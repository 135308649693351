import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#1D1D1B" fillRule="evenodd">
      <path d="M2.022 73.609H74.73V60.73H2.022v12.879ZM.189 75.443h76.374V58.897H.189v16.546Z" />
      <path d="M8.325 58.897h10.777V2.077H8.325v56.82ZM6.492 60.73h14.442V.244H6.492V60.73Z" />
      <path d="M20.934 58.897h8.15V18.17h-8.15v40.728ZM19.1 60.73h11.815V16.334H19.101V60.73ZM10.562 8.709h5.778c.48 0 .939-.422.917-.917-.023-.497-.403-.916-.917-.916h-5.778c-.48 0-.939.422-.916.916.02.497.4.917.916.917M10.826 11.359a17.256 17.256 0 0 1 2.2-.062c.36.014.72.04 1.077.075.085.01.172.018.257.029.049.005.265.034.099.01.2.028.4.06.598.095.47.08 1.01-.125 1.126-.64.103-.445-.134-1.04-.64-1.128a18.016 18.016 0 0 0-4.717-.212c-.475.043-.94.39-.916.916.02.46.405.963.916.917" />
      <path d="M22.516 22.768V54.82c0 1.18 1.833 1.182 1.833 0V22.768c0-1.18-1.833-1.182-1.833 0M26.714 54.82V42.737c0-1.18-1.833-1.182-1.833 0V54.82c0 1.18 1.833 1.181 1.833 0" />
    </g>
  </React.Fragment>,
  "Stage1",
  {
    viewBox: "0 0 77 76",
    width: "77px",
    height: "76px",
  }
);
