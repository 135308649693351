import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
  <g fill="#1D1D1B" fillRule="evenodd">
    <path d="M2.138 79.422h86.368V65.548H2.138v13.874ZM.304 81.257h90.034V63.715H.304v17.542Z" />
    <path d="M62.753 63.715h11.923V7.263H62.753v56.452Zm-1.832 1.833H76.51V5.43H60.92v60.118Z" />
    <path d="M76.51 63.715h5.573V17.703H76.51v46.012Zm-1.834 1.833h9.238V15.87h-9.238v49.68ZM66.685 13.98V58.58c0 1.179 1.833 1.181 1.833 0V13.98c0-1.179-1.833-1.18-1.833 0M71.294 58.299V25.2c0-1.18-1.832-1.182-1.832 0v33.098c0 1.179 1.832 1.18 1.832 0M66.069 58.299V47.452c0-.588-.917-.59-.917 0v10.847c0 .59.917.59.917 0" />
    <path d="M81.86 36.978h2.616c.24 0 .47-.21.459-.457a.466.466 0 0 0-.459-.46H81.86c-.24 0-.47.21-.459.46a.467.467 0 0 0 .46.457M81.86 38.849h2.429c.24 0 .47-.211.459-.46a.466.466 0 0 0-.46-.457H81.86c-.24 0-.47.21-.459.458.012.249.202.459.46.459M82.42 72.465h2.43c.295 0 .295-.457 0-.457h-2.43c-.296 0-.296.457 0 .457M82.42 74.149h2.804c.295 0 .295-.46 0-.46H82.42c-.296 0-.296.46 0 .46M32.828 63.523h17.124V10.485H32.828v53.038Zm-1.832 1.833h20.789V8.65h-20.79v56.705Z" />
    <path d="M51.785 63.523h8.977V2.737h-8.977v60.786Zm-1.833 1.833h12.642V.904H49.952v64.452ZM36.285 21.475h10.47c.884 0 .885-1.375 0-1.375h-10.47c-.886 0-.886 1.375 0 1.375M35.537 24.467h11.218c.885 0 .886-1.375 0-1.375H35.537c-.885 0-.886 1.375 0 1.375M37.78 27.832h3.74c.36 0 .704-.316.688-.687-.017-.371-.302-.687-.688-.687h-3.74c-.36 0-.703.316-.688.687.018.374.304.687.689.687M35.912 57.981H46.38c1.18 0 1.181-1.833 0-1.833H35.91c-1.18 0-1.18 1.833 0 1.833" />
    <path d="M53.735 6.785h5.111c.59 0 .59-.917 0-.917h-5.111c-.59 0-.59.917 0 .917M53.485 8.53h5.61c.59 0 .59-.916 0-.916h-5.61c-.588 0-.59.916 0 .916M53.985 11.522h4.487c.59 0 .591-.917 0-.917h-4.487c-.59 0-.591.917 0 .917M53.61 13.267h4.986c.59 0 .592-.916 0-.916H53.61c-.59 0-.59.916 0 .916M53.735 15.137h5.111c.59 0 .59-.917 0-.917h-5.111c-.59 0-.59.917 0 .917M54.11 60.808c1.247.05 2.493.097 3.74.145.479.019.937-.435.915-.916a.935.935 0 0 0-.916-.916c-1.246-.05-2.492-.097-3.739-.146-.48-.018-.938.436-.916.917a.937.937 0 0 0 .916.916M8.322 63.196H19.95V2.41H8.322v60.786ZM6.49 65.029h15.294V.577H6.49V65.03Z" />
    <path d="M21.784 63.528h8.824V19.921h-8.824v43.607Zm-1.834 1.833h12.49V18.088H19.95v47.273ZM10.77 8.7h6.17c.59 0 .59-.916 0-.916h-6.17c-.588 0-.59.917 0 .917M11.053 11.53c1.553-.142 3.12-.09 4.655.176.244.042.494-.066.564-.32.06-.222-.075-.523-.32-.565a18.766 18.766 0 0 0-4.9-.208c-.246.022-.457.195-.457.459 0 .23.21.48.458.457" />
    <path d="M23.596 24.23v34.22c0 1.18 1.834 1.182 1.834 0V24.23c0-1.18-1.834-1.18-1.834 0M27.495 58.45V45.549c0-.59-.917-.59-.917 0v12.903c0 .59.917.59.917 0" />
  </g>
  </React.Fragment>,
  "Stage3",
  {
    viewBox: "0 0 91 82",
    width: "91px",
    height: "82px",
  }
);
