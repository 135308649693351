import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, ResponsiveImage, ComponentWrapper } from "../..";
import CMSIcon from "../../CMS/CMSIcon";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { IUrl, IImage } from "../../../Types";

const ImageContainer = styled.div`
  background: rgba(255, 255, 255, 0);
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in;
  pointer-events: none;

  ${(props) => props.theme.breakpoints("md")`
    cursor: zoom-in;
    pointer-events: all;
`}

  picture,
  img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    max-height: 80vh;
  }

  &.cloneElement {
    background: RGBA(251, 239, 228, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    pointer-events: all;
    position: fixed;
    opacity: 0;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    z-index: 4000;
  }

  &.goFullScreen {
    cursor: zoom-out;
    height: 100vh;
    opacity: 1;
    width: 100vw;

    picture,
    img {
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.23);
      max-width: 95vw;
      max-height: 95vh;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  a {
    min-width: 250px;

    svg {
      height: auto;
      margin-left: 10px;
      max-width: 22px;
    }

    & > span {
      align-items: center;
      display: inline-flex;
    }
  }
`;

export interface IImageBanner {
  CTA?: IUrl;
  Image: IImage;
}

const ImageBanner: React.FC<IImageBanner> = ({ CTA, Image }) => {
  const imageRef = useRef<any>();

  const toggleZoom = (e) => {
    disableBodyScroll(imageRef.current);

    const imageElem = imageRef.current;
    const cloneElem = imageElem.cloneNode(true);
    cloneElem.classList.add("cloneElement");
    document.getElementsByTagName("body")[0].appendChild(cloneElem);

    setTimeout(() => {
      cloneElem.classList.add("goFullScreen");
    }, 100);

    cloneElem.addEventListener("click", (e) => {
      cloneElem.remove();
      enableBodyScroll(imageRef.current);
    });
  };

  useEffect(() => {
    const imageElem = imageRef.current;
    imageElem.addEventListener("click", (e) => toggleZoom(e));
  }, []);

  return (
    <ComponentWrapper>
      <div className="animate">
        {Boolean(Image) && (
          <ImageContainer ref={imageRef}>
            <ResponsiveImage {...Image} />
          </ImageContainer>
        )}

        {Boolean(CTA) && (
          <ButtonWrapper>
            <Button
              component={`a`}
              href={CTA?.Url}
              variant={`CTA`}
              target={CTA?.Target}
            >
              <span className="label">{CTA?.Label}</span>
              {Boolean(CTA?.Icon) && <CMSIcon icon={CTA?.Icon} />}
            </Button>
          </ButtonWrapper>
        )}
      </div>
    </ComponentWrapper>
  );
};

export default ImageBanner;
