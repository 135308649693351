
import * as React from 'react';
import createSvgIcon from '../SvgIcon/createSvgIcon';

export default createSvgIcon(
// tslint:disable-next-line:max-line-length
<React.Fragment><path fill="currentColor" fillRule="nonzero" d="M6.917.303c.3554.3654.3751.933.0646 1.321l-.0845.093L3.463 5.055l9.237.0006c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9932l-.1166.0068-9.237-.0006 3.434 3.3391c.3656.3554.4013.9222.1018 1.3187l-.0818.0954c-.3554.3655-.9222.4012-1.3187.1018l-.0954-.0819-5.2-5.0555a1.0537 1.0537 0 0 1-.1082-.1241l-.0569-.0859-.0563-.1108L.0538 6.38l-.0273-.0947-.0138-.0704-.011-.1008v-.1173L.016 5.877l.0186-.0832.0364-.109.023-.053.0469-.0883.076-.11.0491-.0571.037-.0378L5.503.283c.396-.385 1.029-.376 1.414.02z" /></React.Fragment>
,
'ArrowLeft',
{
		viewBox : '0 0 14 13',
		width : '14px',
		height : '13px'
});
