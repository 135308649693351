export const colors = {
	neutralWhite: '#fff',
	bgColor: '#FBEFE4',
	darkGreen: '#7AB59C',
	neoMint: '#8ECDB2',
	sort: '#1D1D1B',
	koral: '#EE737E',
	koral2: '#E73D3E',
	lilla: '#84307B',
	lilla2: '#BBAAD2',
	lyseBlaa: '#88CDD7',
	solGul: '#F4BA00',
	graa: '#A1A1A0',

	textColor: '#1D1D1B',
	linkColor: '#1D1D1B',
};

