import React from "react";
import styled, { css } from "styled-components";

const LogoWrapper = styled.div`
  padding: ${(props) => (props.isWhite ? "0" : "0 10px")};
  width: ${(props) => (props.isWhite ? "116px" : "109px")};

  ${(props) => props.theme.breakpoints("sm")`
		width: ${(props) => (props.isWhite ? "116px" : "152px")};
	`}

	img {
    display: block;
    width: 100%;
  }
`;

export interface ILogo {
  white?: boolean;
}

const Logo: React.FC<ILogo> = ({ white = false }) => {
  return white ? (
    <LogoWrapper isWhite={white}>
      <img src="/dist/DGR_logo_ikon_neg.png" className="logo" />
    </LogoWrapper>
  ) : (
    <LogoWrapper>
      <img src="/dist/DGR_logo_primaer.svg" className="logo" />
    </LogoWrapper>
  );
};

export default Logo;
