import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="currentColor" fillRule="evenodd">
      <rect width="11" height="11" rx="3" />
      <rect x="13" width="11" height="11" rx="3" />
      <rect y="13" width="11" height="11" rx="3" />
      <rect x="13" y="13" width="11" height="11" rx="3" />
    </g>
  </React.Fragment>,
  "ListGrid",
  {
    viewBox: "0 0 24 24",
    width: "24px",
    height: "24px",
  }
);
