import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Modal from "react-modal";
import YouTube from "@u-wave/react-youtube";
import {
  Heading,
  ModalCloseButton,
  ResponsiveImage,
  ComponentWrapper,
} from "../..";
import { IImage } from "../../../Types";
import CMSIcon from "../../CMS/CMSIcon";

const VideoContainer = styled.div`
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
  }
`;

const WebinarList = styled.ul`
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
`;

const WebinarListItem = styled.li`
  margin: 0;
  padding: 0;
`;

const WebinarButton = styled.button`
  align-items: center;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg width="17" height="27" xmlns="http://www.w3.org/2000/svg"><path d="M13.81 13.353c.476-.48.476-1.253 0-1.706L3.136.9a1.336 1.336 0 0 0-1.906 0 1.359 1.359 0 0 0 0 1.92l8.765 8.827c.477.48.477 1.253 0 1.706L1.23 22.18a1.359 1.359 0 0 0 0 1.92c.53.533 1.377.533 1.906 0L13.81 13.353Z" fill="%231D1D1B" fill-rule="nonzero"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 37px) center;
  background-size: 16px 26px;
  border: 0;
  border-top: 1px solid RGBA(121, 121, 121, 0.19);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
  padding: 20px 0;
  text-align: left;
  transition: all 0.3s ease;
  width: 1080px;

  &:hover {
    background-color: white;
  }
`;

const WebinarImage = styled.span`
  padding: 0 20px;
  position: relative;

  img {
    border-radius: 5px;
    width: 122px;
  }

  svg {
    inset: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 30px;
  }
`;

const WebinarTitle = styled.span`
  flex-grow: 1;
  padding: 0 20px 0 4px;
`;

const WebinarDescription = styled.span`
  flex-basis: 290px;
  font-size: 13px;
  line-height: 18px;
  opacity: 0.74;
  padding-right: 40px;
  flex-shrink: 0;
`;

const WebinarTime = styled.span`
  flex-basis: 80px;
  font-size: 15px;
  font-weight: 600;
  padding-right: 83px;
`;

export interface IWebinar {
  Image: IImage;
  Header: string;
  Description: string;
  Time: string;
  VideoId: string;
}

export interface IWebinars {
  Webinars: IWebinar[];
}

const Webinar: React.FC<IWebinars> = ({ Webinars }) => {
  const [VideoId, setVideoId] = useState<string>("");
  const scrollerRef = useRef<any>();

  const playVideo = (e: any, video): void => {
    e.preventDefault();
    setVideoId(video);
  };

  const stopVideo = (): void => {
    setVideoId("");
  };

  const closeModal = (e: any): void => {
    stopVideo();
  };

  useEffect(() => {
    Boolean(VideoId)
      ? disableBodyScroll(scrollerRef.current)
      : enableBodyScroll(scrollerRef.current);
  }, [VideoId]);

  return (
    <ComponentWrapper>
      <div ref={scrollerRef}>
        {Boolean(VideoId) && (
          <Modal
            isOpen={true}
            style={{
              content: {
                background: "transparent",
                border: 0,
                padding: 0,
                overflow: "hidden",
              },
              overlay: {
                opacity: 1,
                zIndex: 100,
                backgroundColor: "rgba(251, 239, 228, 0.94)",
              },
            }}
            onRequestClose={stopVideo}
          >
            <ModalCloseButton onButtonClick={closeModal}>
              <CMSIcon icon={"close"} />
            </ModalCloseButton>
            <VideoContainer>
              <YouTube
                video={VideoId}
                controls={true}
                showRelatedVideos={false}
                showInfo={false}
                modestBranding={false}
                onEnd={stopVideo}
                playsInline={true}
                annotations={false}
                autoplay={true}
              />
            </VideoContainer>
          </Modal>
        )}
        <WebinarList className="animate">
          {Webinars.map(
            ({ Image, Header, Description, Time, VideoId }, index) => {
              return (
                <WebinarListItem key={`webinar_${index}`}>
                  <WebinarButton onClick={(e) => playVideo(e, VideoId)}>
                    <WebinarImage>
                      <ResponsiveImage {...Image} />
                      <CMSIcon icon={"videoplay"} />
                    </WebinarImage>
                    <WebinarTitle>
                      <Heading noMargin size={300}>
                        {Header}
                      </Heading>
                    </WebinarTitle>
                    <WebinarDescription>{Description}</WebinarDescription>
                    <WebinarTime>{Time}</WebinarTime>
                  </WebinarButton>
                </WebinarListItem>
              );
            }
          )}
        </WebinarList>
      </div>
    </ComponentWrapper>
  );
};

export default Webinar;
