import React, { useRef, useState, useEffect } from 'react';

export const intersectionObserver = options => {
	const isClient = typeof window === 'object';

	if (!isClient) {
		return [];
	}

	if(!('IntersectionObserver' in window)){
		return [null, true];
	}
	const containerRef = useRef<any>();
	const [isVisible, setIsVisible] = useState(false);

	const callbackFunction = entries => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options);
		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return [containerRef, isVisible];
};
