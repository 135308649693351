import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CMSIcon from "../CMS/CMSIcon";

const IconBackground = styled.div`
  &.climate {
    width: 62%;
  }
  &.energy {
    width: 49%;
  }
  &.food {
    width: 50%;
  }
  &.health {
    width: 69%;
  }
  &.nature {
    width: 49%;
  }
  &.ressources {
    width: 49%;
  }
`;
const IconWrapper = styled.div`
  &.climate {
    width: 30%;
  }
  &.energy {
    width: 51%;
  }
  &.food {
    width: 37%;
  }
  &.health {
    width: 49%;
  }
  &.nature {
    width: 60%;
  }
  &.ressources {
    width: 42%;
  }
`;

const SubjectIconContainer = styled.div`
  position: relative;

  ${IconBackground},
  ${IconWrapper} {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    svg {
      height: auto;
      width: 100%;
    }
  }
`;

const IconContainer = styled.div`
  padding: 93% 0 0;
`;

export interface ISubjectIcon {
  Icon: string;
}

const SubjectIcon: React.FC<ISubjectIcon> = ({ Icon }) => {

  return (
    <SubjectIconContainer>
      <IconContainer>
        <IconBackground className={`${Icon} bg_icon`}>
          <CMSIcon icon={`bg${Icon}`} />
        </IconBackground>
        <IconWrapper className={`${Icon} fg_icon`}>
          <CMSIcon icon={`subject${Icon}`} />
        </IconWrapper>
      </IconContainer>
    </SubjectIconContainer>
  );
};

export default SubjectIcon;
