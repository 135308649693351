import { useState } from 'react';
import { serialize } from '../Utils/formUtils';

export type TAjaxHook = {
	formSubmit: any;
	fileFormSubmit: any;
	ajaxReceipt: boolean;
	ajaxResponse: any;
	ajaxStatus: any;
};

export default function useAjax(): TAjaxHook {
	const [ajaxReceipt, showAjaxReceipt] = useState(false);
	const [ajaxResponse, setAjaxResponse] = useState({});
	const [ajaxStatus, setAjaxStatus] = useState({});

	const formSubmit = (e, formUrl, customData = {}, formMethod = 'POST') => {
		const config = {
			method: formMethod,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		};
		let additionalParams = {};

		if (formMethod === 'POST') {
			additionalParams = {
				body: JSON.stringify({ ...serialize(e.target), ...customData }),
			};
		}

		fetch(formUrl, {
			...config,
			...additionalParams,
		})
			.then(response => {
				setAjaxStatus(response.status);
				return response.json();
			})
			.then(response => {
				showAjaxReceipt(true);
				setAjaxResponse(response);
			})
			.catch(error => {
				console.error(error);
			});

		e.preventDefault();
	};

	const fileFormSubmit = (e, formUrl, customData = {}, formMethod = 'POST') => {
		const config = {
			method: formMethod,
		};
		let additionalParams = {};

		const formData = new FormData(e.target);

		if (formMethod === 'POST') {
			additionalParams = {
				body: formData,
			};
		}

		fetch(formUrl, {
			...config,
			...additionalParams,
		})
			.then(response => {
				setAjaxStatus(response.status);
				return response.json();
			})
			.then(response => {
				showAjaxReceipt(true);
				setAjaxResponse(response);
			})
			.catch(error => {
				console.error(error);
			});

		e.preventDefault();
	};

	return { formSubmit, fileFormSubmit, ajaxReceipt, ajaxResponse, ajaxStatus };
}
