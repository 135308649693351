import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

const StyledSlideUp = styled.div`
  opacity:  ${props => props.visible ? '1' : '0'};
	transform: ${props => props.visible ? 'translate(0)' : 'translateY(-25px)'};
	transition: all 300ms ease;
	will-change: transform;
`;

export interface ISlideUp {
  children: React.ReactNode;
}

const SlideUp: React.FC<ISlideUp> = ({ children }) => {
  const [visible, setVisibility] = useState(false);
  const [entered, setEntered] = useState(false);

  const onChange = visiblity => {
    setVisibility(visiblity);
  };

  useEffect(() => {
    if (visible && !entered) {
      setEntered(true);
    }
  }, [visible]);

  return (
    <React.Fragment>
      <VisibilitySensor
        partialVisibility
        onChange={onChange}
        active={!entered}
      >
        <StyledSlideUp visible={visible}>
          {children}
        </StyledSlideUp>
      </VisibilitySensor>
    </React.Fragment>
  );
}

export default SlideUp;