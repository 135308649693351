import React from 'react';
import styled from 'styled-components';

const StyledRichText = styled.div`
	font-size: 18px;
	letter-spacing: 0;
	line-height: 24px;

	${props => props.small && `
		max-width: 50vw;
		font-size: 18px;
		line-height: 24px;
	`}

	& p {
		margin-top: 0;
		margin-bottom: 23px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	& a {
		color: ${props => props.theme.colors.darkGreen};
		font-weight: 500;
		text-decoration: none;

		text-decoration: none;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0 102%;
		background-repeat: no-repeat;
		background-size: 0% 1px;
		transition: background-size 300ms ease-in-out;

		&:hover, &:focus {
			background-size: 100% 1px;
		}
	}

	& ul, ol {
		margin-left: 0;
		padding-left: 16px;
		& li {
			margin-bottom: 16px;
		}
	}

	& ol {
		counter-reset:li;
		& li {
			position:relative;
			list-style:none;
			margin: 0 0 16px 20px;

			&:before {
				content:counter(li);
				counter-increment:li;
				position:absolute;
				top: 0;
				left: -40px;
				color: #fff;
				background-color: ${props => props.theme.typography.orderedListColor};
				border-radius: 100%;
				width: 28px;
				height: 28px;
				line-height: 30px;
				font-weight: 500;
				font-size: 14px;
				letter-spacing: 0;
				text-align: center;
			}
		}
	}
	.floater{
		width: 400px;
		height: 400px;
		shape-outside: circle(50%);
		background-color: red;
		float: right;
		margin-left: -80px;
	}
`;

export type TRichText = {
	htmlContent: any;
}

const RichText: React.FC<TRichText> = ({
	htmlContent
}) => {
	return (
		<StyledRichText className="concito-web__rte"
			dangerouslySetInnerHTML={{
				__html: htmlContent
			}}
		/>
	);
};

export default RichText;