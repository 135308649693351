import * as React from 'react';

import {
	PageNotFound,
	ContentPage
} from '.';

interface ITemplateMap {
	[propName: string]: React.FC;
}

const templatePicker = (templateId: string) => {
	const templateMap: ITemplateMap = {
		homePage: ContentPage,
		contentPage: ContentPage
	};
	const template = templateMap[templateId];
	// If the template is not found or is not a fucnton
	if (!template) {
		//throw new Error('Not a valid template (' + templateId + ')');
		return PageNotFound;
	}

	return template as any;
};

export default templatePicker;
