import React from 'react';
import styled from 'styled-components';
import { CMSContent } from '../Components';
import { IPage } from '../Types';
import { Master } from '.';

export interface IContentPage extends IPage {
	Components: any[];
}

const StyledCMSContent = styled.div`
	position: relative;
`;

const ArticleBoundary = styled.div`
	width: 100%;
	max-width: 100vw;
`;

const Root = styled.section`
	width: 100%;
	max-width: 100vw;

	/*
		the growing surface animation will not work in ie11
		we rely heavily on this to determine our target width
		so in ie11 we need to set the background on this container explicitly
	*/
	@media all and (-ms-high-contrast: none) {
		background: #fff;
	}
`;

const ContentPage: React.FC<IContentPage> = ({
	Components,
	...rest
}) => {
	return (
		<Master {...rest}>
			<ArticleBoundary>
				<Root>
					<StyledCMSContent>
						<CMSContent Components={Components} />
					</StyledCMSContent>
				</Root>
			</ArticleBoundary>
		</Master>
	);
};

export default ContentPage;