import React, { useEffect } from "react";
import { ISearchItem } from "../../../Types";
import { drawPieChart } from "./dendogram";
import styled from "styled-components";

const CircleWrap = styled.div`
  .title {
    position: relative;
  }

  h3 {
    position: relative;
  }

  .subtitle {
  }

  .sectionheader {
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 120px;
  }

  #kommune-map {
    height: 600px;
  }

  .info {
    padding: 6px 8px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .highcharts-figure,
  .highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
  }

  #container {
    height: 400px;
  }

  .highcharts-data-table table {
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }

  .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }
  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }
  .highcharts-data-table td,
  .highcharts-data-table th,
  .highcharts-data-table caption {
    padding: 0.5em;
  }
  .highcharts-data-table thead tr,
  .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }

  .node text {
  }

  .node--internal circle {
    stroke: #555;
  }

  .node--internal text {
    text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
  }

  .node--unlinked circle {
    stroke: gray;
  }

  .node--linked circle {
    stroke: ${(props) => props.theme.textColor};
  }

  .highlighted.with-link {
    fill: ${(props) => props.theme.textColor};
    text-decoration: underline;
  }

  .highlighted.with-link circle {
    fill: ${(props) => props.theme.textColor};
  }

  .highlighted.without-link circle {
    fill: ${(props) => props.theme.textColor};
  }

  .highlighted.without-link text {
    fill: ${(props) => props.theme.textColor};
    text-decoration: line-through;
  }

  .link {
    fill: none;
    stroke: #555;
    stroke-opacity: 0.4;
    stroke-width: 1.5px;
  }

  .overlay-spinner {
    top: 50%;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 10000;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .overlay {
    position: relative;
    top: 50%;
    width: 60%;
    height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .overlay-message {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -55%);
    text-align: center;
  }

  .overlay-message h1 {
    color: #fff;
    font-size: 1.7rem;
  }
  .overlay-message p {
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.5;
  }

  .hidden {
    display: none;
  }

  .filters {
    text-align: center;
  }

  .selected-filter {
    fill-opacity: 1;
    stroke-width: 3;
  }

  #theme-filter rect:hover {
    stroke: #2d3532;
    opacity: 1;
    stroke-width: 3;
  }

  #theme-filter rect:hover + text:hover {
    opacity: 1;
  }

  #schoollevel-filter rect:hover {
    stroke: #2d3532;
    opacity: 1;
    stroke-width: 3;
  }

  #schoollevel-filter rect:hover + text:hover {
    opacity: 1;
  }

  #class-filter circle:hover {
    stroke: #2d3532;
    fill-opacity: 1;
    stroke-width: 3;
  }

  #class-filter circle:hover + text:hover {
    opacity: 1;
  }

  .materials-highlighted {
    stroke: black;
    fill-opacity: 1;
    stroke-width: 3;
  }

  .materials-label {
  }

  .textlabel {
    pointer-events: none;
  }

  .kws {
    pointer-events: none;
  }

  .themetext {
    pointer-events: none;
  }

  .udbyder {
    pointer-events: none;
  }

  .titles {
    pointer-events: none;
  }
  #circle_logo {
    animation-name: breathe;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes breathe {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.95);
    }
  }
  padding-top: 100%;
  position: relative;
  margin: 0;
  & > svg {
    max-width: 1000px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    display: block;
    transform: translateX(-50%);
  }
`;

export interface ICircleGrid {
  Data: Array<ISearchItem>;
}

const CircleGrid: React.FC<ICircleGrid> = ({Data}) => {
    const svgRef = React.useRef(null);

  useEffect(() => {
    if (Data.length >= 1) {
      drawPieChart(svgRef.current, Data, radius);
    }
  }, [Data, svgRef]);

  let width = 1000;
  let height = 1000;
  let radius = 400;
  let center_logo_width = radius * 2 - 220;
  let center_logo_height = radius * 2 - 220;

  return (
    <CircleWrap id="tree-chart">
      <svg viewBox="0 0 1000 1000" overflow='visible'>
        <g transform={`translate(${width / 2}, ${height / 2})`} ref={svgRef}>
          <image
            x={0 - center_logo_width / 2}
            y={0 - center_logo_height / 2}
            id="circle_logo"
            width={center_logo_width}
            height={center_logo_height}
            href="/dist/circle_logo.png"
          ></image>
        </g>
      </svg>
    </CircleWrap>
  );
};

export default CircleGrid;
