import React from "react";
import styled, { css } from "styled-components";
import { IButton } from "../../Types";

const StyleButton = styled.div<IButton>`
  color: ${(props) => props.theme.colors.neutralWhite};
  border: ${(props) => (props.version === "dark" ? "1px" : "2px")} solid
    ${(props) =>
      props.version === "dark"
        ? props.theme.colors.neutralWhite
        : props.theme.button.bg};
  border-radius: ${(props) => (props.iconButton ? "100px" : "38px")};
  width: auto;
  cursor: pointer;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 120px;
  -webkit-tap-highlight-color: transparent;
  will-change: transform;
  font-weight: 600;

  ${(props) => {
    if (props.iconButton) {
      return `
				padding: 0
			`;
    }

    if (props.slimButton) {
      return `
				padding: 10px 30px 10px 20px;
			`;
    }

    return `
			padding: 0 30px;
		`;
  }}

  transition: transform 100ms ease-in-out;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.iconButton &&
    `
		min-width: 50px;
		max-width: 50px;
	`}

  ${(props) => {
    switch (props.variant) {
      case "CTA":
        return css`
          background-color: ${(props) =>
            props.version === "dark"
              ? props.theme.colors.darkGreen
              : props.theme.button.bg};
          border: none;
          color: ${(props) => props.theme.button.neutralWhite};
          transform: scale(1);

          &:hover {
            transform: scale(1.035);
          }
        `;
      case "Outline":
        return css`
          border-width: 2px;
          border-style: solid;
          background-color: transparent;
          border-color: ${(props) =>
            props.version === "dark"
              ? props.theme.button.bg
              : props.theme.colors.neutralWhite};
          color: ${(props) =>
            props.version === "dark"
              ? props.theme.button.bg
              : props.theme.colors.neutralWhite};

          transform: scale(1);

          &:hover {
            transform: scale(1.035);
          }
        `;
      case "Gray":
        return css`
          border: none;
          background-color: rgba(0,0,0,0.4);
          color: ${(props) => props.theme.colors.neutralWhite};
          transform: scale(1);

          &:hover {
            transform: scale(1.035);
          }
          &.nd {
            margin-left: 15px !important;
            padding-right: 20px;
            span{
              display: flex;
              .svgwrap{
                width: 1.5em;
                height: 1.5em;
                position: relative;
                overflow: visible;
                margin-left: 10px;
                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform:translate(-50%, -50%) rotate(-45deg);
                  fill: ${(props) => props.theme.colors.neutralWhite};
                }
              }
              > * {
                margin: auto 0;
              }
            }
            &.active {
              svg {
                fill: ${(props) => props.theme.colors.neoMint} !important;
              }
            }
          }
        `;
    }
  }}

  	${(props) =>
    props.disabled &&
    `
		& {
			transition: none;
			opacity: 0.5;
			cursor: not-allowed;
			&:hover {
				transform: none;
			}
		}
	`}
`;

const StyledCaption = styled.span`
  ${(props) => {
    switch (props.iconButton) {
      case false:
        return css`
          font-family: ${(props) => props.theme.typography.fontPrimary};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          font-size: 15px;
          color: white;
          text-align: center;
          line-height: 24px;
        `;
    }
  }}
`;

const Button: React.FC<IButton> = ({
  component = "button",
  children,
  href,
  target,
  variant = "Outline",
  version,
  id,
  Modal,
  ...rest
}) => {
  return (
    <React.Fragment>
      {!Boolean(Modal) && (
        <StyleButton
          as={component}
          variant={variant}
          {...(id && { id })}
          {...(version && { version })}
          {...(href && { href })}
          {...(target && { target })}
          {...rest}
        >
          <StyledCaption iconButton={rest.iconButton ? true : false}>
            {children}
          </StyledCaption>
        </StyleButton>
      )}
    </React.Fragment>
  );
};

export default Button;
