import React from 'react';
import styled from 'styled-components';
import { Grid } from '..';

const Root = styled.section`
	margin-bottom: 60px;
`;

export interface ICookieBot {
	Language: string;
	ScriptReference: string;
}

const CookieBot: React.FC<ICookieBot> = ({
	Language,
	ScriptReference
}) => {

	return (
		<Root>
			<Grid noPadding columns={20}>
				<Grid item xs={24} sm={12} smOffset={4}>
					<script async src={ScriptReference} data-culture={Language} />
				</Grid>
			</Grid>
		</Root>
	);
};
export default CookieBot;