import React from "react";
import styled, { css } from "styled-components";
import { Heading, RichText, ComponentWrapper } from "../..";
import { SubjectIcon } from "../..";

const TextContainer = styled.div`
  padding: 0 0 30px;
`;

const IconWrapper = styled.div`
  left: -30px;
  position: relative;
  width: 130px;
`;

const ColorBreaker = styled.div`
  height: 6px;
  width: 120px;
  background-color: ${(props) => props.theme.colors.neoMint};
  transform: translateY(-10px);

  ${(props) => {
    switch (props.Color) {
      case "energy":
        return css`
          background-color: ${(props) => props.theme.colors.lilla2};
        `;
      case "food":
        return css`
          background-color: ${(props) => props.theme.colors.solGul};
        `;
      case "climate":
        return css`
          background-color: ${(props) => props.theme.colors.lyseBlaa};
        `;
      case "nature":
        return css`
          background-color: ${(props) => props.theme.colors.koral};
        `;
      case "ressources":
        return css`
          background-color: ${(props) => props.theme.colors.koral2};
        `;
      case "health":
        return css`
          background-color: ${(props) => props.theme.colors.lilla};
        `;
    }
  }}
`;

const UnderHeader = styled.div`
  font-size: 26px;
  line-height: 34px;
  margin: 0 0 30px;

  @media (min-width: 768px) {
    max-width: 70%;
  }
`;

const Content = styled.div`
  margin: 10px 0 0;
  @media (min-width: 768px) {
    max-width: 70%;
  }
`;
interface ITextAreaSt {
  Theme: any;
  Header: any;
  UnderHeader: any;
  RichText?: any;
}

const UnderHeaderSection: React.FC<ITextAreaSt> = ({
  Theme,
  Header: HeadingContent,
  UnderHeader: UnderHeaderContent,
  RichText: HtmlContent,
}) => {
  return (
    <ComponentWrapper>
      <TextContainer className={"animate"}>
        {Boolean(Theme) && (
          <IconWrapper>
            <SubjectIcon Icon={Theme} />
          </IconWrapper>
        )}
        {Boolean(HeadingContent) && (
          <Heading is="h1" maxWidth={70} size={100}>
            {HeadingContent}
          </Heading>
        )}
        {Boolean(HeadingContent) && (
          <ColorBreaker Color={Theme} />
        )}
        {Boolean(UnderHeaderContent) && (
          <UnderHeader
            dangerouslySetInnerHTML={{
              __html: UnderHeaderContent
            }}
          />
        )}
        <Content>
          <RichText htmlContent={HtmlContent} />
        </Content>
      </TextContainer>
    </ComponentWrapper>
  );
};
export default UnderHeaderSection;
