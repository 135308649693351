import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M98.42 56.3s14.532-40.85 9.587-42.309C103.063 12.533 62.905.27 62.18.002 61.457-.265 0 22.888 0 22.888l20.522 41.69 20.345 5.221 12.208 3.133L98.42 56.3Z"
      fill="#84317A"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgHealth",
  {
    viewBox: "0 0 109.033 72.932",
    width: "109.033px",
    height: "72.932px",
  }
);
