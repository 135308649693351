import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#000" fillRule="nonzero">
      <path d="M.531 3.097A1.813 1.813 0 1 1 3.096.532L24.47 21.903a1.814 1.814 0 0 1-2.565 2.565L.53 3.097Z" />
      <path d="M3.096 24.47a1.813 1.813 0 1 1-2.565-2.565v-.001L21.904.532a1.814 1.814 0 1 1 2.565 2.565L3.096 24.469Z" />
    </g>
  </React.Fragment>,
  "Close",
  {
    viewBox: "0 0 26 26",
    width: "26px",
    height: "26px",
  }
);
