import * as React from "react";
import styled, { css } from "styled-components";
import { THeading } from "../../Types";
import {
  globalHeadingsProfile,
  componentsHeadingsProfile,
  globalHeadingMarginBottomsProfile,
} from "./HeadingProfiles";

export const headingStyles = (props) => {
  let cssString = `
  	color: inherit;
  	letter-spacing: -.3px;
		margin-top: 0;
		margin-bottom: ${props.margin ? `${props.margin / 2}px` : "0"};
		font-family: "PTSerif", serif;
    word-break: break-word;
	`;

  if (props.center) {
    cssString += `text-align: center;`;
  }

  switch (props.headingsize) {
    case 100 /* 100|200|300|etc. equivalent to global h1|h2|h3|etc. base size - but not tied to h1|h2|h3|etc. necessarily */:
      cssString += `
					font-size: 35px;
					line-height: 42px;
			`;
      break;
    case 200:
      cssString += `
					font-size: 24px;
					line-height: 31px;
				`;
      break;
    case 300:
      cssString += `
					font-size: 20px;
					line-height: 26px;
				`;
      break;
  }

  if (props.left) {
    cssString += `text-align: left;`;
  }
  if(props.maxWidth > 0){
    cssString += `
      max-width: ${props.maxWidth}%
      @media (max-width: 768px) {
        max-width: 90%
      }
      `
  }
  

  return cssString;
};

const StyledHeading = styled.div`
  && {
    ${(props) => {
      const cssString = headingStyles(props);

      return css`
        ${cssString}
      `;
    }}
  }
`;

const Heading: React.FC<THeading> = ({
  is = "h2",
  children,
  size,
  globalSized,
  noMargin,
  ...rest
}) => {
  let setSize;

  if (size) {
    setSize = size;
  } else {
    setSize = globalSized
      ? globalHeadingsProfile.get(is)
      : componentsHeadingsProfile.get(is);
  }

  const bottomMargin = noMargin ? 0 : globalHeadingMarginBottomsProfile.get(is);

  return (
    <StyledHeading
      as={is}
      headingsize={setSize}
      margin={bottomMargin}
      {...rest}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;
