import React from "react";
import styled, { css } from "styled-components";
import { ISearchItem } from "../../../Types";
import { Heading } from "../..";
import ReactSelect from "react-select";
import CMSIcon from "../../CMS/CMSIcon";

const Root = styled.div`
  margin-top: 50px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  min-width: 100;
  width: 100;
  overflow: scroll;
  padding: 0;
  text-align: left;
`;

const ListItem = styled.li`
  margin: 0;
  padding: 0;

  a {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    line-height: 24px;
    padding: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;

    & > * {
      flex-shrink: 0;
    }

    &:before {
      border-top: 1px solid RGBA(121, 121, 121, 0.19);
      display: block;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:hover {
      background-color: white;
    }
  }
`;

const ThemeHeader = styled.div`
  display: flex;
  flex-basis: 90px;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ThemeInfo = styled.ul`
  display: flex;
  flex-basis: 90px;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ThemeInfoItem = styled.li`
  align-content: center;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  margin: 0;
  padding: 0;

  span {
    padding: 0 6px;
    visibility: hidden;
  }

  ${(props) => {
    if (props.isActive) {
      return `
        span {
            visibility: visible;
        }
        `;
    }
  }}

  &:before {
    align-self: center;
    background-color: aliceblue;
    border-radius: 100%;
    content: "";
    display: block;
    height: 10px;
    width: 10px;
  }

  ${(props) => {
    switch (props.tileTheme) {
      case "energy":
        return css`
          &:before {
            background-color: ${props.theme.colors.lilla2};
          }
        `;
      case "food":
        return css`
          &:before {
            background-color: ${props.theme.colors.solGul};
          }
        `;
      case "climate":
        return css`
          &:before {
            background-color: ${props.theme.colors.lyseBlaa};
          }
        `;
      case "ressources":
        return css`
          &:before {
            background-color: ${props.theme.colors.koral2};
          }
        `;
      case "nature":
        return css`
          &:before {
            background-color: ${props.theme.colors.koral};
          }
        `;
      case "health":
        return css`
          &:before {
            background-color: ${props.theme.colors.lilla};
          }
        `;
    }
  }}
`;

const ListHeader = styled.div`
  flex-basis: 420px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 60px;
  h2 {
    margin-bottom: 0 !important;
  }
`;

const ListKeywords = styled.div`
  flex-basis: 280px;
  padding: 0;
`;

const ListLink = styled.div`
  flex-basis: 175px;
  padding: 0 30px;
  text-align: right;
`;

const ListIcon = styled.div`
  flex-basis: 24px;
`;

const ListItemRow = styled.div`
  align-items: center;
  color: #757575;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 24px;
  padding: 17px 10px;

  ${ListLink} {
    text-align: center;
  }
  & > * {
    flex-shrink: 0;
  }
`;

export interface IMaterialList {
  Data: Array<ISearchItem>;
  Themes: any;
  Configuration: any;
  Keywords: any;
  CurrentKeyword: string;
  SelectStyles: any;
  HandleKeywordChange: any;
}

const MaterialList: React.FC<IMaterialList> = ({
  Data,
  Themes: ThemeData,
  Configuration,
  Keywords,
  CurrentKeyword,
  SelectStyles,
  HandleKeywordChange,
}) => {
  const selectOptions = [
    ...Keywords.map(({ KeywordId: value, KeywordName: label }) => {
      return {
        value,
        label,
      };
    }),
  ];

  selectOptions.unshift({
    value: "All",
    label: Configuration.Columns.Keywords.Reset,
  });

  return (
    <Root>
      <List>
        <ListItem>
          <ListItemRow>
            <ThemeHeader>{Configuration.Columns.Themes.Label}</ThemeHeader>
            <ListHeader>{Configuration.Columns.Name.Label}</ListHeader>
            <ListKeywords>
              <ReactSelect
                value={selectOptions.filter((option) => {
                  return option.value === CurrentKeyword;
                })}
                onChange={HandleKeywordChange}
                placeholder={Configuration.Columns.Keywords.Filter}
                options={selectOptions}
                styles={SelectStyles}
                isSearchable={false}
              />
            </ListKeywords>
            <ListLink>{Configuration.Columns.Provider.Label}</ListLink>
            <ListIcon />
          </ListItemRow>
        </ListItem>

              {Data.map(({ Header, Url, UrlHeader, Host,HostName, Themes, KeywordsText }, key) => {
          return (
            <ListItem key={`ListItem_${key}`}>
              <a className="listRow" href={Url} target="_blank">
                <ThemeInfo>
                  {ThemeData.map(({ ThemeId, ThemeName }, index) => (
                    <ThemeInfoItem
                      key={`theme_${index}`}
                      isActive={Themes.includes(ThemeId)}
                      tileTheme={ThemeId}
                    >
                      <span>{ThemeName}</span>
                    </ThemeInfoItem>
                  ))}
                </ThemeInfo>
                <ListHeader>
                  <Heading size={300}>{Header}</Heading>
                </ListHeader>
                <ListKeywords>{KeywordsText}</ListKeywords>
                <ListLink>
                          <span>{HostName}</span>

                </ListLink>
                <ListIcon>
                  <CMSIcon icon={"targetblank"} />
                </ListIcon>
              </a>
            </ListItem>
          );
        })}
      </List>
    </Root>
  );
};

export default MaterialList;
