import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path d="M1.68,5.18V5.3A3.52,3.52,0,0,0,5.22,8.67h4.1a.09.09,0,0,1,.09.09v1.43a.09.09,0,0,1-.09.09H5.06A5,5,0,0,1,1.48,8.79l-.12-.12a5.17,5.17,0,0,1,.06-7.06A5,5,0,0,1,5.1,0H9.32a.09.09,0,0,1,.09.09V1.52a.09.09,0,0,1-.09.09H5.21A3.53,3.53,0,0,0,2.68,2.67,3.63,3.63,0,0,0,1.68,5.18Z"/>
    <path d="M20.43,5.11V5a3.53,3.53,0,0,0-3.55-3.37H12.79a.09.09,0,0,1-.09-.09V.09A.09.09,0,0,1,12.79,0H17a5,5,0,0,1,3.59,1.49l.12.12a5.17,5.17,0,0,1-.06,7.06A5,5,0,0,1,17,10.28H12.79a.09.09,0,0,1-.09-.09V8.76a.09.09,0,0,1,.09-.09H16.9a3.45,3.45,0,0,0,2.53-1.06A3.63,3.63,0,0,0,20.43,5.11Z"/>
    <rect x="6.76" y="4.34" width="8.51" height="1.61" rx="0.09"/>
  </React.Fragment>,
  "ShareLink",
  {
    viewBox: "0 0 22.11 10.28",
    width: "50px",
    height: "12px"
  }
);