import React from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div`
	${props => props.parent &&
		css`
		display: -ms-grid;
		display: grid;

		${props => {
				if (!props.xsCenterItems) {
					return css`
					-ms-grid-columns: (1fr)[${props.columns}];
					grid-template-columns: repeat(${props.columns}, 1fr);
				`
				}
				if (props.xsCenterItems && !props.xsOffset) {
					return css`
					-ms-grid-columns: auto 1fr auto;
					grid-template-columns: auto 1fr auto;
				`
				}
			}}

		${props => {
				if (props.gridRows) {
					return css`
					grid-auto-rows: auto;
				`;
				}
			}}

		grid-auto-flow: dense;
		width: 100%;
		height: ${props => props.spanFullHeight === false ? 'auto' : '100%'};
		padding: ${props => props.noPadding === false ? '0 20px' : '0'};
		margin: 0 auto;

		${props => props.theme.breakpoints('sm')`
			${props => {
					if (!props.smCenterItems) {
						return css`
							-ms-grid-columns: (1fr)[${props.columns}];
							grid-template-columns: repeat(${props.columns}, 1fr);
							max-width: 1920px;
						`;
					}
					if (props.smCenterItems && !props.smOffset) {
						return css`
							-ms-grid-columns: auto 1fr auto;
							grid-template-columns: auto 1fr auto;
							max-width: 100%;
						`;
					}
				}}

				${props => {
					if (props.fullWidth) {
						return css`
							max-width: none;
						`;
					}
				}}

			padding: 0;
		`}

		${props => props.smCenterItems &&
				css`
				place-items: center;
		`}
	`}
	
	${props => {
		if (props.item) {
			return css`
				display: ${props => props.xs === false ? 'none' : 'flex'};
				flex-direction: ${props.flexDirection};
				-ms-grid-column: ${props.xsOffset + 1};
				-ms-grid-column-span: ${props.xs};
				grid-column: ${props.xsOffset + 1} / span ${props.xs};
				height: ${props => props.spanFullHeight === false ? 'auto' : '100%'};

				${props => {
					if (props.xsRow) {
						return css`
							-ms-grid-row: ${props.xsRow};
							grid-row: ${props.xsRow};
						`;
					}
				}}

				${props => {
					if (props.center) {
						return css`
							${props => props.flexDirection === 'column' ? 'align-items: center;' : 'justify-content: center'}
						`;
					}
				}}

				${props => {
					if (props.right) {
						return css`
							${props => props.flexDirection === 'column' ? 'align-items: flex-end' : 'justify-content: flex-end;'}
						`;
					}
				}}

				${props => {
					if (props.middle) {
						return css`
							${props => props.flexDirection === 'column' ? 'justify-content: center;' : 'align-items: center'}
						`;
					}
				}}

				${props => {
					if (props.bottom) {
						return css`
							${props => props.flexDirection === 'column' ? 'justify-content: flex-end;' : 'align-items: flex-end'}
						`;
					}
				}}

				${props => props.theme.breakpoints('sm')`
					display: ${props => props.sm === false ? 'none' : 'flex'};

					-ms-grid-column: ${props.smOffset ? (props.smOffset + 1) : 'auto'};
					-ms-grid-column-span: ${props.sm};
					grid-column: ${props.smOffset ? `${props.smOffset + 1} /` : 'auto /'} span ${props.sm};

					${props => {
						if (props.smRow) {
							return css`
								-ms-grid-row: ${props.smRow};
								grid-row: ${props.smRow};
							`;
						}
					}}

					${props => {
						if (props.setMaxWidth) {
							return css`
								max-width: 100%;
							`;
						}
					}}
				`}
			`
		}
	}}
`;

export interface IGrid {
	item?: boolean;
	xs?: false | number;
	xsOffset?: number;
	sm?: false | number;
	smOffset?: number;
	center?: boolean;
	right?: boolean;
	middle?: boolean;
	bottom?: boolean;
	flexDirection?: string;
	gridRows?: boolean;
	xsRow?: number;
	smRow?: number;
	columns?: number;
	xsCenterItems?: boolean;
	smCenterItems?: boolean;
	noPadding?: boolean;
	spanFullHeight?: boolean;
	fullWidth?: boolean;
	setMaxWidth?: boolean;
}

const Grid: React.FC<IGrid> = ({
	item,
	xs = false,
	xsOffset = 0,
	sm = xs,
	smOffset = 0,
	columns = 24,
	flexDirection = 'column',
	xsCenterItems,
	smCenterItems,
	noPadding = false,
	spanFullHeight = false,
	fullWidth = false,
	setMaxWidth = false,
	...rest
}) => {
	const config = {
		parent: !item,
		columns,
		flexDirection,
		item,
		xs,
		xsOffset,
		sm,
		smOffset,
		xsCenterItems,
		smCenterItems,
		noPadding,
		spanFullHeight,
		fullWidth,
		setMaxWidth,
		...rest
	}

	return (
		<Component {...config} />
	);
};

export default Grid;