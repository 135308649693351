import React from "react";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  BackArrow,
  Book,
  Bulb,
  Checkmark,
  Close,
  Document,
  GreenSpine,
  ListCircle,
  ListGrid,
  ListRow,
  Progress,
  Quote,
  ShareLink,
  Search,
  Stage1,
  Stage2,
  Stage3,
  TargetBlank,
  TileListThemeIcon,
  VideoPlay,
} from "../../Icons/System";

import {
  SubjectClimate,
  SubjectEnergy,
  SubjectFood,
  SubjectHealth,
  SubjectNature,
  SubjectRessources,
} from "../../Icons/Subjects";

import {
	BgClimate,
	BgEnergy,
	BgFood,
	BgHealth,
	BgNature,
	BgRessources,
  } from "../../Icons/Backgrounds";

const iconMap = {
  arrowdown: <ArrowDown />,
  arrowleft: <ArrowLeft />,
  arrowright: <ArrowRight />,
  backarrow: <BackArrow />,
  bgclimate: <BgClimate />,
  bgenergy: <BgEnergy />,
  bgfood: <BgFood />,
  bghealth: <BgHealth />,
  bgnature: <BgNature />,
  bgressources: <BgRessources />,
  book: <Book />,
  bulb: <Bulb />,
  checkmark: <Checkmark />,
  close: <Close />,
  document: <Document />,
  greenspine: <GreenSpine />,
  listcircle: <ListCircle />,
  listgrid: <ListGrid />,
  listrow: <ListRow />,
  progress: <Progress />,
  quote: <Quote />,
  shareLink: <ShareLink />,
  search: <Search />,
  stage1: <Stage1 />,
  stage2: <Stage2 />,
  stage3: <Stage3 />,
  subjectclimate: <SubjectClimate />,
  subjectenergy: <SubjectEnergy />,
  subjectfood: <SubjectFood />,
  subjecthealth: <SubjectHealth />,
  subjectnature: <SubjectNature />,
  subjectressources: <SubjectRessources />,
  targetblank: <TargetBlank />,
  tilelistthemeicon: <TileListThemeIcon />,
  videoplay: <VideoPlay />,
};

type CMSIcons = keyof typeof iconMap;

export interface ICMSIcon {
  icon: string | undefined | null | CMSIcons;
}

const CMSIcon: React.FC<ICMSIcon> = ({ icon }) => {
  return icon ? (
    <React.Fragment>{iconMap[icon || ""]}</React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export { iconMap };
export default CMSIcon;
