import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4)">
        <circle fill="currentColor" cx="17" cy="17" r="17" />
        <path
          d="M19.611 5c-1.973.947-3.52 2.643-4.204 4.61a7.49 7.49 0 0 0 .506 6.017c.601 1.099 1.47 2.047 2.192 3.08 1.349 1.926 2.198 4.264 1.794 6.542-.405 2.277-2.284 4.407-4.732 4.751"
          fill="#FFF"
        />
      </g>
    </g>
  </React.Fragment>,
  "TileListThemeIcon",
  {
    viewBox: "0 0 42 42",
    width: "42px",
    height: "42px",
  }
);
