import React from "react";
import styled from "styled-components";
import { SubjectIcon, ComponentWrapper } from "../..";

const SubjectList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;
  margin: 0 auto;
  max-width: 600px;
  padding: 0;

  .animate-content & {
    .bg_icon svg {
      transform: translateY(+30px);
      transition: transform 1s ease;
    }
    .fg_icon svg {
      transform: translateY(+50px);
      transition: transform 1.2s ease;
    }
  }
  .is-visible & {
    .bg_icon svg {
      transform: translateY(0);
    }
    .fg_icon svg {
      transform: translateY(0);
    }
  }

  ${(props) => props.theme.breakpoints("xsm")`
        justify-content: space-between;
        margin: 0 auto;
        max-width: 750px;
    `}
  ${(props) => props.theme.breakpoints("md")`
      max-width: 985px;
  `}
`;

const SubjectListItem = styled.li`
  flex-basis: 155px;
  font-size: 15px;
  margin: 0;
  padding: 0;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
    flex-basis: 33%;
    max-width: 200px;
  `}
  ${(props) => props.theme.breakpoints("md")`
    flex-basis: 16%;
  `}
  & > a {
    display: block;
    font-size: 15px;
    font-weight: 600;
    transform: scale(1);
    transition: transform 100ms ease-in-out;

    &:hover {
      transform: scale(1.035);
    }
  }
`;

export interface ISubjectBanner {
  List: any[];
}

const SubjectBanner: React.FC<ISubjectBanner> = ({ List = [] }) => {
  return (
    <ComponentWrapper>
      <SubjectList>
        {List.map((ListItem, index) => {
          return (
            <SubjectListItem key={`ressourceItem_${index}`}>
              <a href={ListItem.Link.Url}>
                <SubjectIcon Icon={ListItem.Icon} />
                {ListItem.Link.Label}
              </a>
            </SubjectListItem>
          );
        })}
      </SubjectList>
    </ComponentWrapper>
  );
};

export default SubjectBanner;
