
import * as React from 'react';
import createSvgIcon from '../SvgIcon/createSvgIcon';

export default createSvgIcon(
// tslint:disable-next-line:max-line-length
<React.Fragment><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M1 10.915h24.429M10.5 1.414l-9.5 9.5 9.5 9.5" /></React.Fragment>
,
'BackArrow',
{
		viewBox : '0 0 26.429 21.828',
		width : '22px',
		height : '22px'
});
