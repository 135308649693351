import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
    <path
      d="M14.195 26.047c.884 0 1.768-.009 2.652.006.247.003.355-.084.414-.32.54-2.132 1.526-4.042 2.859-5.783 1.455-1.899 2.046-4.022 1.533-6.38-.878-4.042-4.763-6.789-9.024-5.862-5.51 1.199-7.837 7.524-4.5 11.98 1.378 1.842 2.46 3.817 3.034 6.055.061.242.184.31.42.308.87-.01 1.742-.004 2.612-.004m-.024.409c-.937 0-1.875-.005-2.813.003-.255.001-.431-.046-.5-.337-.564-2.357-1.736-4.4-3.148-6.351-2.736-3.775-1.67-8.955 2.28-11.43 3.472-2.174 8.091-1.324 10.58 1.95 2.12 2.79 2.218 6.54.203 9.407-.916 1.306-1.854 2.594-2.439 4.09a20.93 20.93 0 0 0-.698 2.13c-.114.411-.306.56-.73.547-.912-.029-1.823-.009-2.735-.009"
      fill="#1D1D1B"
    />
    <path
      d="M14.195 26.047c.884 0 1.768-.009 2.652.006.247.003.355-.084.414-.32.54-2.132 1.526-4.042 2.859-5.783 1.455-1.899 2.046-4.022 1.533-6.38-.878-4.042-4.763-6.789-9.024-5.862-5.51 1.199-7.837 7.524-4.5 11.98 1.378 1.842 2.46 3.817 3.034 6.055.061.242.184.31.42.308.87-.01 1.742-.004 2.612-.004Zm-.024.409c-.937 0-1.875-.005-2.813.003-.255.001-.431-.046-.5-.337-.564-2.357-1.736-4.4-3.148-6.351-2.736-3.775-1.67-8.955 2.28-11.43 3.472-2.174 8.091-1.324 10.58 1.95 2.12 2.79 2.218 6.54.203 9.407-.916 1.306-1.854 2.594-2.439 4.09a20.93 20.93 0 0 0-.698 2.13c-.114.411-.306.56-.73.547-.912-.029-1.823-.009-2.735-.009Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M15.647 25.768c.402 0 .803.001 1.204.008.082.002.104-.01.106-.01 0 0 .017-.02.036-.1.523-2.067 1.5-4.048 2.909-5.883 1.457-1.903 1.956-3.973 1.482-6.154a7.418 7.418 0 0 0-3.128-4.623c-1.632-1.088-3.61-1.453-5.568-1.027-2.49.542-4.497 2.26-5.369 4.595-.869 2.33-.482 4.927 1.03 6.948 1.54 2.057 2.547 4.069 3.08 6.152.018.07.036.088.036.088s.02.014.115.014c.634-.01 1.27-.007 1.904-.007l.71.001.727-.001.726-.001m1.304.974-.055-.001c-.64-.021-1.295-.016-1.929-.012l-.795.003h-.776c-.68-.002-1.358-.003-2.035.002-.178 0-.64.006-.772-.548-.487-2.032-1.444-3.96-3.104-6.253-1.352-1.868-1.859-4.129-1.424-6.365.442-2.27 1.786-4.209 3.783-5.461 3.574-2.24 8.382-1.355 10.945 2.017 2.19 2.882 2.274 6.793.211 9.732l-.171.243c-.836 1.19-1.701 2.417-2.238 3.79-.254.65-.485 1.358-.69 2.102-.14.516-.434.751-.95.751"
      fill="#1D1D1B"
    />
    <path
      d="M15.647 25.768c.402 0 .803.001 1.204.008.082.002.104-.01.106-.01 0 0 .017-.02.036-.1.523-2.067 1.5-4.048 2.909-5.883 1.457-1.903 1.956-3.973 1.482-6.154a7.418 7.418 0 0 0-3.128-4.623c-1.632-1.088-3.61-1.453-5.568-1.027-2.49.542-4.497 2.26-5.369 4.595-.869 2.33-.482 4.927 1.03 6.948 1.54 2.057 2.547 4.069 3.08 6.152.018.07.036.088.036.088s.02.014.115.014c.634-.01 1.27-.007 1.904-.007l.71.001.727-.001.726-.001Zm1.304.974-.055-.001c-.64-.021-1.295-.016-1.929-.012l-.795.003h-.776c-.68-.002-1.358-.003-2.035.002-.178 0-.64.006-.772-.548-.487-2.032-1.444-3.96-3.104-6.253-1.352-1.868-1.859-4.129-1.424-6.365.442-2.27 1.786-4.209 3.783-5.461 3.574-2.24 8.382-1.355 10.945 2.017 2.19 2.882 2.274 6.793.211 9.732l-.171.243c-.836 1.19-1.701 2.417-2.238 3.79-.254.65-.485 1.358-.69 2.102-.14.516-.434.751-.95.751Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M11.164 26.061c-.193-.053-.38.03-.523.157a.729.729 0 0 0-.246.512c-.014.207.065.41.126.605.027.087.063.178.07.269.005.099-.062.141-.124.207-.31.335-.097.767.156 1.059.041.046.094.101.089.167-.008.09-.087.176-.125.255a1.45 1.45 0 0 0 .257 1.652c.16.16.41-.088.25-.25-.208-.21-.31-.5-.303-.794.002-.139.03-.281.09-.408.077-.17.203-.316.18-.515-.022-.2-.173-.312-.278-.466-.069-.1-.152-.249-.112-.374.02-.068.079-.106.124-.155a.508.508 0 0 0 .14-.274c.03-.202-.052-.398-.111-.585-.049-.16-.121-.346-.049-.508a.427.427 0 0 1 .22-.21c-.001 0 .052-.01.041-.01.019 0 .012.001.033.006.22.061.314-.28.095-.34"
      fill="#1D1D1B"
    />
    <path
      d="M11.164 26.061c-.193-.053-.38.03-.523.157a.729.729 0 0 0-.246.512c-.014.207.065.41.126.605.027.087.063.178.07.269.005.099-.062.141-.124.207-.31.335-.097.767.156 1.059.041.046.094.101.089.167-.008.09-.087.176-.125.255a1.45 1.45 0 0 0 .257 1.652c.16.16.41-.088.25-.25-.208-.21-.31-.5-.303-.794.002-.139.03-.281.09-.408.077-.17.203-.316.18-.515-.022-.2-.173-.312-.278-.466-.069-.1-.152-.249-.112-.374.02-.068.079-.106.124-.155a.508.508 0 0 0 .14-.274c.03-.202-.052-.398-.111-.585-.049-.16-.121-.346-.049-.508a.427.427 0 0 1 .22-.21c-.001 0 .052-.01.041-.01.019 0 .012.001.033.006.22.061.314-.28.095-.34Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M10.962 31.274a.441.441 0 0 1-.315-.136 1.723 1.723 0 0 1-.48-1.35c.021-.222.08-.432.171-.62l.061-.103.012-.018c-.444-.521-.496-1.046-.148-1.423l.045-.045c-.008-.033-.021-.071-.034-.11l-.04-.125c-.062-.187-.132-.4-.114-.634.02-.28.137-.522.338-.699.235-.208.52-.287.778-.216.247.068.38.307.311.556a.439.439 0 0 1-.482.33.176.176 0 0 0-.037.045c-.024.053.014.173.045.267l.042.128c.06.184.128.392.092.627a.776.776 0 0 1-.208.422l-.05.05a.04.04 0 0 1-.01.006c.008.019.024.061.072.128.025.037.053.07.082.103.096.117.217.263.243.488.027.235-.07.411-.149.552a.772.772 0 0 0-.12.409.816.816 0 0 0 .223.594.437.437 0 0 1 .115.428.471.471 0 0 1-.443.346Z"
      fill="#1D1D1B"
    />
    <path
      d="M10.962 31.274a.441.441 0 0 1-.315-.136 1.723 1.723 0 0 1-.48-1.35c.021-.222.08-.432.171-.62l.061-.103.012-.018c-.444-.521-.496-1.046-.148-1.423l.045-.045c-.008-.033-.021-.071-.034-.11l-.04-.125c-.062-.187-.132-.4-.114-.634.02-.28.137-.522.338-.699.235-.208.52-.287.778-.216.247.068.38.307.311.556a.439.439 0 0 1-.482.33.176.176 0 0 0-.037.045c-.024.053.014.173.045.267l.042.128c.06.184.128.392.092.627a.776.776 0 0 1-.208.422l-.05.05a.04.04 0 0 1-.01.006c.008.019.024.061.072.128.025.037.053.07.082.103.096.117.217.263.243.488.027.235-.07.411-.149.552a.772.772 0 0 0-.12.409.816.816 0 0 0 .223.594.437.437 0 0 1 .115.428.471.471 0 0 1-.443.346Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M11.008 31.04c1.768 0 3.536-.003 5.304-.004h1.459c.228 0 .228-.354 0-.354-1.768 0-3.537.004-5.304.004l-1.46.002c-.226 0-.226.352 0 .352"
      fill="#1D1D1B"
    />
    <path
      d="M11.008 31.04c1.768 0 3.536-.003 5.304-.004h1.459c.228 0 .228-.354 0-.354-1.768 0-3.537.004-5.304.004l-1.46.002c-.226 0-.226.352 0 .352Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M11.008 31.316a.44.44 0 0 1-.447-.452.44.44 0 0 1 .447-.453l3.388-.002 3.375-.003a.44.44 0 0 1 .447.454.44.44 0 0 1-.447.452l-3.34.003-3.423.001"
      fill="#1D1D1B"
    />
    <path
      d="M11.008 31.316a.44.44 0 0 1-.447-.452.44.44 0 0 1 .447-.453l3.388-.002 3.375-.003a.44.44 0 0 1 .447.454.44.44 0 0 1-.447.452l-3.34.003-3.423.001Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M17.923 30.948c.253-.37.507-.764.524-1.228a1.12 1.12 0 0 0-.145-.61c-.05-.083-.116-.148-.176-.223-.058-.075-.035-.117.01-.198.167-.304.274-.726.058-1.038a.685.685 0 0 0-.217-.196c-.073-.044-.105-.04-.07-.13.033-.085.084-.164.118-.251a.607.607 0 0 0 .038-.265c-.025-.376-.319-.795-.73-.785-.227.006-.227.359 0 .354.097-.003.192.054.263.149.08.108.127.236.114.365-.013.136-.156.298-.172.463a.38.38 0 0 0 .097.286c.059.066.142.095.21.146.105.08.122.211.113.34-.008.155-.078.287-.148.424a.515.515 0 0 0-.071.307c.013.108.07.195.138.278.107.13.187.248.211.42.06.453-.228.86-.47 1.214-.053.079-.014.195.064.241.087.052.187.016.241-.063"
      fill="#1D1D1B"
    />
    <path
      d="M17.923 30.948c.253-.37.507-.764.524-1.228a1.12 1.12 0 0 0-.145-.61c-.05-.083-.116-.148-.176-.223-.058-.075-.035-.117.01-.198.167-.304.274-.726.058-1.038a.685.685 0 0 0-.217-.196c-.073-.044-.105-.04-.07-.13.033-.085.084-.164.118-.251a.607.607 0 0 0 .038-.265c-.025-.376-.319-.795-.73-.785-.227.006-.227.359 0 .354.097-.003.192.054.263.149.08.108.127.236.114.365-.013.136-.156.298-.172.463a.38.38 0 0 0 .097.286c.059.066.142.095.21.146.105.08.122.211.113.34-.008.155-.078.287-.148.424a.515.515 0 0 0-.071.307c.013.108.07.195.138.278.107.13.187.248.211.42.06.453-.228.86-.47 1.214-.053.079-.014.195.064.241.087.052.187.016.241-.063Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M17.769 31.31a.454.454 0 0 1-.227-.06.467.467 0 0 1-.214-.296.435.435 0 0 1 .062-.34l.02-.028c.222-.322.448-.657.403-.993-.011-.09-.048-.161-.147-.28a.802.802 0 0 1-.202-.422.802.802 0 0 1 .099-.466c.067-.131.115-.223.12-.314.005-.083-.01-.106-.01-.107-.014-.01-.032-.02-.05-.03-.053-.032-.127-.074-.192-.147a.649.649 0 0 1-.167-.498c.014-.139.072-.257.12-.352a.606.606 0 0 0 .051-.116.249.249 0 0 0-.06-.168c-.024-.034-.044-.04-.044-.04a.43.43 0 0 1-.444-.434.452.452 0 0 1 .439-.471c.008-.002.015-.002.025-.002.565 0 .953.537.988 1.043a.895.895 0 0 1-.11.504.924.924 0 0 1 .192.2c.234.337.224.817-.022 1.289.045.053.096.112.14.186.129.216.193.486.183.763-.019.526-.281.95-.571 1.373a.462.462 0 0 1-.382.207"
      fill="#1D1D1B"
    />
    <path
      d="M17.769 31.31a.454.454 0 0 1-.227-.06.467.467 0 0 1-.214-.296.435.435 0 0 1 .062-.34l.02-.028c.222-.322.448-.657.403-.993-.011-.09-.048-.161-.147-.28a.802.802 0 0 1-.202-.422.802.802 0 0 1 .099-.466c.067-.131.115-.223.12-.314.005-.083-.01-.106-.01-.107-.014-.01-.032-.02-.05-.03-.053-.032-.127-.074-.192-.147a.649.649 0 0 1-.167-.498c.014-.139.072-.257.12-.352a.606.606 0 0 0 .051-.116.249.249 0 0 0-.06-.168c-.024-.034-.044-.04-.044-.04a.43.43 0 0 1-.444-.434.452.452 0 0 1 .439-.471c.008-.002.015-.002.025-.002.565 0 .953.537.988 1.043a.895.895 0 0 1-.11.504.924.924 0 0 1 .192.2c.234.337.224.817-.022 1.289.045.053.096.112.14.186.129.216.193.486.183.763-.019.526-.281.95-.571 1.373a.462.462 0 0 1-.382.207Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M17.674 27.283c-1.791 0-3.584.047-5.377.068-.508.005-1.015.01-1.523.01-.227 0-.227.353 0 .353 1.794 0 3.587-.048 5.38-.069.507-.005 1.014-.01 1.52-.01.228 0 .228-.352 0-.352"
      fill="#1D1D1B"
    />
    <path
      d="M17.674 27.283c-1.791 0-3.584.047-5.377.068-.508.005-1.015.01-1.523.01-.227 0-.227.353 0 .353 1.794 0 3.587-.048 5.38-.069.507-.005 1.014-.01 1.52-.01.228 0 .228-.352 0-.352Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M10.775 27.99a.435.435 0 0 1-.337-.15.46.46 0 0 1-.11-.303c0-.258.192-.452.447-.452.505-.001 1.012-.004 1.518-.01.64-.007 1.281-.018 1.922-.028 1.132-.02 2.306-.04 3.46-.04.163 0 .307.082.384.218a.473.473 0 0 1 0 .47.435.435 0 0 1-.385.218 234.233 234.233 0 0 0-3.442.038c-1.132.019-2.304.038-3.457.04Z"
      fill="#1D1D1B"
    />
    <path
      d="M10.775 27.99a.435.435 0 0 1-.337-.15.46.46 0 0 1-.11-.303c0-.258.192-.452.447-.452.505-.001 1.012-.004 1.518-.01.64-.007 1.281-.018 1.922-.028 1.132-.02 2.306-.04 3.46-.04.163 0 .307.082.384.218a.473.473 0 0 1 0 .47.435.435 0 0 1-.385.218 234.233 234.233 0 0 0-3.442.038c-1.132.019-2.304.038-3.457.04Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M11.04 29.253h6.899c.227 0 .227-.353 0-.353h-6.9c-.227 0-.227.353 0 .353"
      fill="#1D1D1B"
    />
    <path
      d="M11.04 29.253h6.899c.227 0 .227-.353 0-.353h-6.9c-.227 0-.227.353 0 .353Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      d="M17.94 29.529h-6.9a.44.44 0 0 1-.448-.454.44.44 0 0 1 .447-.452h6.9c.254 0 .448.195.448.452a.441.441 0 0 1-.447.454"
      fill="#1D1D1B"
    />
    <path
      d="M17.94 29.529h-6.9a.44.44 0 0 1-.448-.454.44.44 0 0 1 .447-.452h6.9c.254 0 .448.195.448.452a.441.441 0 0 1-.447.454Z"
      stroke="#1D1D1B"
      strokeWidth=".314"
    />
    <path
      fill="#1D1D1B"
      d="M4.09 11.807.44 9.98l.248-.494 3.65 1.826zM7.013 8.158 4.578 3.532l.489-.258 2.435 4.627zM24.205 13.904l-.057-.549 3.53-.367.056.55zM22.07 8.7l-.414-.367 3.529-4.016.416.364zM17.39 5.39l-.547-.079.73-5.112.548.078zM11.864 5.438 10.282.69l.523-.175 1.583 4.748zM.382 17.794l-.125-.538 3.65-.852.127.538zM27.974 20.096l-3.531-1.338.196-.517 3.53 1.338zM5.239 24.802l-.344-.432 2.434-1.949.345.433zM23.147 25.406l-2.314-1.948.356-.423 2.314 1.948z"
    />
    </g>
  </React.Fragment>,
  "Bulp",
  {
    viewBox: "0 0 29 32",
    width: "29px",
    height: "32px",
  }
);
