import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

export type TCollapseBodyStyle = {
	height: number;
	opacity: number;
}

export type TCollapseHook = {
	isOpen: boolean;
	bodyRef: React.RefObject<HTMLDivElement>;
	bodyStyle: TCollapseBodyStyle;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	AnimatedBody: any;
}

const AnimatedBody = styled.div`
	transition: height 0.2s ease-in, opacity 0.3s ease-in;
	will-change: height, opacity;
	overflow: hidden;
`;

export default function useCollapse({
	initialHeight = 0,
	startOpen = false
}: any): TCollapseHook {
	const [isOpen, setIsOpen] = useState(startOpen);
	const [bodyStyle, setBodyStyle] = useState({
		height: initialHeight,
		opacity: initialHeight ? 1 : 0
	});

	const bodyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isOpen && bodyRef.current) {
			setBodyStyle({
				height: bodyRef.current.scrollHeight,
				opacity: 1
			});
		} else {
			setBodyStyle({
				height: initialHeight,
				opacity: initialHeight ? 1 : 0
			});
		}

	}, [isOpen]);

	return { isOpen, bodyRef, bodyStyle, setIsOpen, AnimatedBody };
}