import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M7.402.556c-.592 0-1.071.48-1.071 1.071v11.754L2.269 9.025a1.072 1.072 0 1 0-1.565 1.463l5.913 6.347a1.072 1.072 0 0 0 1.568 0l5.915-6.347a1.072 1.072 0 1 0-1.567-1.463l-4.06 4.356V1.627c0-.591-.479-1.071-1.07-1.071Z"
      fill="CurrentColor"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "ArrowDown",
  {
    viewBox: "0 0 15 18",
    width: "18px",
    height: "18px",
  }
);