import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(20)">
        <path
          d="M25.53 5.29s6.569 2.613 3.78 10.255c-2.788 7.642-14.08.221-14.08.221s-4.7-1.858-9.213.103c-4.512 1.961-9.282-4.68-2.99-12.766C9.322-4.984 25.53 5.291 25.53 5.291"
          fill="#FE7875"
          mask="url(#b)"
        />
      </g>
      <path
        d="M26.728 18.124s15.226-1.529 18.415 6.148c3.19 7.677-3.553 11.772-3.553 11.772l-4.207 7.505-7.095.451-6.222-21.614s-.728-4.14 2.662-4.262"
        fill="#F4B909"
      />
      <path
        d="M5.533 24.285s-8.36-1.288-4.528 6.047c3.83 7.334 6.598 9.545 8.421 9.847 1.823.302 9.061 3.493 9.2 3.804.139.312 7.374-3.87 7.374-3.87L19.148 22s-8.572 4.128-13.615 2.285"
        fill="#83E3BD"
      />
      <path
        d="m67 43.6-3.51 9.087s-3.068 7.307-8.407 2.9c-5.34-4.409-10.605-8.736-10.605-8.736L44 38.479 58.407 34 67 43.6Z"
        fill="#BCAAD1"
      />
      <path
        d="M44.128 61.916s6.795-8.167 5.766-8.908c-1.03-.741-9.357-6.854-9.503-6.975-.146-.121-16.391.132-16.391.132l1.297 11.243L32.18 62l11.948-.084Z"
        fill="#86CDD7"
      />
    </g>
  </React.Fragment>,
  "Progress",
  {
    viewBox: "0 0 67 62",
    width: "67px",
    height: "62px",
  }
);
