import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#1D1D1B" fillRule="evenodd">
      <path d="M39.02 22.618c-8.534 0-15.475 6.943-15.475 15.479 0 8.535 6.941 15.479 15.475 15.479 8.534 0 15.476-6.944 15.476-15.48 0-8.535-6.942-15.478-15.476-15.478m0 33.402c-9.881 0-17.92-8.04-17.92-17.923s8.039-17.924 17.92-17.924c9.881 0 17.92 8.04 17.92 17.924 0 9.883-8.039 17.923-17.92 17.923" />
      <path d="M35.723 53.936a46.32 46.32 0 0 0-3.668 9.513c-.858 3.272-1.205 6.603-1.647 9.944-.406 3.074-1.003 6.221-2.754 8.841-1.685 2.52-4.262 4.514-6.83 6.067-2.989 1.806-6.513 3.088-10.048 2.513-.615-.1-1.226.16-1.409.8-.155.545.191 1.31.8 1.409 3.38.55 6.667-.161 9.733-1.62 2.975-1.414 5.845-3.482 8.076-5.913 2.287-2.49 3.568-5.503 4.21-8.798.66-3.373.822-6.82 1.51-10.19a43.845 43.845 0 0 1 4.006-11.41c.676-1.307-1.3-2.468-1.98-1.156" />
      <path d="M4.43 90.857c1.31-1.627 3.269-2.995 5.394-3.235 1.12-.128 1.463.67 1.102 1.64-.389 1.038-1.3 1.809-1.748 2.828-.226.513.031 1.147.659 1.15 1.858.004 4.016-.018 5.48 1.32 1.364 1.249 1.149 3.219.077 4.593-1.155 1.482-3.105 2.124-4.865 2.533-2.137.495-4.327.629-6.514.47-.98-.072-.976 1.455 0 1.527 2.654.195 5.372-.038 7.931-.787 2.103-.614 4.253-1.74 5.269-3.784.934-1.882.784-4.158-.82-5.632-1.788-1.644-4.255-1.762-6.558-1.768l.66 1.15c.582-1.323 1.796-2.292 2.041-3.766.21-1.257-.307-2.558-1.617-2.922-1.317-.368-2.893.244-4.069.808a10.17 10.17 0 0 0-3.501 2.795c-.612.759.462 1.846 1.08 1.08M29.854 15.682A75.017 75.017 0 0 0 26.347.525c-.318-.924-1.794-.526-1.473.406a73.29 73.29 0 0 1 3.453 14.751c.12.963 1.65.975 1.527 0M52.282 18.554a44.053 44.053 0 0 1 6.575-11.555c.604-.762-.47-1.851-1.08-1.08a46.348 46.348 0 0 0-6.967 12.23c-.352.918 1.123 1.316 1.472.405M67.848 42.237a43.952 43.952 0 0 1 12.39 1.42c.951.249 1.356-1.224.405-1.473a45.511 45.511 0 0 0-12.795-1.476c-.98.027-.984 1.555 0 1.529M48.674 58.244c.313 2.688 2.386 4.617 3.814 6.778a34.77 34.77 0 0 1 3.758 7.527c.327.919 1.804.523 1.473-.406a36.514 36.514 0 0 0-3.51-7.27c-.693-1.097-1.462-2.133-2.242-3.167-.775-1.024-1.612-2.146-1.765-3.462-.114-.966-1.643-.976-1.528 0M17.976 58.433c-3.485 2.11-6.972 4.218-10.46 6.327-.84.507-.073 1.83.77 1.32 3.486-2.11 6.975-4.22 10.462-6.328.84-.508.072-1.83-.772-1.319M13.036 34.143c-4.104-.129-8.2-.459-12.271-.99-.413-.053-.761.39-.765.763-.004.458.357.711.765.765 4.07.53 8.167.86 12.271.99.984.03.982-1.497 0-1.528" />
    </g>
  </React.Fragment>,
  "SubjectEnergy",
  {
    viewBox: "0 0 81.185 103.749",
    width: "81.185px",
    height: "103.749px",
  }
);
