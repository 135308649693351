import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
	user-select: none;
	display: inline-block;
	flex-shrink: 0;
`;

export interface ISvgIcon {
	/**
	 * Node passed into the SVG element.
	 */
	children: React.ReactNode;
	/**
	 * Provides a human-readable title for the element that contains it.
	 * https://www.w3.org/TR/SVG-access/#Equivalent
	 */
	titleAccess?: string;
	/**
	 * Allows you to redefine what the coordinates without units mean inside an SVG element.
	 * For example, if the SVG element is 500 (width) by 200 (height),
	 * and you pass  ? viewBox : '0 0 512 512'="0 0 50 20",
	 * this means that the coordinates inside the SVG will go from the top left corner (0,0)
	 * to bottom right (50,20) and each unit will be worth 10px.
	 */
	viewBox?: string;
	fillRule?: string;
	clipRule?: string;
}

const SvgIcon: React.FC<ISvgIcon> = ({
	children,
	titleAccess,
	viewBox,
	...rest
}) => {
	return (
		<StyledSvg
			focusable="false"
			viewBox={viewBox}
			aria-hidden={titleAccess ? 'false' : 'true'}
			role="img"
			{...rest}>
			{children}
			{titleAccess ? <title>{titleAccess}</title> : null}
		</StyledSvg>
	);
};

export default SvgIcon;
