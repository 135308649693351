import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#1D1D1B" fillRule="evenodd">
      <path d="M2.81 45.643H95.01c1.178 0 1.18-1.833 0-1.833H2.81c-1.179 0-1.181 1.833 0 1.833" />
      <path d="M15.424 44.038V22.016c0-1.18-1.833-1.181-1.833 0v22.022c0 1.179 1.833 1.182 1.833 0" />
      <path d="M14.85 21.108c-1.69-.223-3.455.327-5.118-.05-.918-.206-2.41-1.285-1.799-2.371.445-.786-.453-1.735-1.253-1.254-1.13.68-2.555.436-3.573-.36-1.113-.87-1.475-2.324-1.175-3.67.778-3.486 4.481-4.63 7.656-4.231.512.064.887-.474.916-.917.168-2.683 1.406-6.022 4.483-6.403 1.177-.146 2.426.421 3.024 1.461.601 1.045.02 2.438 1.031 3.3.514.437 1.133.428 1.772.461a5.64 5.64 0 0 1 1.964.452c1.14.504 2.061 1.489 2.178 2.77.053.58-.054 1.186-.395 1.67-.422.599-1.02.986-1.237 1.727-.424 1.448.782 2.68.496 4.104-.282 1.405-1.55 2.483-2.856 2.92-1.73.582-3.599.432-5.387.309-1.176-.082-1.172 1.752 0 1.834 3.412.235 7.465.176 9.432-3.153a4.925 4.925 0 0 0 .679-2.088c.076-.926-.254-1.71-.51-2.575-.136-.458-.194-.768.122-1.169.246-.31.55-.559.78-.887.46-.662.686-1.46.717-2.26.133-3.468-3.149-5.348-6.211-5.495-.41-.02-.376.002-.414-.395-.032-.354-.02-.704-.077-1.058-.125-.778-.494-1.494-1.005-2.09C18.088.527 16.516-.119 14.987.018 10.813.395 8.904 4.544 8.67 8.255c.306-.305.61-.611.918-.916C5.264 6.794.494 9.068.028 13.803c-.196 1.974.67 3.858 2.313 4.964 1.554 1.05 3.64 1.227 5.264.248l-1.254-1.254c-.983 1.746.119 3.674 1.712 4.59 2.076 1.195 4.545.294 6.787.59.494.066.912-.469.916-.917.005-.548-.427-.852-.916-.916M3.205 32.19c5.727-.494 11.578-1.016 17.293-.134 5.524.855 10.449 3.215 14.819 6.666 2.425 1.916 4.662 4.053 6.835 6.246.83.84 2.127-.456 1.295-1.295-3.939-3.978-8.166-7.85-13.187-10.418-5.251-2.683-11.088-3.522-16.93-3.489-3.382.021-6.756.3-10.125.592-1.165.1-1.177 1.934 0 1.833" />
      <path d="M37.577 39.592c13.065-10.342 26.619-21.01 42.927-25.608 4.406-1.244 8.928-2 13.507-2.135 1.176-.036 1.183-1.869 0-1.833-17.193.514-32.594 9.276-46.081 19.26-3.946 2.923-7.798 5.971-11.65 9.02-.924.732.38 2.021 1.297 1.296" />
      <path d="M27.17 31.73c-1.129-4.8 3.558-9.338 7.396-11.36 4.042-2.126 8.882-2.27 12.79.233 2.009 1.288 3.59 3.2 4.316 5.485.353 1.12 2.124.642 1.767-.486-1.543-4.87-6.184-8.147-11.174-8.657-5.39-.55-10.866 2.294-14.213 6.416-2.016 2.482-3.41 5.623-2.65 8.856.27 1.148 2.038.663 1.768-.487" />
    </g>
  </React.Fragment>,
  "SubjectNature",
  {
    viewBox: "0 0 95.895 45.643",
    width: "95.895px",
    height: "45.643px",
  }
);
