import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g transform="translate(.5)" fill="currentCOlor" fillRule="evenodd">
      <rect width="24" height="6" rx="3" />
      <rect y="18" width="24" height="6" rx="3" />
      <rect y="9" width="24" height="6" rx="3" />
    </g>
  </React.Fragment>,
  "ListRow",
  {
    viewBox: "0 0 25 24",
    width: "25px",
    height: "24px",
  }
);
