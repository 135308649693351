import React from "react";
import styled from "styled-components";
import { IFooter } from "../../../Types";
import { Grid, Logo, Heading, Button, ResponsiveImage } from "../..";
import CMSIcon from "../../CMS/CMSIcon";

const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.footer.bg};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 570 570'%3E%3Cpath  fill='%2378C2A2' d='M569.723698,297.430331 C576.589014,140.182887 454.679735,7.14152451 297.430946,0.276219205 C140.182156,-6.58797665 7.14170259,115.318899 0.27638695,272.567453 C-6.59003814,429.817115 115.320349,562.858478 272.56803,569.723783 C429.81682,576.587979 562.858382,454.679994 569.723698,297.430331 L569.723698,297.430331 Z M282.726046,288.071441 C286.962132,295.127275 291.181465,302.198638 295.114894,309.445259 C317.265837,350.266157 330.352695,399.094611 321.516441,445.547087 C312.680187,492.000672 278.131462,534.119373 235,539 L282.726046,288.071441 C274.175697,273.828884 265.554989,259.642899 259.075218,244.177967 C242.884726,205.536712 241.317832,159.859591 254.816796,120.029238 C268.31576,80.1933394 296.714176,46.7044741 332,29 L282.726046,288.071441 Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position-x: calc(50% - 72px);
  background-position-y: -67px;
  color: ${(props) => props.theme.footer.txt};
  padding: 40px 30px 0;
  position: relative;

  ${(props) => props.theme.breakpoints("xsm")`
  	background-size: 120%;
	`}

  ${(props) => props.theme.breakpoints("sm")`
  	background-size: 812px;
	background-position-x: calc(50% - 406px);
	background-position-y: calc(50% - 20px);
	`}

  ${(props) => props.theme.breakpoints("sm")`
	padding: 60px 30px 0;
	position: static;
	min-height: 600px;
	`}

	${(props) => props.theme.breakpoints("md")`
	  padding: 60px 70px 0;
  `}

	small {
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    padding: 10px 0;
  }

  a {
    color: ${(props) => props.theme.colors.neutralWhite};
  }
`;

const FooterWrapper = styled.div`
  margin: 0 auto;
  max-width: 1480px;
`;

const LogoLink = styled.a`
  margin-bottom: 22px;
  ${(props) => props.theme.breakpoints("sm")`
		margin-bottom: 32px;
	`}
  flex-grow: 2;
`;

const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${(props) => props.theme.breakpoints("sm")`
	text-align: left;
	`}
`;

const StyledAbstract = styled.div`
  margin-bottom: 30px;
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 8px auto 33px;
  padding: 0;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
	flex-direction: row;
  	justify-content: flex-start;
	`}

  ${(props) => props.theme.breakpoints("sm")`
	flex-direction: column;
 	justify-content: space-between;
  	min-width: 100%;
  	margin: 8px 0 33px;
    text-align: left;
	`}

  ${(props) => props.theme.breakpoints("md")`
		flex-direction: row;
	`}
`;

const LinkListItem = styled.li`
  margin: 25px 0 0;
  padding: 0;

  ${(props) => props.theme.breakpoints("xsm")`
		padding: 0 30px 0 0;
	`}

  ${(props) => props.theme.breakpoints("sm")`
  	padding: 0;
	`}
`;

const ServiceLinksList = styled.ul`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: 8px auto 33px;
  padding: 0;

  ${(props) => props.theme.breakpoints("xsm")`
	flex-direction: row;
	flex-wrap: wrap;
	min-width: 100%;
  	justify-content: center;
`}
  ${(props) => props.theme.breakpoints("sm")`
	flex-direction: column;
	min-width: auto;
  	justify-content: space-between;
	margin-top: 90px;
`}

  ${(props) => props.theme.breakpoints("md")`
	  flex-direction: column;
  `}
`;

const ServiceLinksItem = styled.li`
  margin: 25px 0 0;
  padding: 0;

  ${(props) => props.theme.breakpoints("xsm")`
		padding: 0 15px;
	`}

  ${(props) => props.theme.breakpoints("sm")`
		padding: 0;
	`}

  a {
    width: 100%;
  }
`;

const Fundings = styled.div`
  text-align: center;

  ${(props) => props.theme.breakpoints("sm")`
    text-align: left;
  `}

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`;

const FundingList = styled.ul`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  ${(props) => props.theme.breakpoints("sm")`
  	flex-direction: column;
  	flex-wrap: unset;
	`}

  img {
    width: 200px;
  }
`;

const FundingListItem = styled.li`
  margin: 0;
  padding: 30px 0;
  flex-basis: 100%;

  ${(props) => props.theme.breakpoints("sm")`
	flex-basis: auto;
  	padding: 43px 0 27px;
    width: 150px;
  `}

  &:first-child {
    border-bottom: 1px solid;
    padding: 8px 0 41px;
    flex-basis: 100%;
  }
`;

const ContitoListItem = styled.li`
  margin: 0;
  padding: 30px 0;
  text-align: left;
  flex-basis: 100%;

  ${(props) => props.theme.breakpoints("sm")`
  	padding: 0 0 27px;
    width: 150px;
  `}

  div {
    margin: 0 auto;
    max-width: 200px;
  }
`;

const DisclaimerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.darkGreen};
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin: 0 -30px;
  text-align: center;

  ${(props) => props.theme.breakpoints("sm")`
	font-size: 11px;
	margin: 0 -70px;
	padding: 19px 0;
  `}

  & > div {
    margin: 0 auto;
    max-width: 300px;
    padding: 50px 0;

    ${(props) => props.theme.breakpoints("sm")`
		max-width: 100%;
		padding: 0;
	`}

    & > div {
      padding: 3px 0;

      ${(props) => props.theme.breakpoints("sm")`
			display: inline-block;
			padding: 0 3px;
		`}
    }
  }
`;

const Footer: React.FC<IFooter> = (props) => {
  const { Footer } = props;
  const {
    Heading: FooterHeading,
    Abstract,
    CTA,
    Links,
    Buttons,
    ImageLinksLabel,
    ImageLinks,
    AddressLogo,
    Address,
    Disclaimer,
  } = Footer;
  return (
    <StyledFooter>
      <FooterWrapper>
        <Grid noPadding>
          <Grid item xs={24} smOffset={0} sm={12} noPadding>
            <BrandWrapper>
              <LogoLink href={props.HomeUrl}>
                <Logo white={true} />
              </LogoLink>
              <Heading size={100}>{FooterHeading}</Heading>
              <StyledAbstract>{Abstract}</StyledAbstract>
              <div>
                <Button
                  component="a"
                  href={CTA.Url}
                  variant={`Outline`}
                  version={"on-dark"}
                  target={CTA.Target}
                >
                  <span className="label">{CTA?.Label}</span>
                  {Boolean(CTA?.Icon) && <CMSIcon icon={CTA?.Icon} />}
                </Button>
              </div>
              {Boolean(Links) && (
                <LinkList>
                  {Links.map(({ Url, Label, Target }, key) => {
                    return (
                      <LinkListItem key={key}>
                        <a href={Url} {...(Target && { Target })}>
                          {Label}
                        </a>
                      </LinkListItem>
                    );
                  })}
                </LinkList>
              )}
            </BrandWrapper>
          </Grid>
          <Grid item xs={24} sm={8}>
            {Boolean(Buttons) && (
              <ServiceLinksList>
                {Buttons.map(({ Url, Label, Target, Icon }, key) => {
                  return (
                    <ServiceLinksItem key={key}>
                      <Button
                        component="a"
                        href={Url}
                        variant={`CTA`}
                        version={"dark"}
                        {...(Target && { Target })}
                      >
                        <span className="label">{Label}</span>
                        {Boolean(Icon) && <CMSIcon icon={Icon} />}
                      </Button>
                    </ServiceLinksItem>
                  );
                })}
              </ServiceLinksList>
            )}
          </Grid>
          <Grid item xs={24} sm={4}>
            {Boolean(ImageLinksLabel) && (
              <Fundings>
                <small>{ImageLinksLabel}</small>
                <FundingList>
                  {ImageLinks.map(({ Image, Link }, key) => {
                    return (
                      <FundingListItem key={key}>
                        <a href={Link.Url}>
                          <ResponsiveImage {...Image} />
                        </a>
                      </FundingListItem>
                    );
                  })}
                  <ContitoListItem>
                    <ResponsiveImage {...AddressLogo} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Address,
                      }}
                    />
                  </ContitoListItem>
                </FundingList>
              </Fundings>
            )}
          </Grid>
        </Grid>
      </FooterWrapper>
      <DisclaimerWrapper>
        <div
          dangerouslySetInnerHTML={{
            __html: Disclaimer,
          }}
        />
      </DisclaimerWrapper>
    </StyledFooter>
  );
};

export default Footer;
