import React from "react";
import styled, { css } from "styled-components";

const StyledSpacer = styled.div`
  background-color: transparent;

  ${(props) => {
    switch (props.size) {
      case "small":
        return css`
          height: 40px;
        `;
      case "medium":
        return css`
          height: 60px;
        `;
      case "large":
        return css`
          height: 80px;
        `;
    }
  }}
`;

export interface ISpacer {
  Size: string;
  Theme?: string;
}

const Spacer: React.FC<ISpacer> = ({ Size = "large" }) => {
  return <StyledSpacer size={Size} />;
};

export default Spacer;
