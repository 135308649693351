import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import {
  Heading,
  Button,
  ResponsiveImage,
  SlideUp,
  RichText,
  ComponentWrapper,
} from "../..";
import ReactSelect from "react-select";
import { IImage, IUrl } from "../../../Types";
import CMSIcon from "../../CMS/CMSIcon";

const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    borderRadius: "8px",
    padding: 0,
    zIndex: 3000,
    cursor: "pointer",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: "8px",
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    fontFamily: "Avenir",
    color: "#939393",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    border: "1px solid #BCB8B1",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "48px",
    height: "48px",
    cursor: "pointer",
    boxShadow: "0 !important",
    paddingLeft: "16px",
    "&:hover": {
      border: "1px solid #BCB8B1 !important",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "48px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "normal",
    color: "#4A4A4A",
    background: state.isSelected ? "#f4f4f4" : "#fff",
    padding: "0 8px",
    height: "48px",
    maxHeight: "48px",
    minHeight: "32px",
    borderBottom: "1px solid #BCB8B1",
    paddingLeft: "16px",

    "&:hover": {
      background: "#f4f4f4",
      "& path": {
        stroke: "#fff",
      },
    },

    "&:last-of-type": {
      borderBottom: 0,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: "#fff",
    padding: "0",
    height: "48px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "& > div": {
      borderLeft: "1px solid #BCB8B1",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#4A4A4A",
  }),
};

const CommuneFilter = styled.div`
  position: relative;
  margin: 0 auto 80px;
  max-width: 330px;
  z-index: 2;
`;

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
`;
const ListItem = styled.li`
  background-color: ${(props) =>
    props.isOpen ? "rgba(255,255,255,1)" : "rgba(255,255,255,0)"};
  box-shadow: ${(props) =>
    props.isOpen
      ? "0 0 12px 0 rgba(0, 0, 0, 0.05)"
      : "0 0 0 0 rgba(0, 0, 0, 0)"};
  transition: all 0.3s ease-in-out;
  border-top: 1px solid RGBA(121, 121, 121, 0.19);
  margin: 0;
  padding: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;
const ListHeader = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ${(props) => props.theme.breakpoints("sm")`
    padding: 24px 32px;
  `}
`;
const ImageContainer = styled.div`
  img {
    height: auto;
    width: 50px;
  }
`;
const ListTitle = styled.div`
  flex-grow: 1;
  padding: 0 32px;
`;
const ListIcon = styled.div`
  width: 20px;
  svg {
    transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(45deg)")};
    transition: all 0.3s ease;
    width: 100%;
  }
`;
const TextContainer = styled.div`
  padding: ${(props) => (props.isOpen ? "10px 20px 20px" : "0 20px")};
  transition: all 0.3s ease;

  ${(props) => props.theme.breakpoints("xsm")`
    padding: ${(props) => (props.isOpen ? "20px 60px 50px" : "0 60px")};
  `}

  & > div {
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.breakpoints("xsm")`
      display: block;
    `}
  }
`;

const SubHeaderContent = styled.div`
  order: 2;
`;

const ListContent = styled.div`
  order: 4;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
`;
const ListButtons = styled.div`
  order: 5;
  padding: 30px 0;

  ${(props) => props.theme.breakpoints("xsm")`
    padding-bottom: 0;
  `}

  a {
    margin: 0 11px 11px 0;

    svg {
      height: auto;
      margin-left: 10px;
      max-width: 22px;
    }

    & > span {
      align-items: center;
      display: inline-flex;
    }
  }
`;

const QuoteContainer = styled.blockquote`
  order: 3;
  margin: 30px 20px;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
    float: right;
    max-width: 350px;
    margin: 10px 40px 20px;
    width: 50%;
  `}

  h2 {
    order: 1;
  }
`;

const QuoteAuthor = styled.p`
  order: 3;
`;

export interface ICommunesListItem {
  CommuneLogo: IImage;
  CommuneName: string;
  CommuneId: string;
  Header: string;
  SubHeader: string;
  Content: string;
  Quote: string;
  Author: string;
  Buttons: IUrl[];
  index?: string;
  forceOpen?: boolean;
}

export interface ICommunesList {
  Communes: ICommunesListItem[];
  Configuration: any;
}

const CommunesListItem: React.FC<ICommunesListItem> = ({
  CommuneName,
  CommuneId,
  Header,
  SubHeader,
  CommuneLogo,
  Content,
  Quote,
  Author,
  Buttons = [],
  forceOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(forceOpen);

  return (
    <ListItem isOpen={isOpen}>
      <ListHeader onClick={() => setIsOpen(!isOpen)}>
        <ImageContainer>
          <ResponsiveImage {...CommuneLogo} />
        </ImageContainer>
        <ListTitle>
          <Heading noMargin size={300}>
            {CommuneName}
          </Heading>
        </ListTitle>
        <ListIcon isOpen={isOpen}>
          <CMSIcon icon="close" />
        </ListIcon>
      </ListHeader>
      {Boolean(isOpen) && (
        <TextContainer isOpen={isOpen}>
          <SlideUp>
            {Boolean(Quote) && (
              <QuoteContainer>
                <CMSIcon icon="quote" />
                <Heading size={200} noMargin>
                  {Quote}
                </Heading>
                <QuoteAuthor>{Author}</QuoteAuthor>
              </QuoteContainer>
            )}
            <Heading noMargin size={100}>
              {Header}
            </Heading>
            <SubHeaderContent
              dangerouslySetInnerHTML={{
                __html: SubHeader,
              }}
            />
            <ListContent>
                <RichText htmlContent={Content} />
            </ListContent>
            <ListButtons>
              {Buttons?.map((button, index) => {
                return (
                  <Button
                    key={`ListItem_${CommuneId}_${index}`}
                    component={`a`}
                    href={button?.Url}
                    id={button?.Id ? button?.Id : undefined}
                    variant={`CTA`}
                    target={button?.Target}
                  >
                    <span className="label">{button?.Label}</span>
                    {Boolean(button?.Icon) && <CMSIcon icon={button?.Icon} />}
                  </Button>
                );
              })}
            </ListButtons>
          </SlideUp>
        </TextContainer>
      )}
    </ListItem>
  );
};

const CommunesList: React.FC<ICommunesList> = ({
  Communes = [],
  Configuration,
}) => {
  const [selectedCommune, setSelectedCommune] = useState<string>("");
  const [filteredCommunes, setFilteredCommunes] = useState<any>([]);
  const [highlightedCommune, setHighlightedCommune] = useState<any>(null);

  const handleCommuneChange = (commune) => {
    setSelectedCommune(commune.value);
  };

  useEffect(() => {
    setHighlightedCommune(null);
    let tempFilteredCommunes = [...Communes];

    let result = tempFilteredCommunes.filter(
      (entry) => entry.CommuneId !== selectedCommune
    );
    let highlight = tempFilteredCommunes.filter(
      (entry) => entry.CommuneId === selectedCommune
    );
    setFilteredCommunes(result);
    setHighlightedCommune(highlight[0]);
  }, [selectedCommune]);

  // Set Commune Select options
  const selectOptions = [
    ...Communes.map(({ CommuneId: value, CommuneName: label }) => {
      return {
        value,
        label,
      };
    }),
  ];

  return (
    <ComponentWrapper>
      <CommuneFilter className="animate">
        <ReactSelect
          value={selectOptions.filter((option) => {
            return option.value === selectedCommune;
          })}
          onChange={handleCommuneChange}
          placeholder={Configuration.Filter.Label}
          options={selectOptions}
          styles={selectStyles}
          isSearchable={false}
        />
      </CommuneFilter>
      <ListContainer className="animate">
        {Boolean(highlightedCommune) && (
          <Fragment key={highlightedCommune.CommuneId}>
            <CommunesListItem {...highlightedCommune} forceOpen={true} />
          </Fragment>
        )}

        {filteredCommunes.map((entry) => {
          return (
            <Fragment key={`ListItem_${entry.CommuneId}`}>
              <CommunesListItem {...entry} forceOpen={false} />
            </Fragment>
          );
        })}
      </ListContainer>
    </ComponentWrapper>
  );
};

export default CommunesList;
