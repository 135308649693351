import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M60 7.219c-4.084 1.046-7.199 2.59-9.346 4.63-2.146 2.04-3.115 4.472-2.905 7.297 1.36 1.674 3.063 3.008 5.104 4.002a48.32 48.32 0 0 0 6.519 2.59c.523 1.882.602 3.792.235 5.727a14.823 14.823 0 0 1-2.12 5.336c-1.047 1.622-2.278 3.008-3.691 4.159-1.414 1.15-2.854 1.83-4.32 2.04a21.109 21.109 0 0 1-6.44-1.334c-2.094-.785-3.926-1.91-5.497-3.374-1.57-1.465-2.853-3.217-3.848-5.257-.995-2.04-1.492-4.368-1.492-6.984 0-3.139.628-6.146 1.885-9.024 1.256-2.877 3.01-5.466 5.262-7.768 2.25-2.302 4.947-4.263 8.089-5.885C50.575 1.752 54.03.628 57.8 0L60 7.219Zm-32.199 0c-4.084 1.046-7.199 2.59-9.346 4.63-2.146 2.04-3.115 4.472-2.905 7.297 1.361 1.674 3.063 3.008 5.104 4.002a48.32 48.32 0 0 0 6.519 2.59c.523 1.882.602 3.792.235 5.727a14.823 14.823 0 0 1-2.12 5.336c-1.047 1.622-2.278 3.008-3.691 4.159-1.414 1.15-2.854 1.83-4.32 2.04a21.109 21.109 0 0 1-6.44-1.334c-2.094-.785-3.926-1.91-5.497-3.374-1.57-1.465-2.853-3.217-3.848-5.257C.497 30.995 0 28.667 0 26.05c0-3.139.628-6.146 1.885-9.024 1.256-2.877 3.01-5.466 5.262-7.768 2.25-2.302 4.947-4.263 8.089-5.885C18.377 1.752 21.832.628 25.602 0l2.199 7.219Z"
      fill="#8ECDB2"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "Quote",
  {
    viewBox: "0 0 60 43",
    width: "60px",
    height: "43px",
  }
);
