import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactDOMServer from 'react-dom/server';
import { concitoTheme } from './Themes';
import { ThemeProvider, ServerStyleSheet } from 'styled-components';
import { CMSContent } from './Components';
import GlobalStyles from './Components/Layout/GlobalStyles';
import { StateProvider } from './Contexts/StateProvider';

export interface ICMSPreviewProps {
	Theme: string;
	Components: any;
}

const CMSPreview: React.FC<ICMSPreviewProps> = ({
	Theme,
	Components,
	...rest
}) => {

	let theme = concitoTheme;

	return (
		<StateProvider PageArea={Theme}>
			<ThemeProvider theme={theme}>
				<React.Fragment>
					<GlobalStyles />
					<CMSContent
						Components={Components}
					/>
				</React.Fragment>
			</ThemeProvider>
		</StateProvider>
	);
};

global['Styled'] = { ServerStyleSheet };
global['React'] = React;
global['ReactDOM'] = ReactDOM;
global['ReactDOMServer'] = ReactDOMServer;
global['CMSPreview'] = CMSPreview;
export default CMSPreview;
