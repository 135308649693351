import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#1D1D1B" fillRule="evenodd">
      <path d="M2.178 72.616h73.226V59.902H2.178v12.714ZM.344 74.45h76.894V58.07H.344v16.38Z" />
      <path d="M30.747 58.069H46.47V9.086H30.747v48.983Zm-1.831 1.833h19.388V7.253H28.916v52.649Z" />
      <path d="M48.304 58.069h8.176V1.91h-8.176V58.07Zm-1.833 1.833h11.843V.077H46.471v59.825ZM33.88 19.247h9.698c.884 0 .885-1.375 0-1.375H33.88c-.883 0-.886 1.375 0 1.375M33.188 22.018h10.39c.884 0 .885-1.375 0-1.375h-10.39c-.884 0-.885 1.375 0 1.375M35.266 25.135h3.463c.36 0 .703-.316.689-.687-.019-.373-.304-.688-.689-.688h-3.463c-.359 0-.704.316-.687.688.016.372.302.687.687.687M33.534 52.843h9.698c.883 0 .885-1.374 0-1.374h-9.698c-.883 0-.886 1.374 0 1.374" />
      <path d="M50.041 5.625h4.733c.59 0 .59-.916 0-.916h-4.733c-.59 0-.59.916 0 .916M49.81 7.242h5.195c.59 0 .59-.917 0-.917H49.81c-.589 0-.59.917 0 .917M50.273 10.013h4.155c.59 0 .59-.917 0-.917h-4.155c-.59 0-.591.917 0 .917M49.926 11.629h4.617c.59 0 .59-.916 0-.916h-4.617c-.59 0-.59.916 0 .916M50.041 13.36h4.733c.59 0 .59-.916 0-.916h-4.733c-.59 0-.59.916 0 .916M50.39 55.233l3.461.134c.248.01.459-.218.459-.458 0-.257-.21-.45-.459-.458l-3.462-.135c-.248-.01-.459.218-.459.459 0 .256.211.447.46.458M8.052 58.074h10.634V1.915H8.052v56.16ZM6.22 59.907h14.3V.083h-14.3v59.824Z" />
      <path d="M20.519 58.074h8.037V17.826h-8.037v40.248Zm-1.833 1.833h11.702V15.991H18.686v43.916ZM10.252 8.015h5.714c.589 0 .59-.917 0-.917h-5.714c-.59 0-.59.917 0 .917M10.514 10.634a16.496 16.496 0 0 1 4.302.163c.244.042.494-.066.563-.32.062-.221-.074-.522-.32-.564a17.331 17.331 0 0 0-4.545-.195c-.247.022-.458.195-.458.459 0 .23.21.48.458.457" />
      <path d="M22.061 22.363v31.691c0 1.18 1.833 1.183 1.833 0v-31.69c0-1.18-1.833-1.182-1.833 0M25.774 54.054V42.105c0-.59-.916-.59-.916 0v11.95c0 .59.916.59.916 0" />
    </g>
  </React.Fragment>,
  "Stage2",
  {
    viewBox: "0 0 78 75",
    width: "78px",
    height: "75px",
  }
);
