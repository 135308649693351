import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

const _getWidth = () => window.innerWidth
	|| document.documentElement.clientWidth
	|| document.body.clientWidth;

const _throttle = (func, delay) => {
	let inProgress = false;
	return (...args) => {
		if (inProgress) {
			return;
		}
		inProgress = true;
		setTimeout(() => {
			func(...args); // Consider moving this line before the set timeout if you want the very first one to be immediate
			inProgress = false;
		}, delay);
	}
}

export default function useResize() {
	if (!isClient) {
		return;
	}

	let [width, setWidth] = useState(_getWidth());

	useEffect(() => {
		const handleResize = _throttle(() => {
			setWidth(_getWidth());
		}, 1000);

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return width;
}