import * as React from "react";
import {
  useMobileMenu,
  TMobileMenuHook,
  useDetectVisibility,
  TDetectVisibility,
} from "../Hooks";
import { useState } from "react";

export interface IAppState {
  PageArea: string;
  setPageArea: any;
  isMenuOpen: boolean;
  isHeaderVisible: boolean;
  isHeroVisible: boolean;
  isSearchOpen: boolean;
  toggleMenu?: TMobileMenuHook["toggleMenu"];
  setIsMenuOpen: TMobileMenuHook["setIsOpen"];
  setHeaderVisible: TDetectVisibility["setVisibility"];
  setHeroVisible: TDetectVisibility["setVisibility"];
  setSearchOpen: any;
  PageType: string;
  setPageType: any;
  isStickyBreadcrumbHidden: boolean;
  CurrentCommune: string;
  map: any;
  setCurrentCommune: any;
  setMap: any;
}

export const AppContext = React.createContext<IAppState>({
  PageArea: "",
  PageType: "",
  isStickyBreadcrumbHidden: false,
  isMenuOpen: false,
  isHeaderVisible: true,
  isHeroVisible: true,
  isSearchOpen: false,
  CurrentCommune: "",
  map: "",
  setIsMenuOpen: () => {},
  setHeaderVisible: () => {},
  setHeroVisible: () => {},
  setSearchOpen: () => {},
  setPageArea: () => {},
  setPageType: () => {},
  setCurrentCommune: () => {},
  setMap: () => {},
});

const StateProvider = (props) => {
  const { isOpen, toggleMenu, setIsOpen } = useMobileMenu();
  const { isVisible, setVisibility } = useDetectVisibility();
  const {
    isVisible: isHeroVisible,
    setVisibility: setHeroVisible,
  } = useDetectVisibility();
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [PageArea, setPageArea] = useState<string>(props.PageArea);
  const [PageType, setPageType] = useState<string>(props.PageType);
  const [CurrentCommune, setCurrentCommune] = useState<string>("");
  const [map, setMap] = useState<any>("");

  const context = {
    isMenuOpen: isOpen,
    toggleMenu,
    setIsMenuOpen: setIsOpen,
    isHeaderVisible: isVisible,
    setHeaderVisible: setVisibility,
    isHeroVisible,
    setHeroVisible,
    isSearchOpen,
    setSearchOpen,
    PageArea,
    setPageArea,
    PageType,
    setPageType,
    CurrentCommune,
    setCurrentCommune,
    ...props,
    map,
    setMap,
    ...props,
  };

  return <AppContext.Provider value={context} {...props} />;
};

const useAppContext = () => {
  return React.useContext(AppContext);
};

export { StateProvider, useAppContext };
