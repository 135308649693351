import React from 'react';
import styled from 'styled-components';

export interface ILink {
	href: string | undefined;
	target?: string;
	children: React.ReactNode;
	className?: string;
	disableTabIndex?: boolean;
	ariaLabel?: string;
	onClick?: (val: any) => void;
}

const StyledLink = styled.a`
	font-size: 18px;
	color: ${props => props.theme.linkColor};
	letter-spacing: -0.3px;
	text-decoration: none;
	line-height: 24px;

	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 0px;
		left: 0;
		height: 1px;
		margin: 1px 0 0;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		background-color: ${props => props.theme.linkColor};
	}

	&:hover {
		cursor: pointer;

		&:before,
		&:after {
			width: 100%;
			opacity: 1;
		}
	}
`;

const Link: React.FC<ILink> = ({ href,
	target,
	children,
	className,
	disableTabIndex,
	ariaLabel,
	onClick
}) => {
	return (
		<StyledLink
			className={className}
			target={target ? target : undefined}
			href={href}
			tabIndex={disableTabIndex ? -1 : undefined}
			aria-label={ariaLabel ? ariaLabel : undefined}
			onClick={onClick ? onClick : undefined}
		>
			{children}
		</StyledLink>
	);
}

export default Link;
