import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Logo } from "../../Components";
import { IHeader } from "../../Types";
import { Burger } from "../.";
import { useAppContext } from "../../Contexts/StateProvider";
import VisibilitySensor from "react-visibility-sensor";
import PubSub from "pubsub-js";

const HeaderOuter = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
`;

const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.navigation.bg};
  padding: 20px 0;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  z-index: 2000;

  ${(props) => props.theme.breakpoints("sm")`

    &.scrollNav {
      padding: 0;

      img.logo {
        width: 80px;
      }
    }
	`}

  img.logo {
    transition: all 0.3s ease-in-out;
  }
`;

const HeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 20px;
  width: 100%;

  ${(props) => props.theme.breakpoints("sm")`
		padding: 20px 0;
	`}
`;

const LogoNavigation = styled.div`
  align-self: center;

  ${(props) => props.theme.breakpoints("sm")`
		flex-grow: 1;
	`}

  svg {
    display: block;
  }
`;

const MainNavigation = styled.div`
  display: none;

  ${(props) => props.theme.breakpoints("sm")`
		display: block;
		align-self: center;
		flex-grow: 2;
	`}
`;

const MobileNavigation = styled.div`
  display: flex;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints("sm")`
		display: none;
		flex-grow: 0;
	`}

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
`;

const PrimaryNav = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  min-width: 100%;
  padding: 0;
`;

const PrimaryNavLink = styled.a`
  line-height: 34px;
  display: block;
  font-size: 17px;
  color: #2d2d2d;
  letter-spacing: -0.3px;
  text-decoration: none;
  margin-right: 1rem;
  white-space: nowrap;

  background-image: linear-gradient(
    ${(props) => props.theme.navigation.lineColor},
    ${(props) => props.theme.navigation.lineColor}
  );
  background-position: 50% 100%;
  background-repeat: no-repeat;

  ${(props) => props.theme.breakpoints("sm")`
		margin: 0 25px;
	`}

  ${(props) => {
    if (props.isSelected) {
      return `
				background-size: 100% 4px;
			`;
    } else {
      return `
				background-size: 0% 4px;
			`;
    }
  }}
	transition: background-size 300ms ease-in-out;

  &:hover,
  &:focus {
    background-size: 100% 4px;
  }
`;

let lastScroll = 0;
/* let lastDirection = "none"; */
let headerOuterHeight = 160;

const Header: React.FC<IHeader> = ({
  HomeUrl,
  Header,
  children,
  sticky,
  Fullscreen,
}) => {
  const { isMenuOpen } = useAppContext();
  const { toggleMenu } = useAppContext();
  const { setHeaderVisible } = useAppContext();
  const headerOuterRef = useRef<any>();
  const headerRef = useRef<any>();

  const { NavigationLinks } = Header;

  const onChange = (visiblity) => {
    const isClient = typeof window === "object";
    const isModalOpen = document.querySelector("body.ReactModal__Body--open");

    if (!isClient || !document || Boolean(isModalOpen)) {
      return;
    }

    setHeaderVisible(visiblity);

    const docElm = document.documentElement;
  };

  const debounce = (fn) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
      // If the frame variable has been defined, clear it now, and queue for next frame
      if (frame) {
        cancelAnimationFrame(frame);
      }

      // Queue our function call for the next frame
      frame = requestAnimationFrame(() => {
        // Call our function and pass any params we received
        fn(...params);
      });
    };
  };

  const scrollObserver = () => {
    const isClient = typeof window === "object";

    if (!isClient || !document) {
      return;
    }

    if (!headerRef || !headerRef.current) {
      return;
    }

    const currentScroll = window.pageYOffset;
    const scrollY = window.scrollY || document.body.scrollTop || 0;
    const headerIsOutsideViewport = scrollY > headerOuterHeight - 1;

    if (currentScroll > 100) {
      //headerRef.current.style = `transform: translateY(0px);transition: none 0s ease 0s;top: 0px;position: relative;`;
      headerRef.current.classList.add("scrollNav");

      PubSub.publish("header:height");
      return;
    } else {
      headerRef.current.classList.remove("scrollNav");
      PubSub.publish("header:height");
    }

    /* if (currentScroll > lastScroll && lastDirection !== "down") {
      // down
      if (headerIsOutsideViewport) {
        headerRef.current.style = `transform: translateY(0);transition: transform 0.5s ease 0s;top:-160px;position: fixed;`;
        PubSub.publish("header:height");
      } else {
        headerRef.current.style = `transform: translateY(0px);transition: none 0s ease 0s;top: 0px;position: relative;`;
        PubSub.publish("header:height");
      }
      lastDirection = "down";
    } else if (currentScroll < lastScroll && lastDirection === "down") {
      // up
      headerRef.current.style = `transform: translateY(160px);transition: transform 0.5s ease 0s;top:-160px;position: fixed;`;
      PubSub.publish("header:height");
      lastDirection = "up";
    } */

    lastScroll = currentScroll;
  };

  useEffect(() => {
    const isClient = typeof window === "object";

    if (!isClient || !document || !sticky) {
      return;
    }

    PubSub.subscribe("header:height", () => {
      const docElm = document.documentElement;

      headerOuterHeight =
        headerOuterRef &&
        headerOuterRef.current &&
        headerOuterRef.current.clientHeight;

      docElm.style.setProperty("--header-height-px", `${headerOuterHeight}px`);
    });

    PubSub.publish("header:height");

    // just return if ie11
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > -1
    ) {
      return;
    }

    // Listen for new scroll events, here we debounce our `scrollObserver` function
    document &&
      document.addEventListener("scroll", debounce(scrollObserver), {
        passive: true,
      });

    // Update scroll position for first time
    scrollObserver();

    return () => {
      PubSub.unsubscribe("header:height");
      document &&
        document.removeEventListener("scroll", debounce(scrollObserver));
    };
  }, []);

  return (
    <VisibilitySensor partialVisibility onChange={onChange}>
      <HeaderOuter ref={headerOuterRef}>
        <HeaderWrapper ref={headerRef} visible={setHeaderVisible}>
          <HeaderGrid>
            <LogoNavigation>
              <a href={Boolean(HomeUrl) ? HomeUrl : "/"}>
                <Logo />
              </a>
            </LogoNavigation>
            {!isMenuOpen && !Fullscreen && (
              <MainNavigation>
                <PrimaryNav role="navigation" aria-label="Primary">
                  {Boolean(NavigationLinks) &&
                    NavigationLinks.map(({ Url, Label, Selected }, key) => (
                      <li key={key}>
                        <PrimaryNavLink href={Url} isSelected={Selected}>
                          {Label}
                        </PrimaryNavLink>
                      </li>
                    ))}
                </PrimaryNav>
              </MainNavigation>
            )}
            <MobileNavigation>
              <button onClick={toggleMenu}>
                <Burger />
              </button>
            </MobileNavigation>
          </HeaderGrid>
        </HeaderWrapper>
        {Boolean(children) && children}
      </HeaderOuter>
    </VisibilitySensor>
  );
};

export default Header;
