import React, { useEffect } from 'react';

const ViewportObserver: React.FC = () => {
	const isClient = typeof window === 'object';

	/* const getWidth = () => {
		if (!isClient) {
			return;
		}

		const width = window.innerWidth;

		const sqrt = Math.sqrt(width);
		const roundedSqrt = Math.round(100 * sqrt) / 100;
		const controlledWidth = Math.min(60, Math.max(15, roundedSqrt));

		return controlledWidth;
	} */

	const getHeight = () => {
		if (!isClient) {
			return;
		}

		return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	}

	const setStyleVariables = () => {
		/* const width = getWidth(); */
		const height = getHeight();
		
		/* document.documentElement.style.setProperty('--viewport-width', '' + width);
		document.documentElement.style.setProperty('--viewport-width-px', `${width}px`); */
		document.documentElement.style.setProperty('--viewport-height', '' + height);
	}
	  
	useEffect(() => {
		if (!isClient) {
			return;
		}

		setStyleVariables();

		const resizeListener = () => {
			setStyleVariables();
		};
		
		window.addEventListener('resize', resizeListener);

		return () => {
			window.removeEventListener('resize', resizeListener);
		}
	}, []);

	return (
		<React.Fragment />
	);
};

export default ViewportObserver;