import { useState, useEffect } from 'react';
import useResize from './useResize';
import { breakpoints } from '../Themes/base';

const _findBreakpoint = (currentWidth) => {
	let prev = 0;
	let currentBreakpoint = '';

	Object.keys(breakpoints).reduce((_, breakpointName: string) => {
		if (breakpoints[breakpointName] < currentWidth) {
			currentBreakpoint = breakpointName;
			return breakpointName;
		}
		prev = breakpoints[breakpointName];
	}, {});

	return currentBreakpoint;
}

export default function useBreakpoint() {
	let width = useResize();
	let [breakpoint, setBreakpoint] = useState('');

	useEffect(() => {
		setBreakpoint(_findBreakpoint(width));
	}, [width]);

	if (breakpoint) return breakpoint;
}