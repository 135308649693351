import React from "react";
import styled, { css } from "styled-components";
import { ISearchItem } from "../../../Types";
import { Heading, ResponsiveImage } from "../..";
import CMSIcon from "../../CMS/CMSIcon";

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 auto;
  padding: 0;
`;

const ListItem = styled.li`
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 10px;
  text-align: center;

  @media screen and (min-width: 520px) {
    flex-basis: 50%;
  }

  ${(props) => props.theme.breakpoints("xsm")`
    flex-basis: 33.333%;
  `}

  ${(props) => props.theme.breakpoints("sm")`
    flex-basis: 25%;
  `}
`;

const GridIcon = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.neoMint};
  border-radius: 100%;
  color: white;
  display: flex;
  height: 33px;
  justify-content: center;
  margin: 0 auto;
  width: 33px;

  ${(props) => props.theme.breakpoints("xsm")`
      opacity: 0;
      transform: translateY(9px);
      transition: all 0.3s ease-in;
    `}

  svg {
    display: inline-block;
    height: 13px;
    width: 13px;
  }
`;

const TileGridItem = styled.a`
  background-color: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.43);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 9px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.43);

    ${GridIcon} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const GradeInfo = styled.div`
  border-bottom: 1px solid;
  color: ${(props) => props.theme.colors.neoMint};
  font-size: 15px;
  font-weight: 600;
  margin: 0 20px;
  padding: 0 0 10px;
`;

const ThemeInfo = styled.ul`
  display: flex;
  list-style: none;
  margin: 10px auto;
  min-width: calc(100% - 14px);
  padding: 0;

  svg {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 30px;
  }
`;

const ThemeInfoItem = styled.li`
  flex-basis: 16.6666%;
  font-size: 8px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  opacity: 0.23;

  ${(props) => {
    if (props.isActive) {
      return `
        opacity: 1;
        `;
    }
  }}

  ${(props) => {
    switch (props.tileTheme) {
      case "energy":
        return css`
          svg {
            color: ${props.theme.colors.lilla2};
          }
        `;
      case "food":
        return css`
          svg {
            color: ${props.theme.colors.solGul};
          }
        `;
      case "climate":
        return css`
          svg {
            color: ${props.theme.colors.lyseBlaa};
          }
        `;
      case "ressources":
        return css`
          svg {
            color: ${props.theme.colors.koral2};
          }
        `;
      case "nature":
        return css`
          svg {
            color: ${props.theme.colors.koral};
          }
        `;
      case "health":
        return css`
          svg {
            color: ${props.theme.colors.lilla};
          }
        `;
    }
  }}
`;

const GridImage = styled.div`
  display: flex;
  flex-direction: column;
  height: 33px;
  justify-content: center;
  min-height: 100px;

  img {
    display: block;
    margin: 10px auto;
    max-height: 61px;
  }
`;

const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 10px 20px;

  h2 {
    align-self: center;
    display: block;
    flex-basis: 100%;
    margin-bottom: 0;
    text-align: center;
  }
`;

export interface ITileGrid {
  Data: Array<ISearchItem>;
  Themes: any;
}

const TileGrid: React.FC<ITileGrid> = ({
  Data,
  Themes: ThemeData,
}) => {
  return (
    <List>
          {Data.map(({ Url, DendogramName, Host, Logo, SchoolYears, Themes,  }, key) => {
        return (
          <ListItem key={`ListItem_${key}`}>
            <TileGridItem href={Url} target="_blank">
                    <GridImage>
                          <ResponsiveImage {...Logo} />
                {/*{HostData.map(({ Logo }, index) => (*/}
      
                {/*))}*/}
              </GridImage>
              <GradeInfo>{SchoolYears}</GradeInfo>
              <ThemeInfo>
                {ThemeData.map(({ ThemeId, ThemeName }, index) => (
                  <ThemeInfoItem
                    key={`theme_${index}`}
                    isActive={Themes.includes(ThemeId)}
                    tileTheme={ThemeId}
                  >
                    <CMSIcon icon={"tilelistthemeicon"} />
                    {ThemeName}
                  </ThemeInfoItem>
                ))}
              </ThemeInfo>
              <GridHeader>
                        <Heading size={300}>{DendogramName}</Heading>
              </GridHeader>
              <GridIcon>
                <CMSIcon icon={"targetblank"} />
              </GridIcon>
            </TileGridItem>
          </ListItem>
        );
      })}
    </List>
  );
};

export default TileGrid;
