import React, { useEffect } from 'react';

const FontLoader: React.FC = () => {

	const init = () => {
		const isClient = typeof window === 'object';

		if (!isClient || !document) {
			return;
		}

		if (sessionStorage.getItem('fonts')) {
			// If the font had been loaded before
			// Just active the font immediately
			document.documentElement.classList.add('wf-active')
		}

		// We use dynamic import to prevent "window is not defined" error
		import('webfontloader').then(WebFontLoader => {
			WebFontLoader.load({
				custom: {
					families: [
						'Avenir'
					]
				},
				active: () => {
					sessionStorage.setItem('fonts', 'true');
				},
			})
		})
	}

	useEffect(() => {
		init();
	}, [])
	return (
		<React.Fragment />
	);
};

export default FontLoader;