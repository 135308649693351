import React from "react";
import styled from "styled-components";
import { Heading, RichText, ComponentWrapper } from "../..";

const TextContainer = styled.div`
  padding-bottom: 30px;
`;

const Content = styled.div`
  @media (min-width: 768px) {
    max-width: 70%;
  }
`;
interface ITextAreaNd {
  SubHeader: any;
  RichText?: any;
}

const SubheaderSection: React.FC<ITextAreaNd> = ({
  SubHeader: SubHeadingContent,
  RichText: HtmlContent,
}) => {
  return (
    <ComponentWrapper>
      <TextContainer className={"animate"}>
        <Heading maxWidth={70}>{SubHeadingContent}</Heading>
        <Content>
          <RichText htmlContent={HtmlContent} />
        </Content>
      </TextContainer>
    </ComponentWrapper>
  );
};

export default SubheaderSection;
