import React from "react";
import styled from "styled-components";
import { SubjectIcon, ComponentWrapper } from "../..";

const ListContainer = styled.div`
  overflow: auto;
`;

const SubjectList = styled.ul`
  border-bottom: 1px solid RGBA(121, 121, 121, 0.19);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  min-width: 980px;
  padding: 0;

  .animate-content & {
    .bg_icon svg {
      transform: translateY(+30px);
      transition: transform 1s ease;
    }
    .fg_icon svg {
      transform: translateY(+50px);
      transition: transform 1.2s ease;
    }
  }
  .is-visible & {
    .bg_icon svg {
      transform: translateY(0);
    }
    .fg_icon svg {
      transform: translateY(0);
    }
  }

  ${(props) => props.theme.breakpoints("xsm")`
        margin: 0 auto;
    `}
`;

const SubjectListItem = styled.li`
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 15px;
  margin: 0;
  padding: 0 0 20px 0;
  position: relative;
  text-align: center;

  ${(props) => {
    if (props.isSelected) {
      return `
        &:after {
          background-color: #8ecdb2;
          bottom: 0;
          content: "";
          display: block;
          height: 6px;
          margin: 0 auto;
          position: absolute;
          right: 10px;
          width: calc(100% - 30px);
        }
      `;
    }
  }}

  & > a {
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: flex-start;

    & > div {
      transform: scale(1);
      transition: transform 100ms ease;
    }

    &:hover > div {
      transform: scale(1.1);
    }

    & > div {
      flex-basis: 100px;
      flex-shrink: 0;
    }
    & > span {
      display: block;
      transform: translateX(-5px);
    }
  }
`;

export interface ISubjectTabs {
  List: any[];
}

const SubjectTabs: React.FC<ISubjectTabs> = ({ List = [] }) => {
  return (
    <ComponentWrapper>
      <ListContainer className="animate styled-scrollbars">
        <SubjectList>
          {List.map((ListItem, index) => {
            return (
              <SubjectListItem
                key={`ressourceItem_${index}`}
                isSelected={ListItem.Selected}
              >
                <a href={ListItem.Link.Url}>
                  <SubjectIcon Icon={ListItem.Icon} />
                  <span>{ListItem.Link.Label}</span>
                </a>
              </SubjectListItem>
            );
          })}
        </SubjectList>
      </ListContainer>
    </ComponentWrapper>
  );
};

export default SubjectTabs;
