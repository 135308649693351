import * as d3 from './lib/d3';

// export function drawPieChart(ref, activity_data, active_layer){
export function drawPieChart(ref, activity_data, radius, getHostData){
  // let height = 1000;
  let svg = d3.select(ref);
  let clusterMap = d3.cluster().size([360, radius]);

  updateDendogramFigure(updateDendogramData());

  function updateDendogramData() {
  let node_data = [{ name: "epic", parent: "" }];
  let parents = [];

  activity_data.forEach((d) => {
    if (!parents.includes(d.DendogramHostName)) {
      parents.push(d.DendogramHostName);

      node_data.push({ name: d.DendogramHostName, parent: "epic", link: d.DendogramHostUrl});
    }

    node_data.push({ name: d.DendogramName, parent: d.DendogramHostName, link: d.Url });
  });


  let stratify = d3
  .stratify()
  .id(function (d) {
    return d.name;
  })
  .parentId(function (d) {
    return d.parent;
  });

  // Create hierarchy used in dendogram
  let root = stratify(node_data);
  // console.log(root)
  return root;
  }

  function updateDendogramFigure(data) {
  // Start clean when getting new data
  let dumLink = d3.linkRadial().angle(0).radius(0);
  let linksGenerator = d3
    .linkRadial()
    .angle(function (d) {
      return (d.x / 180) * Math.PI;
    })
    .radius(function (d) {
      return d.y - depth_scale(d.depth);
    });
    function depth_scale(depth) {
      return depth == 2 ? 100 : 0;
    }
  svg.selectAll("g").remove();
  svg.selectAll("path").remove();
  svg.selectAll("text").remove();

  let nodes = clusterMap(data);
  let u_links = svg.selectAll("path").data(nodes.links());
  let links_enter = u_links.enter().append("path");

  links_enter
    .attr("d", dumLink)
    .style("fill", "none")
    .attr("stroke", "#ccc")
    .style("opacity", 0.0)
    .merge(u_links);

  links_enter
    .transition()
    .ease(d3.easeLinear)
    .duration(500)
    .style("opacity", 1.0)
    .attr("d", linksGenerator);

  let u_node = svg.selectAll("g").data(nodes.descendants());

  let nodeEnter = u_node
    .enter()
    .append("g")
    .attr("class", function (d) {
      let assign_class =
        "node" + (d.children ? " node--internal" : " node--leaf");
      if (d.depth >= 1) {
        assign_class =
          assign_class +
          (d.data.link === "" ? " node--unlinked" : " node--linked");
      }
      return assign_class;
    });

  // Attach a piece of text to each "g" (the container for each node)
  svg
    .selectAll("g")
    .append("text")
    //nodeEnter.append("text")
    .attr("dy", ".31em")
    .attr("x", function (d) {
      return d.x < 180 === !d.children ? 10 : -10;
    })
    .style("text-anchor", function (d) {
      return d.x < 180 === !d.children ? "start" : "end";
    })
    .style("font-size", "13px")
    .attr("transform", function (d) {
      return "rotate(" + (d.x < 180 ? d.x - 90 : d.x + 90) + ")";
    })
    .text(function (d) {
      return d.depth === 0 ? "" : d.data.name;
    })
    .on("click", function (event, d) {
      if (d.data.link) {
        window.open(d.data.link);
      }
    })
    .on("mouseover", mouseOverHandler)
    .on("mouseout", mouseOutHandler)

  // Enter the nodes in the middle.
  nodeEnter
    .attr("x", 0)
    .attr("y", 0)
    .style("opacity", 0.0)
    .append("circle")
    .attr("r", 6)
    .attr("class", "node--linked with-link")
    .on("click", function (event, d) {
      if (d.data.link) {
        window.open(d.data.link);
      }
    })
    .attr("fill", "#999")
    .on("mouseover", mouseOverHandler)
    .on("mouseout", mouseOutHandler)
  // .merge(u_node);

  function project(x, y, depth) {
    var angle = ((x - 90) / 180) * Math.PI,
      radius = y - depth_scale(depth);

    // var angle = (x - 90) / 180 * Math.PI, radius = y;
    return [radius * Math.cos(angle), radius * Math.sin(angle)];
  }


  // Move them to their correct position
  nodeEnter
    .transition()
    .ease(d3.easeLinear)
    .duration(500)
    .style("opacity", 1.0)
    .attr("transform", function (d) {
      return "translate(" + project(d.x, d.y, d.depth) + ")";
    });

  // Add header over dendogram showing the active region
  // svg
  //   .append("text")
  //   .attr("x", 0)
  //   .attr("y", -1 * (height / 2) + 80)
  //   .attr("text-anchor", "middle")
  //   .attr("dy", "0.31em")
  //   .attr("class", "h2")
  //   .style("font-family", "Nunito")
  //   // .text(CommuneName); // CommuneName is the name of the selected region
  }

  function mouseOverHandler(event, d) {
  // If the node has a link, make slighter larger + change color
  // if the node does not have a link, make different color
  let g = d3.select(event.target.parentElement);
  g.classed("highlighted", true);
  // Only add highlihting to leaf-nodes:

  if (g.classed("node--linked")) {
    g.classed("with-link", true);
    g.style("cursor", "pointer");
  }
  // else {
  //   g.classed("without-link", true);
  // }

  }

  function mouseOutHandler(event, d) {
  let g = d3.select(event.target.parentElement);
  g.classed("highlighted", false);
  }
}