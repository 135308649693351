import React from 'react';
import styled from 'styled-components';

const Root = styled.span`
	& > a {
		text-decoration: none;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0 102%;
		background-repeat: no-repeat;
		background-size: 0% 1px;
		transition: background-size 300ms ease-in-out;
		
		&:hover, &:focus {
			background-size: 100% 1px;
		}
	}
`;

const LinkUnderline: React.FC<any> = ({ children }) => {
	return (
		<Root>
			{children}
		</Root>
	);
};
export default LinkUnderline;