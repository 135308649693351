import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M22.155 9.23v12c0 2.032-1.656 2.77-2.77 2.77H2.77C.738 24 0 22.344 0 21.23V4.615c0-2.031 1.656-2.769 2.77-2.769h11.077a.923.923 0 1 1 0 1.846H2.769c-.415.011-.923.18-.923.923v16.617c.011.415.18.923.923.923h16.617c.415-.011.923-.18.923-.923v-12a.923.923 0 1 1 1.846 0ZM23.077 0h-5.538a.923.923 0 1 0 0 1.846h3.187L9.525 12.248a.924.924 0 0 0 1.257 1.353l11.372-10.56v3.42a.923.923 0 1 0 1.846 0V.923A.923.923 0 0 0 23.077 0Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "TargetBlank",
  {
    viewBox: "0 0 24 24",
    width: "24px",
    height: "24px",
  }
);
