import React, { useState, useRef, useEffect, Fragment } from "react";
import styled from "styled-components";
import {
    Button,
    ListGrid,
    SubjectIcon,
    TileGrid,
    CircleGrid,
    Heading,
    intersectionObserver,
    ResponsiveImage,
    MaterialList,
} from "../..";
import ReactSelect from "react-select";
import CMSIcon from "../../CMS/CMSIcon";
import { useAppContext } from "../../../Contexts/StateProvider";

const selectStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        borderRadius: "8px",
        padding: 0,
        zIndex: 3000,
        cursor: "pointer",
    }),
    menuList: (provided) => ({
        ...provided,
        borderRadius: "8px",
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        whiteSpace: "nowrap",
        fontFamily: "Avenir",
        color: "#939393",
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: "#fff",
        border: "1px solid #BCB8B1",
        fontSize: "14px",
        fontWeight: "normal",
        lineHeight: "48px",
        height: "48px",
        cursor: "pointer",
        boxShadow: "0 !important",
        paddingLeft: "16px",
        "&:hover": {
            border: "1px solid #BCB8B1 !important",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "normal",
        lineHeight: "48px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "normal",
        color: "#4A4A4A",
        background: state.isSelected ? "#f4f4f4" : "#fff",
        padding: "0 8px",
        height: "48px",
        maxHeight: "48px",
        minHeight: "32px",
        borderBottom: "1px solid #BCB8B1",
        paddingLeft: "16px",

        "&:hover": {
            background: "#f4f4f4",
            "& path": {
                stroke: "#fff",
            },
        },

        "&:last-of-type": {
            borderBottom: 0,
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: "#fff",
        padding: "0",
        height: "48px",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        "& > div": {
            borderLeft: "1px solid #BCB8B1",
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: "14px",
        color: "#4A4A4A",
    }),
};

const Root = styled.section`
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 30px;
  text-align: center;

  &.animate-content {
    .animate {
      opacity: 0;
      transition: all 1s ease-in-out;
    }
    &.is-visible {
      .animate {
        opacity: 1;
      }
    }
  }
`;

const CommuneFilter = styled.div`
  margin: 0 auto;
  max-width: 330px;
`;

const ResetButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }
`;

const CommuneLogo = styled.div`
  background-color: white;
  border-radius: 100%;
  height: 98px;
  margin: 50px auto 13px;
  position: relative;
  transition: all 0.3s ease;
  width: 98px;

  svg,
  img {
    height: 100%;
    max-width: 65px;
    max-height: 65px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  svg {
    height: auto;
  }
`;

const CommuneContainer = styled.div`
  margin: 0 auto;
  max-width: 470px;
`;

const CommuneContainerButton = styled(ResetButton)`
  background-image: linear-gradient(
    ${(props) => props.theme.navigation.lineColor},
    ${(props) => props.theme.navigation.lineColor}
  );
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: ${(props) => (props.isActive ? "130px 5.6px" : "0% 5.6px")};
  margin: 0 auto;
  max-width: 470px;
  padding-bottom: 13px;
  transition: background-size 300ms ease-in-out;

  ${CommuneLogo} {
    background-color: ${(props) =>
        props.isActive ? props.theme.colors.neoMint : "white"};
  }

  &:hover {
    h2 {
      opacity: 1;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
    opacity: ${(props) => (props.isActive ? "1" : "0.17")};
    padding: 0 10px;
    transition: all 0.3s ease-in-out;

    ${(props) => props.theme.breakpoints("xsm")`
      font-size: 35px;
      line-height: 44px;
      padding: 0;
    `}
  }
`;

const ThemeFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 45px 0;
`;

const ThemeFilterButton = styled(ResetButton)`
  background-image: linear-gradient(
    ${(props) => props.theme.navigation.lineColor},
    ${(props) => props.theme.navigation.lineColor}
  );
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: ${(props) => (props.isActive ? "80% 5.6px" : "0% 5.6px")};
  flex-basis: 31%;
  padding-bottom: 12px;
  margin-bottom: 20px;

  @media screen and (min-width: 520px) {
    flex-basis: 15%;
    margin-bottom: 0;
  }

  .bg_icon {
    opacity: ${(props) => (props.isActive ? 1 : 0.2)};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .bg_icon {
      opacity: ${(props) => (props.isActive ? 1 : 0.7)};
      transform: translateX(-50%) translateY(-50%) scale(1.1) rotate(-5deg);
    }
  }
`;

const StageFilter = styled.div`
  border-top: 1px solid RGBA(121, 121, 121, 0.19);
  display: flex;
  justify-content: space-evenly;
  padding: 40px 0 0;
`;
const StageFilterButton = styled(ResetButton)`
  margin: 20px 4px;
  position: relative;
  width: 96px;

  ${(props) => props.theme.breakpoints("xsm")`
    margin: 20px;
  `}

  svg {
    margin-bottom: 6px;
  }

  &:before {
    background-color: ${(props) =>
        props.isActive ? props.theme.colors.neoMint : "RGBA(142, 205, 178, .3)"};
    border-radius: 100%;
    content: "";
    display: block;
    height: 76px;
    left: 50%;
    position: absolute;
    top: 7px;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    width: 76px;
    z-index: -1;
  }

  &:hover {
    &:before {
      background-color: ${(props) => props.theme.colors.neoMint};
      transform: translateX(-50%) scale(1.1);
    }
  }
`;
const GradeFilter = styled.div`
  padding: 20px 0 30px;
`;
const GradeFilterButton = styled(ResetButton)`
  background-color: ${(props) =>
        props.isActive ? props.theme.colors.neoMint : "RGBA(142, 205, 178, .15)"};
  border-radius: 100%;
  height: 61px;
  margin: 12px;
  width: 61px;

  &:hover {
    background-color: ${(props) => props.theme.colors.neoMint};
  }
`;

const ResultsCount = styled.a`
  @keyframes bounce {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    0% {
      background-size: 0 5.6px;
    }
    100% {
      background-size: 60% 5.6px;
    }
  }
  background-color: transparent;
  background-image: linear-gradient(
    ${(props) => props.theme.navigation.lineColor},
    ${(props) => props.theme.navigation.lineColor}
  );
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 60% 5.6px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 auto 30px;
  padding-bottom: 10px;
  text-align: center;
  animation: bounce 0.75s ease-in-out;
`;

const ClearButton = styled.div``;

const ListTabs = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
const ListTab = styled.li`
  flex-basis: 50%;
  margin: 0;
  padding: 0;
`;

const ViewSelector = styled.div`
  display: inline-flex;
  padding: 40px 30px;
`;

const GridButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  flex-direction: column;
  line-height: 24px;
  margin: 0 15px;
  padding: 0;

  & > * {
    align-self: center;
  }

  svg {
    color: ${(props) =>
        props.isActive ? props.theme.colors.neoMint : "RGB(121, 121, 121)"};
    transition: color 0.3s ease;
  }
`;

export enum ETabStyle {
    Activities = "Activities",
    Materials = "Materials",
}

export enum EViewStyle {
    Tiles = "Grid",
    List = "List",
    Circle = "Circle",
}

export interface IGrade {
    GradeId: string;
    GradeName: string;
}

export interface ISearchSection {
    Configuration: any;
    Activities: any;
    Materials: any;
    Communes: any;
    Themes: any;
    Keywords: any;
    Stages: any;
}

const SearchSection: React.FC<ISearchSection> = ({
    Configuration,
    Activities,
    Materials,
    Communes,
    Themes,
    Keywords,
    Stages,
}) => {
    const [view, setView] = useState<string>(EViewStyle.Circle);
    const [filterTheme, setFilterTheme] = useState<Array<string>>([]);
    const [filterGrade, setFilterGrade] = useState<Array<string>>([]);
    const [filterKeyword, setFilterKeyword] = useState<string>("");
    const [filteredActivities, setFilteredActivities] = useState<any>([]);
    const [filteredMaterials, setFilteredMaterials] = useState<any>([]);
    const [tab, setTab] = useState<string>(ETabStyle.Activities);
    const [grades, setGrades] = useState<Array<IGrade>>([]);
    const [copyText, setCopyText] = useState<string>();
    const [containerRef, containerVisible] = intersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
    });
    const filtersRef = useRef<any>();
    const buttonsRef = useRef<any>();
    const [className, setClassName] = useState<string>("animate-content");
    const { CurrentCommune, setCurrentCommune } = useAppContext();

    useEffect(() => {
        let newClassName = className;
        if (!className.includes("is-visible")) {
            newClassName = containerVisible ? className + " is-visible" : className;
        }
        setClassName(newClassName);
    }, [containerVisible]);

    useEffect(() => {
        if (Configuration.Filters?.Commune?.Initial) {
            setCurrentCommune(Configuration.Filters.Commune.Initial);
        }

        if (
            Configuration.Filters?.Commune?.Initial == "" &&
            !Configuration.Tabs.Order.includes("Activities")
        ) {
            setCurrentCommune("000");
        }
    }, []);

    const handleKeywordChange = (keyword) => {
        const keyWordValue = keyword.value !== "All" ? keyword.value : "";

        setFilterKeyword(keyWordValue);
    };



    const getCommuneLogo = () => {
        if (Configuration.Tabs.Activities) {
            let result = Communes.filter(
                (Commune) => Commune.CommuneId === CurrentCommune
            );
            return result[0].CommuneLogo;
        }
    };

    const getCommuneName = () => {
        if (Configuration.Tabs.Activities) {
            let result = Communes.filter(
                (Commune) => Commune.CommuneId === CurrentCommune
            );
            return result[0].CommuneName || "";
        }
    };

    const toggleTheme = (filterId: string) => {
        if (filterTheme.includes(filterId)) {
            setFilterTheme(filterTheme.filter((fId) => fId !== filterId));
        } else {
            setFilterTheme([...filterTheme, filterId]);
        }
        setFilterKeyword("");
    };

    const clearFilters = () => {
        setFilterTheme([]);
        setFilterGrade([]);
    };

    const addStageGrades = (stage: any) => {
        let tempGrades = [...filterGrade];

        stage.Grades.forEach((Grade) => {
            if (!filterGrade.includes(Grade.GradeId)) {
                tempGrades = [...tempGrades, Grade.GradeId];
            }
        });
        setFilterGrade(tempGrades);
    };

    const toggleGrade = (filterId: string) => {
        if (filterGrade.includes(filterId)) {
            setFilterGrade(filterGrade.filter((fId) => fId !== filterId));
        } else {
            setFilterGrade([...filterGrade, filterId]);
        }
    };

    const dataFilterCommune = (dataEntry) => {
        for (const commune of dataEntry.Commune) {
            if (CurrentCommune === commune) {
                return true;
            }
        }
        return false;
    };

    const dataFilterTheme = (dataEntry) => {

        for (const theme of dataEntry.Themes) {
            if (filterTheme.includes(theme)) {
                return true;
            }
        }
        return false;
    };

    const dataFilterGrade = (dataEntry) => {
        for (const grade of dataEntry.Grades) {
            if (filterGrade.includes(grade)) {
                return true;
            }
        }
        return false;
    };

    const dataFilterKeywords = (dataEntry) => {
        for (const keyword of dataEntry.Keywords) {
            if (filterKeyword === keyword) {
                return true;
            }
        }
        return false;
    };

    const shareLink = () => {
        setCopyText(Configuration.Filters.Results.Shared);
        navigator.clipboard.writeText(window.location.href);
        setTimeout(() => {
            setCopyText(Configuration.Filters.Results.Share);
        }, 2500);
    };

    useEffect(() => {
        if (!Boolean(CurrentCommune)) {
            return;
        }

        const getData = async () => {
            try {
                const res = await fetch(`${Configuration.FetchActivitiesMaterialsUrl}?commune=${CurrentCommune}`
                , {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!res.ok) {
                    alert("Der var et problem med at loade aktiviteter og materialer. Genindl�s siden eller pr�v igen senere")
    /*                throw new Error(res.statusText);*/
                }

                const data = await res.json();

                let tempActivities;

                tempActivities = data.Activities;


                let tempMaterials = data.Materials;

                // Filter by Themes

                tempActivities = tempActivities.filter(dataFilterCommune);


                if (filterTheme.length > 0) {
                    tempActivities = tempActivities.filter(dataFilterTheme);

                    tempMaterials = tempMaterials.filter(dataFilterTheme);
                }
                // Filter by Grades
                if (filterGrade.length > 0) {

                    tempActivities = tempActivities.filter(dataFilterGrade);

                    tempMaterials = tempMaterials.filter(dataFilterGrade);
                }
                // Filter by Keywords (Materials only)
                if (Boolean(filterKeyword)) {
                    tempMaterials = tempMaterials.filter(dataFilterKeywords);
                }

                // Set Materials and Activities states
                setFilteredActivities(tempActivities);
                setFilteredMaterials(tempMaterials);

                const dependencies = [
                    filterTheme,
                    filterGrade,
                    filterKeyword,
                    CurrentCommune,
                    tab,
                    view,
                ];
                const depStrings = [
                    "filterTheme",
                    "filterGrade",
                    "filterKeyword",
                    "CurrentCommune",
                    "tab",
                    "view",
                ];
                // LOL javasctipt
                const queryHashes: string[] = [];
                dependencies.forEach((dep, index) => {
                    if (dep.length !== 0 && dep) {
                        // if(dep.length !== 0 && dep && dep !== EViewStyle.Circle && dep !== ETabStyle.Activities){
                        const hashValue = `${depStrings[index]}=${dep}`;
                        queryHashes.push(hashValue);
                    }
                });
                window.location.hash = queryHashes.join("|");

            } catch (err) {
                console.error(err);
            }
        };

        getData()


    }, [filterTheme, filterGrade, filterKeyword, CurrentCommune, tab, view]);

    useEffect(() => {
        // Get Grades frtom Stages
        let tempGrades = [...grades];
        Stages.forEach((Stage) => {
            tempGrades = [...tempGrades, Stage.Grades];
        });
        // Set Grades state
        setGrades(tempGrades.flat());

        // Set active Activity List View and Tab
        if (Configuration.Tabs.Activities) {
            setView(Configuration.Tabs.Activities.Displays.Order[0]);
        }
        setTab(Configuration.Tabs.Order[0]);

        //Set copy button text
        setCopyText(Configuration.Filters.Results.Share);
    }, []);

    useEffect(() => {
        let queryParams = decodeURI(window.location.hash);
        if (queryParams && queryParams.indexOf("|") > -1) {
            queryParams
                .replace("#", "")
                .split("|")
                .forEach((el) => {
                    const statePairs = el.split("=");
                    const methodString = statePairs[0];
                    const arg = statePairs[1].split(",");
                    switch (methodString) {
                        case "filterTheme":
                            setFilterTheme(arg);
                            break;
                        case "filterKeyword":
                            setFilterKeyword(arg[0]);
                            break;
                        case "filterGrade":
                            setFilterGrade(arg);
                            break;
                        case "CurrentCommune":
                            setCurrentCommune(arg[0]);
                            break;
                        case "tab":
                            setTab(arg[0]);
                            break;
                        case "view":
                            setView(arg[0]);
                            break;
                    }
                });
        }
    }, []);

    const tplListIcon = (listType) => {
        switch (listType) {
            case EViewStyle.Tiles:
                return <CMSIcon icon={"listgrid"} />;
            case EViewStyle.List:
                return <CMSIcon icon={"listrow"} />;
            case EViewStyle.Circle:
                return <CMSIcon icon={"listcircle"} />;
            case ETabStyle.Activities:
                return <CMSIcon icon={"bulb"} />;
            case ETabStyle.Materials:
                return <CMSIcon icon={"book"} />;
        }
    };

    return (
        <Root ref={containerRef} id="searchSection">
            {Boolean(CurrentCommune) && (
                <Fragment>
                    <ThemeFilter>
                        {Themes.map(({ ThemeId, ThemeName }, index) => (
                            <ThemeFilterButton
                                key={`themefilter_${index}`}
                                isActive={filterTheme.includes(ThemeId)}
                                tileTheme={ThemeId}
                                onClick={() => {
                                    toggleTheme(ThemeId);
                                }}
                            >
                                <SubjectIcon Icon={ThemeId} />
                                {ThemeName}
                            </ThemeFilterButton>
                        ))}
                    </ThemeFilter>

                    <StageFilter>
                        {Stages.map((Stage, index) => (
                            <StageFilterButton
                                key={`stagefilter${index}`}
                                tileTheme={Stage.StageId}
                                onClick={() => addStageGrades(Stage)}
                            >
                                <CMSIcon icon={`stage${index + 1}`} />
                                {Stage.StageName}
                            </StageFilterButton>
                        ))}
                    </StageFilter>

                    <GradeFilter>
                        {grades.map(({ GradeId, GradeName }, index) => (
                            <GradeFilterButton
                                key={`gradefilter_${index}`}
                                isActive={filterGrade.includes(GradeId)}
                                tileTheme={GradeId}
                                onClick={() => toggleGrade(GradeId)}
                            >
                                {`${GradeName}`}
                            </GradeFilterButton>
                        ))}
                    </GradeFilter>

                    {Boolean(Configuration.Filters.Results.Label) &&
                        filteredActivities != undefined && (
                            <ResultsCount
                                onClick={() => buttonsRef.current.scrollIntoView()}
                                key={`result_${filteredActivities.length}_${filteredMaterials.length}`}
                            >
                                {Configuration.Filters.Results.Label.replace(
                                    "{ActivityCount}",
                                    filteredActivities.length
                                )
                                    .replace("{MaterialCount}", filteredMaterials.length)
                                    .replace(
                                        "{ResultsCount}",
                                        filteredActivities.length + filteredMaterials.length
                                    )
                                    .replace("{CommuneName}", getCommuneName())}
                            </ResultsCount>
                        )}

                    <ClearButton ref={buttonsRef}>
                        <Button
                            style={{ marginBottom: 10 }}
                            variant="Gray"
                            onClick={() => clearFilters()}
                        >
                            {Configuration.Filters.Reset.Label}
                        </Button>
                        {Configuration.Tabs.Order.includes("Activities") ? (
                            <Button
                                variant="Gray"
                                className={`nd ${copyText !== Configuration.Filters.Results.Share
                                        ? "active"
                                        : ""
                                    }`}
                                onClick={() => shareLink()}
                            >
                                <p>{copyText}</p>
                                <div className="svgwrap">
                                    <CMSIcon icon="shareLink" />
                                </div>
                            </Button>
                        ) : (
                            ""
                        )}
                    </ClearButton>

                    <ListTabs>
                        {Configuration.Tabs.Order.map((Tab, index) => (
                            <ListTab key={`Tab_${index}`}>
                                <CommuneContainerButton
                                    isActive={tab === Tab}
                                    onClick={() => setTab(Tab)}
                                >
                                    <CommuneLogo>{tplListIcon(Tab)}</CommuneLogo>
                                    <Heading>
                                        {Tab === ETabStyle.Activities
                                            ? filteredActivities.length
                                            : filteredMaterials.length}
                                        <br />
                                        {Configuration.Tabs[Tab].Heading.replace(
                                            "{CommuneName}",
                                            getCommuneName()
                                        )}
                                    </Heading>
                                </CommuneContainerButton>
                            </ListTab>
                        ))}
                    </ListTabs>

                    {Boolean(tab === ETabStyle.Activities) && (
                        <Fragment>
                            <ViewSelector>
                                {Configuration.Tabs.Activities.Displays.Order.map(
                                    (ListType, index) => (
                                        <GridButton
                                            key={`ActivityList_${index}`}
                                            isActive={view === ListType}
                                            onClick={() => setView(ListType)}
                                        >
                                            {tplListIcon(ListType)}
                                            <span>
                                                {Configuration.Tabs.Activities.Displays[ListType].Label}
                                            </span>
                                        </GridButton>
                                    )
                                )}
                            </ViewSelector>

                            {Boolean(view === EViewStyle.Tiles) && (
                                <TileGrid
                                    Data={filteredActivities}
                                    Themes={Themes}
                                />
                            )}

                            {Boolean(view === EViewStyle.Circle) && (
                                <CircleGrid
                                    Data={filteredActivities}
                                />
                            )}
                        </Fragment>
                    )}

                    {Boolean(tab === ETabStyle.Materials) && (
                        <MaterialList
                            Data={filteredMaterials}
                            Keywords={Keywords}
                            Themes={Themes}
                            Configuration={Configuration.Tabs.Materials.Displays.List}
                            SelectStyles={selectStyles}
                            CurrentKeyword={filterKeyword}
                            HandleKeywordChange={handleKeywordChange}
                        />
                    )}
                </Fragment>
            )}
        </Root>
    );
};

export default SearchSection;
