import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMServer from "react-dom/server";
import { concitoTheme } from "./Themes";
import { ThemeProvider, ServerStyleSheet } from "styled-components";
import templatePicker from "./PageTypes/TemplatePicker";
import { IPage } from "./Types";
import {
  FullscreenMenu,
  SEO,
  FontLoader,
  GlobalStyles,
  ScrollObserver,
} from "./Components";
import { StateProvider } from "./Contexts/StateProvider";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const PageWrapper = styled.div`
  min-height: calc(100vh - 330px);
  overflow-x: hidden;
`;

const App: React.FC<IPage> = (props) => {
  const { Alias, Seo, Country, Language, Theme } = props;
  const Page = templatePicker(Alias);

  let theme = concitoTheme;

  return (
    <StateProvider PageArea={Theme} PageType={Alias}>
      <FontLoader />
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <ScrollObserver />
          <GlobalStyles />

          {Boolean(Seo) && (
            <SEO
              PageCategory={Alias}
              PageArea={Theme}
              Language={Language}
              Country={Country}
              {...Seo}
            />
          )}

          <PageWrapper>
            <Page {...props} />
          </PageWrapper>

          <FullscreenMenu {...props} />
        </React.Fragment>
      </ThemeProvider>
    </StateProvider>
  );
};

globalThis["Styled"] = { ServerStyleSheet };
globalThis["React"] = React;
globalThis["ReactDOM"] = ReactDOM;
globalThis["ReactDOMServer"] = ReactDOMServer;
globalThis["App"] = App;
globalThis["Helmet"] = Helmet;

export default App;
