import React, { useEffect } from 'react';

const ScrollObserver: React.FC<any> = () => {
	// The debounce function receives our function as a parameter
	const debounce = (fn) => {

		// This holds the requestAnimationFrame reference, so we can cancel it if we wish
		let frame;

		// The debounce function returns a new function that can receive a variable number of arguments
		return (...params) => {

			// If the frame variable has been defined, clear it now, and queue for next frame
			if (frame) {
				cancelAnimationFrame(frame);
			}

			// Queue our function call for the next frame
			frame = requestAnimationFrame(() => {

				// Call our function and pass any params we received
				fn(...params);
			});

		}
	};

	let lastScroll = 0;

	// Reads out the scroll position and stores it in the data attribute
	// so we can use it in our stylesheets
	const storeScroll = () => {
		const isClient = typeof window === 'object';

		if (!isClient || !document) {
			return;
		}

		const docElm = document.documentElement;

		const currentScroll = window.pageYOffset;
		const scrollY = window.scrollY || document.body.scrollTop || 0;
		docElm.style.setProperty('--scroll-y', '' + scrollY);
		docElm.style.setProperty('--scroll-y-px', `${scrollY}px`);

		const body = document.body;
		const scrollUp = 'scroll-up';
		const scrollDown = 'scroll-down';

		if (currentScroll == 0) {
			body.classList.remove(scrollUp);
			return;
		}

		const documentHeight = 0 + docElm.scrollHeight;

		if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
			// down
			body.classList.remove(scrollUp);
			body.classList.add(scrollDown);
		} else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
			// up
			body.classList.remove(scrollDown);
			body.classList.add(scrollUp);
		}
		lastScroll = currentScroll;

		const currentViewportHeight = +getComputedStyle(docElm).getPropertyValue('--viewport-height');

		const scrollYPercentage = (scrollY / (documentHeight - currentViewportHeight)) * 100;

		docElm.style.setProperty('--scroll-y-percentage', '' + scrollYPercentage);
	}

	useEffect(() => {
		// Listen for new scroll events, here we debounce our `storeScroll` function
		document && document.addEventListener('scroll', debounce(storeScroll), { passive: true });

		// Update scroll position for first time
		storeScroll();
	}, [])
	return (
		<React.Fragment />
	);
};

export default ScrollObserver;