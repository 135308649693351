import { createGlobalStyle } from "styled-components";
import { headingStyles } from "../Typography/Heading";
import {
  componentsHeadingsProfile,
  globalHeadingMarginBottomsProfile,
} from "../Typography/HeadingProfiles";

const headingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"];
const headingsCss: any = [];

headingLevels.map((level) => {
  headingsCss.push(`
		&&& .concito-web ${level} {
			${headingStyles({
        as: level,
        headingsize: componentsHeadingsProfile.get(level),
        margin: globalHeadingMarginBottomsProfile.get(level),
      })}
		}
	`);
});

export default createGlobalStyle`
	.tooltip__box {
		background: #fff;
		padding: 16px 20px;
		border: 1px solid #707070;
	}

	.tooltip__arrow {
		position: absolute;
		top: 24px;
		background: #fff;
		width: 16px;
		height: 16px;
		transform: translate(-50%, 0) rotate(45deg);
	}
	[data-popper-placement="right-start"] {
		.tooltip__arrow {
			border-left: 1px solid #707070;
			border-bottom: 1px solid #707070;
		}
	}
	[data-popper-placement="left-start"] {
		.tooltip__arrow {
			right: -16px;
			border-right: 1px solid #707070;
			border-top: 1px solid #707070;
		}
	}

	.styled-scrollbars {
		scrollbar-color: RGBA(122, 181, 156, 0.25) transparent;
	}
	.styled-scrollbars::-webkit-scrollbar {
		width: 10px; /* Mostly for vertical scrollbars */
		height: 10px; /* Mostly for horizontal scrollbars */
	}
	.styled-scrollbars::-webkit-scrollbar-thumb { /* Foreground */
		background: RGBA(122, 181, 156, 0.25);
	}
	.styled-scrollbars::-webkit-scrollbar-track { /* Background */
		background: transparent;
	}

	.ReactModalPortal {
		position: relative;
		z-index: 2000;
	}

	.ReactModal__Body--open,
	.ReactModal__Html--open {
		overflow: hidden;
	}

	.ReactModal__Overlay {
		opacity: 0;
		transition: opacity 200ms ease;
	}

	.ReactModal__Overlay--after-open{
		opacity: 1;
	}

	.ReactModal__Overlay--before-close{
		opacity: 0;
	}

	.concito-web-modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		outline: none;
		box-shadow: 0 10px 15px 0 rgba(0,0,0,0.5);
		width: 100vw;
		height: 100vh;

		@media screen and (min-width: 576px) {
			height: 525px;
			width: 935px;
		}
	}

	.concito-web-modal--fit-content {
		@media screen and (min-width: 576px) {
			height: auto;
			width: 100%;
			max-height: 80vh;
			max-width: 935px;
		}
	}

	.concito-web-modal--margin {
		@media screen and (max-width: 935px) {
			margin:5%;
			height: auto;

			width: calc(100vw - 10%);
			transform:translate(-55%,-53%);

		}
	}

	.concito-web-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(25, 50, 50, 0.75);
		z-index: 99998;
	}

	&&& {
		${headingsCss.map((headingCss) => {
      return headingCss;
    })}
	}

	.concito-web,
	.dtge-editor-preview-container {
		color: ${(props) => props.theme.typography.textColor};
		font-family: ${(props) => props.theme.typography.fontPrimary};
		font-size: 17px;
		line-height: 1.412;
		min-height: 100%; /* [1] */
		-moz-osx-font-smoothing: grayscale; /* [2] */
		-webkit-font-smoothing: antialiased;
		box-sizing: border-box;
		scroll-behavior: smooth;
	}
	.concito-web body,
	.dtge-editor-preview-container body {
		background: #FBEFE4;
		padding: 0;
		margin: 0;
	}
	.concito-web *, .concito-web *:before, .concito-web *:after,
	.dtge-editor-preview-container *, .dtge-editor-preview-container *:before, .dtge-editor-preview-container *:after {
		box-sizing: inherit;
	}

	.wf-loading h1,
	.wf-loading h2,
	.wf-loading h3,
	.wf-loading h4,
	.wf-loading h5,
	.wf-loading h6,
	.wf-loading button,
	.wf-loading a,
	.wf-loading p {
		visibility: hidden;
	}

	.wf-active h1, .wf-inactive h1,
	.wf-active h2, .wf-inactive h2,
	.wf-active h3, .wf-inactive h3,
	.wf-active h4, .wf-inactive h4,
	.wf-active h5, .wf-inactive h5,
	.wf-active h6, .wf-inactive h6,
	.wf-active button, .wf-inactive button,
	.wf-active a, .wf-inactive a,
	.wf-active p .wf-inactive p	{
		visibility: visible;
	}

	.concito-web hr {
		padding: 0;
		margin: 60px 0 0 0;

		border-width: 1px;
		border-top-style: solid;
		border-top-color:  #dedede;

		transition: border-top-color 300ms ease-in-out;
	}

	.concito-web button,
	.concito-web a {
		color: ${(props) => props.theme.typography.textColor};
		text-decoration: none;
	}

	.concito-web__rte a {
		text-decoration: none;
		background-image: linear-gradient(currentColor, currentColor);
		background-position: 0 102%;
		background-repeat: no-repeat;
		background-size: 0% 1px;
		transition: background-size 300ms ease-in-out;
		color: ${(props) => props.theme.linkColor};

		&:hover, &:focus {
			background-size: 100% 1px;
		}
	}

	.concito-web__rte ul {
		list-style: disc;
	}

	:root {
		--content-background: #fff;
		--content-divider: #dedede;
	}

  :root {
	--swiper-theme-color: #fff;
  }
  .swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 1;
  }

  .swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
	transform: translate3d(0px, 0, 0);
  }
  .swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
  }
  .swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
  }

  .swiper-slide--card {
	  width: auto;
  }

  .swiper-slide-invisible-blank {
	visibility: hidden;
  }
  /* CSS Mode */
  .swiper-container-css-mode > .swiper-wrapper {
	overflow: auto;
	scrollbar-width: none;
	/* For Firefox */
	-ms-overflow-style: none;
	/* For Internet Explorer and Edge */
  }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
	display: none;
  }
  .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
	scroll-snap-align: start start;
  }
  .swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
	scroll-snap-type: x mandatory;
  }
  .swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
	scroll-snap-type: y mandatory;
  }
  :root {
	--swiper-navigation-size: 50px;
	/*
	--swiper-navigation-color: var(--swiper-theme-color);
	*/
  }
  .swiper-button-prev,
  .swiper-button-next {
	position: absolute;
	top: 50%;
	width: calc(var(--swiper-navigation-size) / 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
	background: transparent;
	color: transparent;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
	opacity: 0.35;
	cursor: auto;
	pointer-events: none;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
	display: none;
	font-family: swiper-icons;
	font-size: var(--swiper-navigation-size);
	text-transform: none !important;
	letter-spacing: 0;
	text-transform: none;
	font-variant: initial;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
	left: 10px;
	right: auto;
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
	content: 'prev';
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
	right: 10px;
	left: auto;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
	content: 'next';
  }
  .swiper-button-prev.swiper-button-white,
  .swiper-button-next.swiper-button-white {
	--swiper-navigation-color: #ffffff;
  }
  .swiper-button-prev.swiper-button-black,
  .swiper-button-next.swiper-button-black {
	--swiper-navigation-color: #000000;
  }
  .swiper-button-lock {
	display: none;
  }
  :root {
	/*
	--swiper-pagination-color: var(--swiper-theme-color);
	*/
  }
  .swiper-pagination {
	position: absolute;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
  }
  .swiper-pagination.swiper-pagination-hidden {
	opacity: 0;
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: 30px;
	width: 100%;
	left: 0;
	@media screen and (min-width:36em){
		right: 70px;
		bottom: 70px;
		left: auto;
		width: auto;
		display: inline-block;
	}
  }
  .swiper-pagination-bullet {
	color: #fff;
	font-size: 11px;
	letter-spacing: 0;
	text-align: left;
	font-family: 'Avenir';
	display: inline-block;
	width: 50px;
	border-bottom: 1px solid #fff;
	opacity: 0.2;
	line-height: 16px;

	@media screen and (min-width:36em){
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0;
		border-bottom: 3px solid #fff;
	}
  }
  button.swiper-pagination-bullet {
	border: none;
	margin: 0;
	padding: 0;
	box-shadow: none;
	-webkit-appearance: none;
	   -moz-appearance: none;
			appearance: none;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
  }
  .swiper-pagination-bullet-active {
	opacity: 1;
	//background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
	display: inline-block;
	transition: 200ms transform, 200ms top;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 7.5px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
	transition: 200ms transform, 200ms left;
  }
  .swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
	transition: 200ms transform, 200ms right;
  }
  /* Progress */
  .swiper-pagination-progressbar {
	background: rgba(0, 0, 0, 0.25);
	position: absolute;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	background: var(--swiper-pagination-color, var(--swiper-theme-color));
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: scale(0);
	transform-origin: left top;
  }
  .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	transform-origin: right top;
  }
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
	width: 100%;
	height: 4px;
	left: 0;
	top: 0;
  }
  .swiper-container-vertical > .swiper-pagination-progressbar,
  .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
	width: 4px;
	height: 100%;
	left: 0;
	top: 0;
  }
  .swiper-pagination-white {
	--swiper-pagination-color: #ffffff;
  }
  .swiper-pagination-black {
	--swiper-pagination-color: #000000;
  }
  .swiper-pagination-lock {
	display: none;
  }
  /* Scrollbar */
  .swiper-scrollbar {
	border-radius: 10px;
	position: relative;
	-ms-touch-action: none;
	background: rgba(0, 0, 0, 0.1);
  }
  .swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	left: 1%;
	bottom: 3px;
	z-index: 50;
	height: 5px;
	width: 98%;
  }

  .swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	left: 0;
	top: 0;
  }
  .swiper-scrollbar-cursor-drag {
	cursor: move;
  }
  .swiper-scrollbar-lock {
	display: none;
  }
  .swiper-slide-zoomed {
	cursor: move;
  }
  /* Preloader */
  :root {
	/*
	--swiper-preloader-color: var(--swiper-theme-color);
	*/
  }
  .swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	animation: swiper-preloader-spin 1s infinite linear;
	box-sizing: border-box;
	border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
	border-radius: 50%;
	border-top-color: transparent;
  }
  .swiper-lazy-preloader-white {
	--swiper-preloader-color: #fff;
  }
  .swiper-lazy-preloader-black {
	--swiper-preloader-color: #000;
  }
  @keyframes swiper-preloader-spin {
	100% {
	  transform: rotate(360deg);
	}
  }
  /* a11y */
  .swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
  }
  .swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
  }
  .swiper-container-fade .swiper-slide {
	pointer-events: none;
	transition-property: opacity;
  }
  .swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
  }
  .swiper-container-fade .swiper-slide-active,
  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
  }

  /* custom overrides */

  .swiper-button-prev,
  .swiper-button-next {
	  line-height: 50px;
	  width: 40px;
	  height: 40px;
  }

  @keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }

  @keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
  }

  @keyframes fancyFadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
		transform: translateY(0);
	}
  }
  @keyframes bannerFadeOut {
	0% {
		opacity:1;
		transform: translate(-50%, -50%);
	}
	100% {
		opacity:0;
		transform: translate(-50%, -40%);
	}
  }

	@keyframes bannerFadeIn {
		0% {
			opacity:0;
		}
		100% {
			opacity:1;
			transform: translate(-50%, -50%);
		}
	}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
`;
