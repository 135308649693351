import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <circle cx="12" cy="12" r="12" fill="currentColor" fillRule="evenodd" />
  </React.Fragment>,
  "ListCircle",
  {
    viewBox: "0 0 24 24",
    width: "24px",
    height: "24px",
  }
);