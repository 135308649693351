import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g data-name="1">
      <path fill="currentColor" d="M365.89 450H137a50.17 50.17 0 0 1-50.11-50.11V98.11A50.17 50.17 0 0 1 137 48h150.1a15 15 0 0 1 10.61 4.39l113.9 113.88a15 15 0 0 1 4.39 10.61v223A50.17 50.17 0 0 1 365.89 450ZM137 78a20.13 20.13 0 0 0-20.11 20.11v301.78A20.13 20.13 0 0 0 137 420h228.89A20.13 20.13 0 0 0 386 399.89v-216.8L280.91 78Z" />
      <path fill="currentColor" d="M401 191.88h-78.77a50.17 50.17 0 0 1-50.11-50.11V63a15 15 0 0 1 30 0v78.77a20.13 20.13 0 0 0 20.11 20.11H401a15 15 0 0 1 0 30ZM331.46 246h-160a15 15 0 0 1 0-30h160a15 15 0 1 1 0 30ZM331.46 308h-160a15 15 0 0 1 0-30h160a15 15 0 1 1 0 30ZM331.46 370h-160a15 15 0 0 1 0-30h160a15 15 0 1 1 0 30Z" />
    </g>
  </React.Fragment>,
  "Document",
  {
    viewBox: "0 0 512 512",
    width: "512px",
    height: "512px",
  }
);
