import React from 'react';

const PageNotFound: React.FC<any> = ({
}) => {
	return (
		<React.Fragment>
			<h1>Siden findes ikke i React. Den er endnu ikke lavet i frontenden.</h1>
		</React.Fragment>
	);
};

export default PageNotFound;