import React from "react";
import styled from "styled-components";
import { Button, Heading, ResponsiveImage, ComponentWrapper } from "../..";
import { IUrl, IImage } from "../../../Types";
import CMSIcon from "../../CMS/CMSIcon";

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
    inset: 0 0 0 0;
    position: absolute;
`}

  & > * {
    max-width: 500px;
    position: relative;
  }
`;

const FillContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  inset: 0 0 0 0;
  min-width: 100%;
  position: absolute;

  svg {
    width: 100%;
    max-width: 489px;
  }
`;
const ImageContainer = styled.div`
  height: 370px;
  margin: 0 0 -50px;
  position: relative;

  ${(props) => props.theme.breakpoints("xsm")`
    height: 492px;
    margin-bottom: 0;
  `}

  picture, img {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  margin: 0 0 20px;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
    margin: -50px 0 20px;
  `}
  a {
    display: block;
    transform: scale(1);
    transition: transform 100ms ease-in-out;

    &:hover {
      transform: scale(1.035);
    }
}
`;

const BannerAbstract = styled.div`
  & p {
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  a {
    min-width: 250px;
  }
`;

export interface IImageBanner {
  Header: string;
  SubHeader: string;
  CTA?: IUrl;
  Image: IImage;
  Icon: string;
}

const ImageBanner: React.FC<IImageBanner> = ({
  Header,
  SubHeader,
  CTA,
  Image,
  Icon,
}) => {

  return (
    <ComponentWrapper>
      <div className="animate">
        {Boolean(Image) && (
          <ImageContainer>
            <ResponsiveImage {...Image} />
          </ImageContainer>
        )}
        <TextContainer>
          <FillContainer>
            <svg viewBox="0 0 489 301" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M478.23 243.398c-43.17 123.047-228.703 7.525-228.703 7.525s-76.89-28.386-149.662 4.264c-72.771 32.647-152.12-72.232-52.134-203.381C147.716-79.343 413.93 80.421 413.93 80.421s107.473 39.932 64.302 162.977Z"
                fill="#FFF"
                fillRule="evenodd"
                opacity=".79"
              />
            </svg>
          </FillContainer>
          {Boolean(Icon) && (
            <IconWrapper>
              <a href={CTA?.Url}>
                <CMSIcon icon={Icon} />
              </a>
            </IconWrapper>
          )}
          <Heading size={100} globalSized>
            {Header}
          </Heading>

          <BannerAbstract
            dangerouslySetInnerHTML={{
              __html: SubHeader,
            }}
          />

          {Boolean(CTA) && (
            <ButtonWrapper>
              <Button
                component={`a`}
                Modal={CTA?.Modal}
                href={CTA?.Url}
                id={CTA?.Id ? CTA?.Id : undefined}
                variant={`CTA`}
                target={CTA?.Target}
              >
                <span className="label">{CTA?.Label}</span>
                {Boolean(CTA?.Icon) && <CMSIcon icon={CTA?.Icon} />}
              </Button>
            </ButtonWrapper>
          )}
        </TextContainer>
      </div>
    </ComponentWrapper>
  );
};

export default ImageBanner;
