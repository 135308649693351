import { useState } from 'react';

export default function useMapState(): any {
	const [activeIndex, setActiveIndex] = useState();
	const [activeMarker, setActiveMarker] = useState();
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);

	return {
		activeIndex, setActiveIndex,
		activeMarker, setActiveMarker,
		showingInfoWindow, setShowingInfoWindow
	};
}