import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Modal from "react-modal";
import YouTube from "@u-wave/react-youtube";
import {
  Heading,
  Button,
  ResponsiveImage,
  ModalCloseButton,
  ComponentWrapper,
} from "../..";
import { IContentBanner, EContentBannerTextPosition } from "../../../Types";
import CMSIcon from "../../CMS/CMSIcon";

const VideoContainer = styled.div`
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 0;
  }
`;

const TextContainer = styled.div`
  flex-basis: 100%;
  padding: 30px 0 0;

  ${(props) => props.theme.breakpoints("xsm")`
    flex-basis: 47.5%;
    padding: 0;
    `}
`;
const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1080px;
  text-align: center;
  width: 100%;

  ${(props) => props.theme.breakpoints("xsm")`
    text-align: ${(props) => (props.isQuote ? "center" : "left")};
    ${(props) => {
      switch (props.textPosition) {
        case EContentBannerTextPosition.TextPosition_Left:
          return css`
            flex-direction: row-reverse;
          `;
        case EContentBannerTextPosition.TextPosition_Right:
          return css`
            flex-direction: row;
          `;
        case EContentBannerTextPosition.TextPosition_Center:
          return css`
            justify-content: ${(props) =>
              props.hasImage ? "space-between" : "center"};
            text-align: center;
          `;
      }
    }}
    `}

  ${(props) => {
    if (props.isQuote) {
      return css`
        text-align: center;

        ${TextContainer} > * {
          margin-left: auto;
          margin-right: auto;
          max-width: 358px;
        }
      `;
    }
  }}
`;

const ImageContainer = styled.div`
  flex-basis: 100%;
  position: relative;
  text-align: center;

  ${(props) => props.theme.breakpoints("xsm")`
    flex-basis: 47.5%;
    padding: 0;
    `}

  picture, img {
    display: inline-block;
    max-width: 100%;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;

  ${(props) => {
    switch (props.textPosition) {
      case EContentBannerTextPosition.TextPosition_Left:
        return css`
          text-align: left;
        `;
      case EContentBannerTextPosition.TextPosition_Right:
        return css`
          text-align: left;
        `;
      case EContentBannerTextPosition.TextPosition_Center:
        return css`
          text-align: center;
        `;
    }
  }}

  ${(props) => {
    if (props.isQuote) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

const BannerAbstract = styled.div`
  & p {
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;

  a {
    min-width: 250px;
    position: relative;

    svg {
      max-height: 14px;
      max-width: 16px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const VideoPlayerTrigger = styled.button`
  border: 0;
  background-color: transparent;
  color: ${(props) => props.theme.linkColor};
  cursor: pointer;
  height: 94px;
  inset: 50%;
  padding: 0;
  position: absolute;
  transform: translateY(-50%) translateX(-50%) scale(1);
  width: 94px;
  will-change: transform;
  transition: transform 100ms ease-in-out;

  &:hover {
    transform: translateY(-50%) translateX(-50%) scale(1.035);
  }
`;

const ContentBanner: React.FC<IContentBanner> = ({
  Header,
  SubHeader,
  CTA,
  Image,
  TextPosition = EContentBannerTextPosition.TextPosition_Left,
  VideoId: ContentVideoId,
  Icon,
  IsQuote = false,
}) => {
  const [VideoId, setVideoId] = useState<string>("");
  const scrollerRef = useRef<any>();

  const playVideo = (e: any, video): void => {
    e.preventDefault();
    setVideoId(video);
  };

  const stopVideo = (): void => {
    setVideoId("");
  };

  const closeModal = (e: any): void => {
    stopVideo();
  };

  useEffect(() => {
    Boolean(VideoId)
      ? disableBodyScroll(scrollerRef.current)
      : enableBodyScroll(scrollerRef.current);
  }, [VideoId]);

  return (
    <ComponentWrapper>
      <div ref={scrollerRef}>
        {Boolean(VideoId) && (
          <Modal
            isOpen={true}
            style={{
              content: {
                background: "transparent",
                border: 0,
                padding: 0,
                overflow: "hidden",
              },
              overlay: {
                opacity: 1,
                zIndex: 100,
                backgroundColor: "rgba(251, 239, 228, 0.94)",
              },
            }}
            onRequestClose={stopVideo}
          >
            <ModalCloseButton onButtonClick={closeModal}>
              <CMSIcon icon={"close"} />
            </ModalCloseButton>
            <VideoContainer>
              <YouTube
                video={VideoId}
                controls={true}
                showRelatedVideos={false}
                showInfo={false}
                modestBranding={false}
                onEnd={stopVideo}
                playsInline={true}
                annotations={false}
                autoplay={true}
              />
            </VideoContainer>
          </Modal>
        )}
        <ContentWrapper
          textPosition={TextPosition}
          hasImage={Boolean(Image)}
          isQuote={IsQuote}
          className="animate"
        >
          {Boolean(Image) && (
            <ImageContainer>
              <ResponsiveImage {...Image} />
              {Boolean(ContentVideoId) && (
                <VideoPlayerTrigger
                  onClick={(e) => playVideo(e, ContentVideoId)}
                >
                  <CMSIcon icon={"videoplay"} />
                </VideoPlayerTrigger>
              )}
            </ImageContainer>
          )}
          <TextContainer>
            {Boolean(Icon) && (
              <IconWrapper textPosition={TextPosition} isQuote={IsQuote}>
                <CMSIcon icon={Icon} />
              </IconWrapper>
            )}
            <Heading size={100} globalSized>
              {Header}
            </Heading>

            <BannerAbstract
              hasVideo={Boolean(ContentVideoId)}
              dangerouslySetInnerHTML={{
                __html: SubHeader,
              }}
            />

            {Boolean(CTA) && (
              <ButtonWrapper>
                <Button
                  component={`a`}
                  href={CTA?.Url}
                  id={CTA?.Id ? CTA?.Id : undefined}
                  variant={`CTA`}
                  target={CTA?.Target}
                >
                  <span className="label">{CTA?.Label}</span>
                  {Boolean(CTA?.Icon) && <CMSIcon icon={CTA?.Icon} />}
                </Button>
              </ButtonWrapper>
            )}
          </TextContainer>
        </ContentWrapper>
      </div>
    </ComponentWrapper>
  );
};

export default ContentBanner;
