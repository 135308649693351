import { IUrl, IImage } from '.';

export interface IBanner {
	Header: string;
	SubHeader: string;
	CTA?: IUrl;
	Image?: IImage;
	BackgroundVideo?: string;
	DisableBackgroundFilter?: boolean;
}

export interface IInteractiveVideo {
	Teaser?: string;
	Full?: string;
}

export interface IWallpaper {
	BackgroundVideo?: string;
	Image?: IImage;
	DisableBackgroundFilter?: boolean;
}

export enum EContentBannerTextPosition {
	TextPosition_Right = 'Right',
	TextPosition_Left = 'Left',
	TextPosition_Center = 'Center',
}

export interface IContentBanner extends IBanner {
	CTAOutline?: boolean;
	TextPosition: EContentBannerTextPosition;
    VideoId?: string;
	Icon?: string;
	IsQuote?: boolean;
}