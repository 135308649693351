const isClient = typeof window === 'object';

export const setCookie = (name, value, daysToExpire) => {
    if (!isClient) {
        return;
    }

    let expireDate = new Date();
    if (daysToExpire) {
        expireDate.setDate(expireDate.getDate() + daysToExpire);
    }

    document.cookie = `${name}=${value}; expires="${expireDate}";`;
};

export const getCookie = (name) => {
    if (!isClient) {
        return;
    }

    var cookieName = `${name}=`;
    var decodedCookie = decodeURIComponent(document.cookie);
    
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return false;
};