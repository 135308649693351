import { useState } from 'react';

export type TDetectVisibility = {
	isVisible: boolean;
	setVisibility: (visibility: boolean) => void;
}

export default function useDetectVisibility(): TDetectVisibility {
	const [isVisible, setIsVisible] = useState(false);

	const setVisibility = visibility => {
		setIsVisible(visibility);
	};

	return { isVisible, setVisibility };
}