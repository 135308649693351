
import * as React from 'react';
import createSvgIcon from '../SvgIcon/createSvgIcon';

export default createSvgIcon(
// tslint:disable-next-line:max-line-length
<React.Fragment><path fill="#00A050" fillRule="nonzero" d="M11.1793.4287c.3155-.4533.9387-.565 1.392-.2494.4184.2912.5458.8447.3147 1.2846l-.0653.1074-7.6833 11.038L.2548 7.1527c-.3683-.4115-.3332-1.0437.0784-1.412.3799-.34.9478-.3362 1.3226-.0099l.0894.0883L4.94 9.39 11.1793.4287z" /></React.Fragment>
,
'Checkmark',
{
		viewBox : '0 0 13 13',
		width : '13px',
		height : '13px'
});
