import * as React from 'react';
import { pure } from 'recompose';
import { SvgIcon } from '../SvgIcon';

/* import loadable from '@loadable/component';

const SvgIcon = loadable(() =>
	import('../SvgIcon')
		.then(({ SvgIcon }) => ({ default: SvgIcon }))
); */

function createSvgIcon(svgPath, displayName, props) {
	let Icon = () => (
		<SvgIcon {...props}>
			{svgPath}
		</SvgIcon>
	);

	Icon = pure(Icon);

	return Icon;
}

export default createSvgIcon;