import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M43.006 0S-.342.514.002 5.66c.344 5.142 2.753 47.068 2.753 47.84 0 .772 43.004 50.414 43.004 50.414L77.753 70.22l-3.44-33.437L43.007 0Z"
      fill="#ED7272"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgNature",
  {
    viewBox: "0 0 78 104",
    width: "78px",
    height: "104px",
  }
);
