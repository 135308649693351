import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../Contexts/StateProvider';

const StyledBurger = styled.svg`
	display: block;
	width: 34px;
	height: 28px;
	fill: currentColor;
`;

const Bar = styled.rect`
	transform-origin: center center;
	transform: rotate(0deg);
	will-change: transform, opacity;
	transition: transform 300ms ease-in, opacity 10ms ease-in;
`;

const TopBar = styled(Bar)`
	opacity: ${props => props.isOpen ? '0' : '1'};
`;

const MiddleBar1 = styled(Bar)`
	transform: ${props => props.isOpen ? 'rotate(-45deg)' : 'rotate(0deg)'};
`;

const MiddleBar2 = styled(Bar)`
	transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0deg)'};
`;

const BottomBar = styled(Bar)`
	opacity: ${props => props.isOpen ? '0' : '1'};
`;

const Burger: React.FC = () => {
	const { isMenuOpen } = useAppContext();

	return (
		<StyledBurger xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 28">
			<g fillRule="evenodd">
				<TopBar isOpen={isMenuOpen} width="34" height="4" rx="4"/>
				<MiddleBar1 isOpen={isMenuOpen} y="12" width="34" height="4" rx="4"/>
				<MiddleBar2 isOpen={isMenuOpen} y="12" width="34" height="4" rx="4"/>
				<BottomBar isOpen={isMenuOpen} y="24" width="34" height="4" rx="4"/>
			</g>
		</StyledBurger>
	);
};

export default Burger;