export const globalHeadingsProfile = new Map(
	Object.entries({
		h1: 100,
		h2: 200,
		h3: 300,
		h4: 400,
		h5: 500,
		h6: 600,
	})
);

export const componentsHeadingsProfile = new Map(
	Object.entries({
		h1: 200,
		h2: 500,
		h3: 600,
		h4: 700,
		h5: 800,
		h6: 900
	})
);

export const globalHeadingMarginBottomsProfile = new Map(
	Object.entries({
		h1: 60,
		h2: 30,
		h3: 20,
		h4: 20,
		h5: 10,
		h6: 10,
	})
);
