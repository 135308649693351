import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M34.574 9.892s-21.92-26.08-29.648 6.453C-2.8 48.88-.247 62.963 4.478 68.713c4.724 5.752 18.232 34.671 17.831 35.989-.4 1.316 32.53 7.728 32.53 7.728l26.984-73.433S44.985 28.88 34.574 9.892"
      fill="#E73C3E"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgRessources",
  {
    viewBox: "0 0 81.823 112.43",
    width: "81.823px",
    height: "112.43px",
  }
);
