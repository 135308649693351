import React, { useState, useRef, useEffect, Fragment } from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import { useAppContext } from "../../../Contexts/StateProvider";
import ReactSelect from "react-select";
import { ResponsiveImage, Heading } from "../..";
import { marker } from "leaflet";
import { NONAME } from "dns";

const Root = styled.section`
  overflow: hidden;
  position: relative;

  &:after {
    background-color: ${(props) => props.theme.colors.bgColor};
    background-image: url('data:image/svg+xml;utf8,<svg width="15" height="25" xmlns="http://www.w3.org/2000/svg"><path d="M13.81 13.353c.476-.48.476-1.253 0-1.706L3.136.9a1.336 1.336 0 0 0-1.906 0 1.359 1.359 0 0 0 0 1.92l8.765 8.827c.477.48.477 1.253 0 1.706L1.23 22.18a1.359 1.359 0 0 0 0 1.92c.53.533 1.377.533 1.906 0L13.81 13.353Z" fill="%238ECDB2" fill-rule="nonzero"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 35px;
    border-radius: 100%;
    bottom: 0;
    content: "";
    display: block;
    height: 120px;
    left: 50%;
    position: absolute;
    transform: translate3d(-50%, 25%, 0) rotate(90deg);
    width: 120px;
    z-index: 1000;
  }
`;

const Select = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

const MapContainer = styled.div`
  background-color: #cad2d3;
  height: 620px;
  margin: 0 0 30px;
  width: 100%;
  z-index: 0;

  .leaflet-bar {
    border-color: ${(props) => props.theme.colors.neoMint};
  }

  .leaflet-control-zoom {
    a {
      color: ${(props) => props.theme.colors.neoMint};
      border-color: ${(props) => props.theme.colors.neoMint};
    }
    .leaflet-disabled {
      color: RGBA(142, 205, 178, 0);
    }
  }

  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }

  .smallMarker {
    padding: 10px;

    svg {
      display: block;
    }
  }

  a.leaflet-popup-close-button {
    color: ${(props) => props.theme.colors.textColor};
    font-size: 24px;
    padding: 0;
    right: 20px;
    top: 20px;
  }

  .activityMapPopup {
    display: flex;
    flex-direction: column;
    font-family: "Avenir";
    font-size: 15px;
    padding: 10px 20px;
    text-align: center;
    width: 279px;

    img {
      max-width: 240px;
      max-height: 120px;
      margin: 0 auto;
      width: auto;
    }

    h3 {
      font-size: 20px;
      line-height: 42px;
      margin: 7px 0;
    }

    p {
      background-image: linear-gradient(
        ${(props) => props.theme.navigation.lineColor},
        ${(props) => props.theme.navigation.lineColor}
      );
      background-position: 50% 0%;
      background-repeat: no-repeat;
      background-size: 200px 1px;
      margin-top: 0;
      padding-top: 15px;
    }

    .activityMapPopupButton {
      align-items: center;
      background-color: ${(props) => props.theme.colors.neoMint};
      border-radius: 38px;
      color: ${(props) => props.theme.colors.neutralWhite};
      display: inline-flex;
      cursor: pointer;
      font-weight: 600;
      height: 50px;
      justify-content: center;
      min-width: 200px;
      padding: 0 25px;
      text-decoration: none;
      transform: scale(1);
      transition: transform 100ms ease-in-out;
      will-change: transform;
      width: auto;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        transform: scale(1.035);
      }

      svg {
        margin-left: 16px;
      }
    }
  }
`;

export interface IActivityMap {
    Hosts: any;
    ActiveCommunes: any;
    Configuration: any;
    Communes: any;
}

const ActivityMap: React.FC<IActivityMap> = ({
    Hosts = [],
    ActiveCommunes,
    Configuration,
    Communes,
}) => {
    const { CurrentCommune, setCurrentCommune } = useAppContext();
    //useExternalScripts("https://www.scriptdomain.com/script")
    const { map, setMap } = useAppContext();
    let communes;
    let activeLayer;
    let L;

    useEffect(() => {
        if (map.length != 0) {
            const isClient = typeof window === "object";
            if (isClient) {
                communes = require("./communeGeo.json");
                L = require("leaflet");

                let activityMarkers = new L.FeatureGroup();
                const clickCommuneZoomLevel = 10;
                const svgIcon = L.divIcon({
                    html: `
        <svg
          width="9"
          height="9"
          viewBox="0 0 18 18"
          version="1.1"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="9" cy="9" r="9" fill="#7AB59C" fillRule="evenodd" />
        </svg>`,
                    className: "smallMarker",
                    iconSize: [30, 30],
                    iconAnchor: [15, 15],
                });

                const resizedIcon = L.divIcon({
                    html: `<svg width="61" height="55" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
      <filter x="-16.3%" y="-14.7%" width="132.6%" height="141.2%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/>
      </filter>
      <path d="M33 15c-9.098 0-16 2.908-16 12 0 8.737-6.366 14.192-.501 22C22.363 41.181 57 33.709 57 25c0-9.092-14.902-10-24-10Z" id="b"/>
  </defs>
  <g fill-rule="nonzero" fill="none">
      <use filter="url(#a)" xlink:href="#b" opacity=".269" fill="#000"/>
      <path d="M16.5 0C25.598 0 33 7.397 33 16.489 33 25.198 22.363 41.18 16.499 49 10.634 41.192 0 25.226 0 16.489 0 7.397 7.402 0 16.5 0Zm0 9a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z" fill="#7AB59C"/>
  </g>
  </svg>`,
                    className: "",
                    iconSize: [61, 55],
                    iconAnchor: [16, 33],
                });
                const style = {
                    color: "#8ECDB2",
                    weight: 2,
                    fillOpacity: 0,
                };

                const getData = async () => {
                    let bounds = map.getBounds();

                    const currentZoom = map.getZoom();

                    let latMin = bounds.getSouth();
                    let latMax = bounds.getNorth();
                    let lngMin = bounds.getWest();
                    let lngMax = bounds.getEast();

                    try {
                        const res = await fetch(`${Configuration.FetchHostsUrl}?minLat=${latMin}&maxLat=${latMax}&minLon=${lngMin}&maxLon=${lngMax}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });

                        if (!res.ok) {
                            throw new Error(res.statusText);
                        }



                        const data = await res.json();

                        //const findObjectsWithDuplicateMarker = (array) => {
                        //    const markerMap = new Map<string, object>();
                        //    const duplicates: object[] = [];

                        //    for (const obj of array) {
                        //        const markerValue = JSON.stringify(obj.Marker);

                        //        if (markerMap.has(markerValue)) {
                        //            // Duplicate marker value found, add to duplicates array
                        //            duplicates.push(obj);
                        //        } else {
                        //            // Add marker value and object to the map
                        //            markerMap.set(markerValue, obj);
                        //        }
                        //    }

                        //    return duplicates;
                        //}

                        //const objectsWithDuplicates = findObjectsWithDuplicateMarker(data);

                        //console.log(objectsWithDuplicates)
   

                        data.forEach((Host) => {
                            var alreadyExists = false;

                            activityMarkers.getLayers().forEach((layer) => {
                                if (!alreadyExists && layer instanceof L.Marker && layer.getLatLng().equals(Host.Marker)) {
                                    alreadyExists = true;
                                }
                            })

                            if (!alreadyExists) {
                                const mapMarker = L.marker(Host.Marker, {
                                    icon: svgIcon,
                                });

                                mapMarker.addEventListener("click", function (e) {
                                    var latlngs = this.getLatLng();
                                    var latHeight =
                                        map.getBounds().getNorthEast().lat -
                                        map.getBounds().getSouthWest().lat;
                                    var latlng = L.latLng([latlngs.lat + latHeight / 3, latlngs.lng]);
                                    map.flyTo(latlng);
                                });

                                mapMarker
                                    .bindPopup(
                                        `<div class="activityMapPopup">\
              <img src="${Host.Logo.ImageUrl}" />\
              <h3>${Host.HostName}</h3>\
              <p>${Host.HostDescription}</p>\
              <div><a href="#searchSection" class="activityMapPopupButton">\
                  <span>${Configuration?.HostPopup?.ButtonLabel || ""}</span>\
                  <svg focusable="false" viewBox="0 0 15 18" aria-hidden="true" role="img" width="18px" height="18px" class="sc-gzVnrw hcmybO"><path d="M7.402.556c-.592 0-1.071.48-1.071 1.071v11.754L2.269 9.025a1.072 1.072 0 1 0-1.565 1.463l5.913 6.347a1.072 1.072 0 0 0 1.568 0l5.915-6.347a1.072 1.072 0 1 0-1.567-1.463l-4.06 4.356V1.627c0-.591-.479-1.071-1.07-1.071Z" fill="CurrentColor" fill-rule="nonzero"></path></svg>\
              </a></div>\
          </div>`
                                    )
                                    .openPopup();
                                activityMarkers.addLayer(mapMarker);
                                addRemoveLayers();
                            }


                        });

                    } catch (err) {
                        alert("Der var et problem med at loade aktiviteter. Genindl�s siden eller pr�v igen senere")
                    }
                };

                

                map.on("moveend", () => {
                    const currentZoom = map.getZoom();
                    if (currentZoom >= clickCommuneZoomLevel) {
                        getData();
                    }

                    addRemoveLayers();

                });

                const addRemoveLayers = () => {
                    //getting coordinates of the maps viewport
                    let bounds = map.getBounds();

                    const currentZoom = map.getZoom();

                    let latMin = bounds.getSouth();
                    let latMax = bounds.getNorth();
                    let lngMin = bounds.getWest();
                    let lngMax = bounds.getEast();

                    map.eachLayer(function (marker) {
                        if (marker.options && marker.options.pane === "markerPane") {
                            let coordinates = marker.getLatLng();

                            if (
                                currentZoom < clickCommuneZoomLevel ||
                                coordinates.lat < latMin ||
                                coordinates.lat > latMax ||
                                coordinates.lng < lngMin ||
                                coordinates.lng > lngMax
                            ) {
                                if (map.hasLayer(marker)) {
                                    map.removeLayer(marker);
                                }
                            }
                        }
                    });

                    if (currentZoom >= clickCommuneZoomLevel) {
                        activityMarkers.eachLayer(function (layer) {
                            let coordinates = layer.getLatLng();
                            if (
                                coordinates.lat > latMin &&
                                coordinates.lat < latMax &&
                                coordinates.lng > lngMin &&
                                coordinates.lng < lngMax
                            ) {
                                map.addLayer(layer);
                            }
                        });

                        activityMarkers.eachLayer((Marker) => {
                            Marker.setIcon(currentZoom > 11 ? resizedIcon : svgIcon);
                        });
                    }
                };

                map.on("zoomend", function () {
                    addRemoveLayers();
                });

                const selectFeature = (e) => {
                    setCurrentCommune(e.target.feature.properties.LAU_CODE);

                    if (activeLayer) {
                        activeLayer.setStyle({
                            ...style,
                        });
                    }

                    activeLayer = e.target;

                    activeLayer.setStyle({
                        color: "#8ECDB2",
                        weight: 2,
                        fillOpacity: 0.5,
                    });
                    map.fitBounds(e.target.getBounds());
                };

                const hoverControl = (e) => {
                    const zoomLevel = map.getZoom();

                    if (zoomLevel < clickCommuneZoomLevel) {

                        e.target.setStyle({
                            color: "#8ECDB2",
                            fillOpacity: 0.25,
                        });

                        e.target
                            .bindTooltip(e.target.feature.properties.LAU_NAME)
                            .openTooltip();
                    }
                };

                const hoverExitControl = (e) => {
                    const zoomLevel = map.getZoom();

                    if (zoomLevel <= clickCommuneZoomLevel) {
                        if (e.target !== activeLayer) {
                            e.target.setStyle({
                                ...style,
                            });
                        }
                    }
                };

                const onEachFeature = (feature, layer) => {
                    layer.on({
                        mouseover: hoverControl,
                        mouseout: hoverExitControl,
                        click: selectFeature,
                    });
                    layer._leaflet_id = feature.properties.LAU_CODE;
                };

                L.tileLayer(
                    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFsY2hlbnBvc3QiLCJhIjoiY2tqaWJ1bXRsMmd4MjMxc2NobWd3Nnk2dyJ9.1IiEC7KCRVz1sQsnA562Yw",
                    {
                        minZoom: 7,
                        maxZoom: 18,
                        id: "mapbox/light-v9",
                        tileSize: 512,
                        zoomOffset: -1,
                    }
                ).addTo(map);



                communes.forEach((commune) => {
                    if (ActiveCommunes.includes(commune.properties.LAU_CODE)) {
                        L.geoJson(commune, {
                            style: style,
                            onEachFeature: onEachFeature,
                        }).addTo(map);
                    }
                });
                if (CurrentCommune.length > 0) {
                    map.eachLayer(function (layer) {
                        if (layer._leaflet_id == CurrentCommune) {
                            layer.fireEvent("click");
                        }
                    });
                }
            }
        }
    }, [map]);

    useEffect(() => {
        const isClient = typeof window === "object";

        if (isClient) {
            const communes = require("./communeGeo.json");
            L = require("leaflet");
            let activeLayer;
            let activityMarkers = new L.FeatureGroup();
            const clickCommuneZoomLevel = 10;

            const { GestureHandling } = require("leaflet-gesture-handling");

            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

            setMap(
                L.map("map", {
                    center: [56.26, 11],
                    zoom: 7,
                    gestureHandling: true,
                })
            );

            const style = {
                color: "#8ECDB2",
                weight: 2,
                fillOpacity: 0,
            };

            const svgIcon = L.divIcon({
                html: `
      <svg
        width="9"
        height="9"
        viewBox="0 0 18 18"
        version="1.1"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
      <circle cx="9" cy="9" r="9" fill="#7AB59C" fillRule="evenodd" />
      </svg>`,
                className: "smallMarker",
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });

            const resizedIcon = L.divIcon({
                html: `<svg width="61" height="55" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <filter x="-16.3%" y="-14.7%" width="132.6%" height="141.2%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/>
    </filter>
    <path d="M33 15c-9.098 0-16 2.908-16 12 0 8.737-6.366 14.192-.501 22C22.363 41.181 57 33.709 57 25c0-9.092-14.902-10-24-10Z" id="b"/>
</defs>
<g fill-rule="nonzero" fill="none">
    <use filter="url(#a)" xlink:href="#b" opacity=".269" fill="#000"/>
    <path d="M16.5 0C25.598 0 33 7.397 33 16.489 33 25.198 22.363 41.18 16.499 49 10.634 41.192 0 25.226 0 16.489 0 7.397 7.402 0 16.5 0Zm0 9a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z" fill="#7AB59C"/>
</g>
</svg>`,
                className: "",
                iconSize: [61, 55],
                iconAnchor: [16, 33],
            });
        }
    }, []);

    const selectStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            color: state.selectProps.menuColor,
            borderRadius: "8px",
            padding: 0,
            zIndex: 3000,
            cursor: "pointer",
        }),
        menuList: (provided) => ({
            ...provided,
            borderRadius: "8px",
            padding: 0,
        }),
        placeholder: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
            fontFamily: "Avenir",
            color: "#939393",
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "#fff",
            border: "1px solid #BCB8B1",
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "48px",
            height: "48px",
            cursor: "pointer",
            boxShadow: "0 !important",
            paddingLeft: "16px",
            "&:hover": {
                border: "1px solid #BCB8B1 !important",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "48px",
            display: "flex",
            alignItems: "center",
            letterSpacing: "normal",
            color: "#4A4A4A",
            background: state.isSelected ? "#f4f4f4" : "#fff",
            padding: "0 8px",
            height: "48px",
            maxHeight: "48px",
            minHeight: "32px",
            borderBottom: "1px solid #BCB8B1",
            paddingLeft: "16px",

            "&:hover": {
                background: "#f4f4f4",
                "& path": {
                    stroke: "#fff",
                },
            },

            "&:last-of-type": {
                borderBottom: 0,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            color: "#fff",
            padding: "0",
            height: "48px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            "& > div": {
                borderLeft: "1px solid #BCB8B1",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "#4A4A4A",
        }),
    };

    const CommuneFilter = styled.div`
    margin: 0 auto;
    max-width: 330px;
  `;

    const CommuneLogo = styled.div`
    background-color: white;
    border-radius: 100%;
    height: 98px;
    margin: 50px auto 13px;
    position: relative;
    transition: all 0.3s ease;
    width: 98px;

    svg,
    img {
      height: 100%;
      max-width: 65px;
      max-height: 65px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }

    svg {
      height: auto;
    }
  `;

    const CommuneContainer = styled.div`
    margin: 0 auto;
    max-width: 470px;
  `;

    const filtersRef = useRef<any>();

    const handleCommuneChange = (commune) => {
        map.eachLayer(function (layer) {
            if (layer._leaflet_id == commune.value) {
                layer.fireEvent("click");
            }
        });
    };

    const getCommuneLogo = () => {
        if (CurrentCommune) {
            let result = Communes.filter(
                (Commune) => Commune.CommuneId === CurrentCommune
            );
            return result[0].CommuneLogo;
        }
    };

    const getCommuneName = () => {
        if (CurrentCommune) {
            let result = Communes.filter(
                (Commune) => Commune.CommuneId === CurrentCommune
            );
            return result[0].CommuneName || "";
        }
    };

    const getHostData = (hostIds: Array<string>) => {
        let result = [];
        hostIds.forEach((hostId) => {
            result = result.concat(Hosts.filter((Host) => Host.HostId === hostId));
        });
        return result;
    };

    const selectOptions = [
        ...Communes.map(({ CommuneId: value, CommuneName: label }) => {
            return {
                value,
                label,
            };
        }),
    ];

    return (
        <div>
            <Root>
                <Helmet>
                    {/* <script
            src="https://unpkg.com/leaflet-gesture-handling@1.2.2/dist/leaflet-gesture-handling.min.js"
            type="text/javascript"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet-gesture-handling/dist/leaflet-gesture-handling.min.css"
            type="text/css"
          ></link> */}
                </Helmet>
                <MapContainer id="map"></MapContainer>
            </Root>
            <Select>
                <CommuneFilter ref={filtersRef}>
                    <ReactSelect
                        value={selectOptions.filter((option) => {
                            return option.value === CurrentCommune;
                        })}
                        onChange={handleCommuneChange}
                        placeholder={Configuration.Filters.Commune.Label}
                        options={selectOptions}
                        styles={selectStyles}
                        isSearchable={false}
                    />
                </CommuneFilter>
                {CurrentCommune ? (
                    <CommuneContainer>
                        <CommuneLogo>
                            <ResponsiveImage {...getCommuneLogo()}></ResponsiveImage>
                        </CommuneLogo>

                        {Configuration.Filters.Heading ? (
                            <Heading size={100}>
                                {Configuration.Filters.Heading.replace(
                                    "{CommuneName}",
                                    getCommuneName()
                                )}
                            </Heading>
                        ) : (
                            ""
                        )}
                    </CommuneContainer>
                ) : (
                    ""
                )}
            </Select>
        </div>
    );
};

export default ActivityMap;
