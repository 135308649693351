import { useState, useEffect } from 'react';
import { useAppContext } from '../Contexts/StateProvider';

export type TMobileMenuHook = {
	isOpen: boolean;
	toggleMenu: (e: any) => void;
	setIsOpen: any;
}

export default function useMobileMenu(): TMobileMenuHook {
	const [isOpen, setIsOpen] = useState(false);
	const toggleMenu = (e: any): void => {
		e.preventDefault();

		e.currentTarget.blur();

		setIsOpen(isOpen ? false : true);
	};

	return { isOpen, setIsOpen, toggleMenu };
}