import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { intersectionObserver } from "../..";

const Root = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 30px;
  position: relative;

  &.animate-content {
    .animate {
      opacity: 0;
      transform: translateY(50px);
    }
    &.is-visible {
      .animate {
        animation: fancyFadeIn ease 1s;
        animation-fill-mode: forwards;
      }
    }
  }
`;

export interface IComponentWrapper {
  children: React.ReactNode;
}

const ComponentWrapper: React.FC<IComponentWrapper> = ({ children }) => {
  const [containerRef, containerVisible] = intersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });
  const [className, setClassName] = useState<string>("animate-content");

  useEffect(() => {
    let newClassName = className;
    if (!className.includes("is-visible")) {
      newClassName = containerVisible ? className + " is-visible" : className;
    }
    setClassName(newClassName);
  }, [containerVisible]);
  return (
    <Root className={className} ref={containerRef}>
      {children}
    </Root>
  );
};

export default ComponentWrapper;
