import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
      <circle fillOpacity=".49" fill="#8ECDB2" cx="47" cy="47" r="47" />
      <circle fill="#8ECDB2" cx="47" cy="47" r="33" />
      <path
        d="m60.535 58.35-6.867-6.875c3.452-4.494 3.107-10.985-1.036-15.095A11.405 11.405 0 0 0 44.499 33c-2.954 0-5.87 1.114-8.133 3.38-4.488 4.494-4.488 11.791 0 16.285a11.405 11.405 0 0 0 8.133 3.38c2.455 0 4.91-.768 6.944-2.343l6.905 6.837c.307.307.69.461 1.113.461a1.6 1.6 0 0 0 1.112-.46c.576-.577.576-1.576-.038-2.19Zm-15.997-5.416c-2.264 0-4.335-.883-5.947-2.458-3.26-3.265-3.26-8.604 0-11.907a8.35 8.35 0 0 1 5.947-2.458c2.263 0 4.335.884 5.946 2.458 1.611 1.575 2.455 3.688 2.455 5.954 0 2.266-.882 4.34-2.455 5.953-1.573 1.613-3.721 2.458-5.946 2.458Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  "Search",
  {
    viewBox: "0 0 94 94",
    width: "94px",
    height: "94px",
  }
);
