import React from "react";
import styled from "styled-components";

const ModalCloseButtonContainer = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.bgColor};
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: -10px;
  top: -10px;
  transform: scale(1);
  transition: transform 100ms ease-in-out;
  will-change: transform;
  z-index: 110;

  &:hover {
    transform: scale(1.035);
  }

  svg {
    display: block;
  }
`;

export interface IModalCloseButton {
  children;
  onButtonClick: (val: any) => void;
}

const ModalCloseButton: React.FC<IModalCloseButton> = ({
  children,
  onButtonClick,
}) => {
  return (
    <ModalCloseButtonContainer onClick={onButtonClick}>
      {children}
    </ModalCloseButtonContainer>
  );
};

export default ModalCloseButton;
