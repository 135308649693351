// tslint:disable-next-line:no-var-requires
const UI = require('expose-loader?UI!./UI');

// Map pins
require('./UI/Assets/MapPins/pin_small.svg');
require('./UI/Assets/MapPins/pin_small_selected.svg');
require('./UI/Assets/MapPins/pin_micro.svg');
require('./UI/Assets/MapPins/pin_micro_selected.svg');

// Favicon
require('./UI/Assets/Favicon/apple-touch-icon.png');
require('./UI/Assets/Favicon/browserconfig.xml');
require('./UI/Assets/Favicon/favicon-16x16.png');
require('./UI/Assets/Favicon/favicon-32x32.png');
require('./UI/Assets/Favicon/favicon.ico');
require('./UI/Assets/Favicon/site.webmanifest');
require('./UI/Assets/Favicon/safari-pinned-tab.svg');

require('./UI/Assets/Icons/DGR_logo_primaer.svg');
require('./UI/Assets/Icons/DGR_logo_primaer.png');
require('./UI/Assets/Icons/DGR_logo_ikon_neg.png')

require('./UI/Assets/Icons/circle_logo.png')

// Fonts - to prevent this: https://github.com/gatsbyjs/gatsby/issues/16452
require('./UI/Assets/Css/fonts.css');