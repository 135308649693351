import { theme } from './base';
import { colors } from './colors';

const normalTheme = {
	palette: {
		background: colors.bgColor		
	},
	...theme
}

export default normalTheme;