import React from "react";
import {
  Header,
  Footer
} from "../Components";
import { IPage } from "../Types";
import styled from "styled-components";

const Root = styled.div`
  height: 100%;
  max-width: 100vw;
  padding-top: 100px;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints("sm")`
        padding-top: 168px;
	`}
`;

const ContentArea = styled.div`
  ${(props) => props.theme.breakpoints("sm")`
		min-height: calc(100vh - 124px - 600px);
	`}
`;

const Master: React.FC<IPage> = ({
  HomeUrl,
  Header: HeaderComponent,
  Language,
  Theme,
  children,
  Modals,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Header
        Fullscreen={false}
        HomeUrl={HomeUrl}
        Header={HeaderComponent}
        CurrentPage={rest.Alias}
        sticky
      >
      </Header>

      <Root>

        <ContentArea data-indexable>
          {React.cloneElement(children, { ...rest })}
        </ContentArea>

        <Footer HomeUrl={HomeUrl} {...rest} />
      </Root>
    </React.Fragment>
  );
};

export default Master;
