import React from 'react';

const ResponsiveImage: React.FC<any> = ({ ImageUrl, SrcSets, AltText }) => {
	return (
		<>
			{Boolean(SrcSets) && (
				<picture>
					{SrcSets.map(({ SrcSet: SrcSetProfile, Media }, key) => {
						return (
							<source key={key} media={Media} srcSet={SrcSetProfile} />
						)
					})}
					<img src={ImageUrl} alt={AltText}  />
				</picture>
			)}
			{Boolean(!SrcSets) && (
				<img src={ImageUrl} alt={AltText} />
			)}
		</>
	);
};
export default ResponsiveImage;
