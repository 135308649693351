import React from "react";
import { Helmet } from "react-helmet";
import { ISEO } from "../../Types";

const SEO: React.FC<ISEO> = ({
  Title,
  Description,
  Image,
  Type,
  Url: CurrentUrl,
  HrefLangs,
  PageCategory,
  PageArea,
  Country,
  Language,
  IndexPage,
}) => {
  const hrefLangLinks = (langs) =>
    langs.map(({ Url, HrefLang }) => {
      return {
        rel: "alternate",
        href: Url,
        hrefLang: HrefLang,
      };
    });

  return (
    <Helmet
      title={Title}
      link={hrefLangLinks(HrefLangs)}
      meta={[
        {
          name: "description",
          content: Description,
        },
        {
          property: "og:type",
          content: Type,
        },
        {
          property: "og:url",
          content: CurrentUrl,
        },
        {
          property: "og:title",
          content: Title,
        },
        {
          property: "og:description",
          content: Description,
        },
        {
          name: "PageCategory",
          content: PageCategory,
        },
        {
          name: "PageArea",
          content: PageArea,
        },
        {
          name: "Site",
          content: Country,
        },
        {
          name: "Language",
          content: Language,
        },
      ]
        .concat(
          Boolean(Image)
            ? [
                {
                  name: "og:image",
                  content: Image?.ImageUrl,
                },
                {
                  property: "og:image",
                  content: Image?.ImageUrl,
                },
                {
                  property: "og:image:width",
                  content: Image?.ImageWidth,
                },
                {
                  property: "og:image:height",
                  content: Image?.ImageHeight,
                },
              ]
            : []
        )
        .concat(
          Boolean(IndexPage)
            ? []
            : [
                {
                  name: `robots`,
                  content: "noindex",
                },
              ]
        )}
    >
      <link href="//www.youtube.com" rel="dns-prefetch" />
    </Helmet>
  );
};

export default SEO;
