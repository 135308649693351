import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="m0 46.538 11.13-31.273S20.935-9.91 40.013 4.4a24081.134 24081.134 0 0 0 37.893 28.358l2.648 28.358-49.551 16.962L0 46.538Z"
      fill="#F3B90B"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgFood",
  {
    viewBox: "0 0 80.554 78.078",
    width: "80.554px",
    height: "78.078px",
  }
);
