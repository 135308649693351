export const serialize = function (form) {
	const formToObject: any = {};
	if (typeof form == 'object' && form.nodeName.toLowerCase() == 'form') {
		const inputs = form.getElementsByTagName('input');
		const textareas = form.getElementsByTagName('textarea');
		const fields = Array.prototype.concat.call([
			...inputs,
			...textareas
		]);

		for (let i = 0; i < fields.length; i++) {
			if (fields[i].type === 'radio') {
				if (fields[i].checked) {
					formToObject[fields[i].getAttribute('name')] = fields[i].value;
				}
			} else {
				formToObject[fields[i].getAttribute('name')] = fields[i].value;
			}
		}
		const buttons = form.querySelectorAll('button[type="submit"]');
		for (let i = 0; i < buttons.length; i++) {
			if (buttons[i].getAttribute('name')) {
				formToObject[buttons[i].getAttribute('name')] = buttons[i].value;
			}
		}
	}

	return formToObject;
};
