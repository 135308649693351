import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
      <circle fillOpacity=".49" fill="#8ECDB2" cx="47" cy="47" r="47" />
      <circle fill="#8ECDB2" cx="47" cy="47" r="33" />
      <path
        d="M40.656 58.999a4.811 4.811 0 0 1-2.43-.671 5.043 5.043 0 0 1-1.652-1.778A4.776 4.776 0 0 1 36 54.268V38.601a4.66 4.66 0 0 1 2.328-3.925 4.81 4.81 0 0 1 2.26-.67 4.508 4.508 0 0 1 2.295.502l15.553 7.85a4.882 4.882 0 0 1 1.89 1.712c.445.727.678 1.563.674 2.415 0 .862-.233 1.708-.675 2.45a4.882 4.882 0 0 1-1.89 1.71l-15.552 7.85c-.69.348-1.455.521-2.227.504ZM40.578 37a1.487 1.487 0 0 0-.823.27c-.231.127-.421.315-.55.543-.13.227-.2.483-.205.745v15.884c-.002.273.07.541.206.779.127.237.316.436.549.575a1.63 1.63 0 0 0 1.577 0l15.81-7.925c.272-.138.498-.35.652-.61.14-.248.21-.528.206-.812a1.41 1.41 0 0 0-.24-.813 1.195 1.195 0 0 0-.618-.542l-15.947-7.89-.617-.204Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  "VideoPlay",
  {
    viewBox: "0 0 94 94",
    width: "94px",
    height: "94px",
  }
);
