
import * as React from 'react';
import createSvgIcon from '../SvgIcon/createSvgIcon';

export default createSvgIcon(
// tslint:disable-next-line:max-line-length
<React.Fragment><path fill="#00A050" fillRule="nonzero" d="M7.083 12.697c-.3554-.3654-.3751-.933-.0646-1.321l.0845-.093 3.4341-3.338L1.3 7.9444c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9932L1.3 5.9444l9.237.0006-3.434-3.3391c-.3656-.3554-.4013-.9222-.1018-1.3187l.0818-.0954c.3554-.3655.9222-.4012 1.3187-.1018l.0954.0819 5.2 5.0555c.0409.0404.0765.0813.1082.1241l.0569.0859.0563.1108.0277.0717.0273.0947.0138.0704.011.1008v.1173l-.0143.1199-.0186.0832-.0364.109-.023.053-.0469.0883-.076.11-.0491.0571-.037.0378-5.2 5.0556c-.396.385-1.029.376-1.414-.02z" /></React.Fragment>
,
'ArrowRight',
{
		viewBox : '0 0 14 13',
		width : '14px',
		height : '13px'
});
