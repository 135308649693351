export const getOS = () => {
    if (!navigator) {
        return "unknown";
    }

    var userAgent = navigator.userAgent || navigator.vendor;
  
        // Windows Phone must come first because its UA also contains "Android"
        /*if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }*/
    
        if (/android/i.test(userAgent)) {
            return "android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod|webOS/.test(userAgent) && !window.MSStream) {
            return "ios";
        }
    
        return "unknown";
  }