import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="none" fillRule="evenodd">
      <path
        d="M41.101 26.158V5.469h-2.523v8.842c0 2.93-.01 5.859.007 8.788.002.623-.16.975-.864 1.082-1.492.226-2.97.546-4.452.832-1.805.35-3.611.704-5.417 1.058l.016.087H41.1ZM2.225 5.443v20.706H15.51c-.072-.056-.104-.1-.143-.107-3.221-.621-6.438-1.257-9.665-1.845-.7-.13-.906-.438-.903-1.129.022-5.585.013-11.17.013-16.756v-.87H2.225ZM21.03 26.042c.023-.17.05-.286.05-.405.003-6.877-.001-13.754.012-20.63.001-.435-.15-.58-.56-.66-3.893-.76-7.78-1.544-11.67-2.314-.959-.19-1.924-.35-2.893-.525-.025.198-.042.269-.042.342-.003 6.901.004 13.801-.019 20.703-.001.573.322.61.715.688 2.915.571 5.827 1.151 8.742 1.72 1.876.366 3.757.717 5.665 1.08Zm16.39-2.95V1.475C32.341 2.48 27.298 3.479 22.278 4.471v21.543c5.053-.974 10.065-1.944 15.141-2.922ZM4.812 4.33c0-1.146-.006-2.17.001-3.194.004-.758.262-.98.985-.837 4.705.923 9.426 1.77 14.104 2.815 1.718.384 3.27.018 4.88-.298 4.226-.832 8.446-1.69 12.671-2.53.859-.17 1.116.044 1.122.927.01.974.003 1.949.003 3.057.86 0 1.676 0 2.493.002 1.115.002 1.231.114 1.231 1.19V26.25c0 1.025-.073 1.1-1.073 1.1H2.142c-.983 0-1.073-.089-1.073-1.097-.003-6.955-.003-13.909 0-20.863 0-.964.095-1.055 1.04-1.06.873-.003 1.744 0 2.703 0Z"
        fill="#1D1D1B"
      />
      <path
        d="M41.101 26.158V5.469h-2.523v8.842c0 2.93-.01 5.859.007 8.788.002.623-.16.975-.864 1.082-1.492.226-2.97.546-4.452.832-1.805.35-3.611.704-5.417 1.058l.016.087H41.1ZM2.225 5.443v20.706H15.51c-.072-.056-.104-.1-.143-.107-3.221-.621-6.438-1.257-9.665-1.845-.7-.13-.906-.438-.903-1.129.022-5.585.013-11.17.013-16.756v-.87H2.225ZM21.03 26.042c.023-.17.05-.286.05-.405.003-6.877-.001-13.754.012-20.63.001-.435-.15-.58-.56-.66-3.893-.76-7.78-1.544-11.67-2.314-.959-.19-1.924-.35-2.893-.525-.025.198-.042.269-.042.342-.003 6.901.004 13.801-.019 20.703-.001.573.322.61.715.688 2.915.571 5.827 1.151 8.742 1.72 1.876.366 3.757.717 5.665 1.08Zm16.39-2.95V1.475C32.341 2.48 27.298 3.479 22.278 4.471v21.543c5.053-.974 10.065-1.944 15.141-2.922ZM4.812 4.33c0-1.146-.006-2.17.001-3.194.004-.758.262-.98.985-.837 4.705.923 9.426 1.77 14.104 2.815 1.718.384 3.27.018 4.88-.298 4.226-.832 8.446-1.69 12.671-2.53.859-.17 1.116.044 1.122.927.01.974.003 1.949.003 3.057.86 0 1.676 0 2.493.002 1.115.002 1.231.114 1.231 1.19V26.25c0 1.025-.073 1.1-1.073 1.1H2.142c-.983 0-1.073-.089-1.073-1.097-.003-6.955-.003-13.909 0-20.863 0-.964.095-1.055 1.04-1.06.873-.003 1.744 0 2.703 0Z"
        stroke="#1D1D1B"
        strokeWidth=".47"
      />
      <path
        d="M25.481 17.333c-.114-.112-.455-.305-.443-.474.016-.228.27-.588.476-.63 2.786-.58 5.58-1.13 8.38-1.638.224-.04.515.28.775.433-.191.208-.35.558-.576.605-2.737.57-5.485 1.097-8.23 1.635-.072.015-.143.026-.382.069M34.167 18.823c.105.106.302.301.5.496-.217.215-.403.57-.652.624-1.85.403-3.714.746-5.573 1.112-.88.172-1.756.389-2.646.497-.243.03-.527-.259-.792-.4.177-.217.323-.584.537-.629 2.73-.581 5.471-1.117 8.208-1.663.071-.013.146-.013.418-.037M25.408 12.994c-.096-.11-.415-.35-.375-.453.101-.266.328-.614.565-.672 1.23-.295 2.476-.51 3.718-.753 1.48-.29 2.959-.581 4.44-.87.376-.075.774-.117.892.364.117.485-.25.637-.632.712-2.767.549-5.536 1.092-8.303 1.635-.049.01-.098.013-.305.037M17.756 17.297c-2.337-.456-4.458-.868-6.58-1.283-.642-.127-1.3-.207-1.919-.407-.244-.08-.4-.434-.596-.662.268-.143.565-.445.798-.402 2.748.498 5.487 1.035 8.22 1.607.245.05.423.42.632.64l-.555.507M9.264 5.87c2.425.476 4.643.91 6.859 1.347.547.109 1.114.17 1.634.355.249.09.412.418.617.64-.27.14-.567.439-.807.398-1.94-.328-3.868-.719-5.8-1.095-.833-.163-1.674-.303-2.492-.527-.225-.063-.556-.427-.523-.585.051-.236.382-.412.512-.533M9.161 18.79c1.496.293 2.833.552 4.169.818 1.43.283 2.86.575 4.29.864.374.076.818.192.65.66-.074.212-.57.445-.826.398-2.683-.48-5.354-1.012-8.027-1.536-.392-.076-.83-.19-.733-.708.038-.208.345-.368.477-.496M9.111 10.175c1.466.281 2.783.53 4.097.786 1.456.285 2.912.576 4.37.865.4.08.825.189.715.719-.113.535-.526.432-.936.352-2.627-.52-5.257-1.022-7.886-1.532-.143-.028-.33-.033-.414-.124-.162-.178-.38-.42-.364-.616.016-.182.309-.34.418-.45M25.272 8.398c-.096-.11-.415-.352-.376-.455.102-.264.329-.612.566-.67 1.227-.295 2.476-.51 3.718-.753 1.479-.29 2.96-.582 4.437-.87.379-.075.777-.118.894.362.118.487-.252.637-.631.714-2.768.548-5.536 1.091-8.305 1.635-.047.009-.096.012-.303.037"
        fill="#1D1D1B"
      />
    </g>
  </React.Fragment>,
  "Book",
  {
    viewBox: "0 0 43 28",
    width: "43px",
    height: "28px",
  }
);
