import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { ISearchItem } from "../../../Types";
import { Heading, ResponsiveImage } from "../..";
import CMSIcon from "../../CMS/CMSIcon";

const Root = styled.div`
  overflow: auto;
`;

const List = styled.ul`
  list-style: none;
  margin: 0 auto;
  min-width: 972px;
  padding: 0;
  text-align: left;
`;

const ListItem = styled.li`
  margin: 0;
  padding: 0;
`;

const ListHeader = styled.div`
  flex-basis: 320px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 18px;
  h2 {
    margin-bottom: 0 !important;
  }
`;
const ListIcon = styled.div`
  flex-basis: 44px;
`;

const ListItemRow = styled.div`
  align-items: center;
  color: #757575;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 24px;
  padding: 17px 10px;
  & > * {
    flex-shrink: 0;
  }
`;

const ListGridItem = styled.a`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 24px;
  min-height: 110px;
  padding: 10px;
  position: relative;
  transition: all 0.3s ease;

  & > * {
    flex-shrink: 0;
  }

  &:before {
    border-top: 1px solid RGBA(121, 121, 121, 0.19);
    display: block;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:hover {
    background-color: #fff;
  }
`;

const GradeInfo = styled.div`
  flex-basis: 70px;
  font-size: 15px;
  font-weight: 600;
  margin: 0 20px;
`;

const ListImage = styled.div`
  display: flex;
  flex-basis: 120px;
  flex-direction: column;
  height: 33px;
  justify-content: center;

  img {
    display: block;
    margin: 10px auto;
    max-height: 61px;
    max-width: 120px;
  }
`;

const ListTitle = styled.div`
  flex-grow: 1;
`;

export interface IListGrid {
  Data: Array<ISearchItem>;
  GetHostData: any;
  Configuration: any;
}

const ListGrid: React.FC<IListGrid> = ({ Data, GetHostData, Configuration }) => {
  return (
    <Root>
      <List>
        <ListItem>
          <ListItemRow>
            <ListImage>{Configuration.Columns.ProviderWithLogo.Label}</ListImage>
            <ListHeader></ListHeader>
            <ListTitle>{Configuration.Columns.Name.Label}</ListTitle>
            <GradeInfo>{Configuration.Columns.SchoolYears.Label}</GradeInfo>
            <ListIcon />
          </ListItemRow>
        </ListItem>

        {Data.map(({ Header, Url, Host, SchoolYears }, key) => {
          const HostData = GetHostData(Host);
          return (
            <ListItem key={`ListItem_${key}`}>
              <ListGridItem href={Url} target="_blank">
                {HostData.map(({ Logo, HostName }, index) => (
                  <Fragment key={`hostLogo_${index}`}>
                    <ListImage>
                      <ResponsiveImage {...Logo} />
                    </ListImage>
                    <ListHeader>
                      <Heading size={300}>{HostName}</Heading>
                    </ListHeader>
                  </Fragment>
                ))}
                <ListTitle>{Header}</ListTitle>
                <GradeInfo>{SchoolYears}</GradeInfo>
                <ListIcon>
                  <CMSIcon icon={"targetblank"} />
                </ListIcon>
              </ListGridItem>
            </ListItem>
          );
        })}
      </List>
    </Root>
  );
};

export default ListGrid;
