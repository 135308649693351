import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CMSIcon from "../CMS/CMSIcon";

import { Button } from "..";
import { IHelpButton } from "../../Types/HelpButton";
const StyledBtn = styled.div`
  z-index: 5;
  font-family: "Avenir";
  padding: 0px;
  line-height: 2.2;
  width: 85px;
  height: 85px;
  outline: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #8ecdb2;
  background-color: #8ecdb2;
  font-weight: 600;
  text-align: center;
  position: fixed;
  right: 25px;
  bottom: 30px;
  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 100%;
      opacity: 1;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;

  a {
    min-width: 250px;
    position: relative;

    svg {
      max-height: 14px;
      max-width: 16px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const StyledHelpBox = styled.div`
  max-width: 345px;

  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: fit-content;
  padding: 25px;

  & .line {
    border-top-color: #8ecdb2;
    margin: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 225px;
  }

  & h3 {
    font-size: 22px;
  }
`;

const IconWrapper = styled.div`
  text-align: end;
  & svg {
    cursor: pointer;
    height: 15px;
  }
`;

const HelpWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 25px;
  z-index: 1100;
  align-items: center;
  padding: 0 20px;
  .boxWrapper {
    display: flex;
    align-items: end;
  }

  ${(props) => props.theme.breakpoints("xsm")`

    right: 140px;
    padding: 0;
`}
`;

const BoxTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #fff;
  display: none;

  ${(props) => props.theme.breakpoints("xsm")`
    display: block;
    margin-bottom: 40px;
`}
`;
const HelpBtn: React.FC<IHelpButton> = ({
  BtnLabel,
  Header,
  Description,
  CTA,
}) => {
  const openHelp = () => {
    document.querySelector(".boxWrapper")?.classList.remove("boxHidden");
    if (window.innerWidth < 768) {
      document.querySelector(".helpBtn")?.classList.add("boxHidden");
    }
  };

  const closeHelp = () => {
    document.querySelector(".boxWrapper")?.classList.add("boxHidden");
    if (window.innerWidth < 768) {
      document.querySelector(".helpBtn")?.classList.remove("boxHidden");
    }
  };
  return (
    <>
      <HelpWrapper>
        <StyledBtn onClick={openHelp} className="helpBtn">
          <p>{BtnLabel}</p>
        </StyledBtn>
        <div className="boxWrapper boxHidden">
          <StyledHelpBox>
            <IconWrapper onClick={closeHelp}>
              <CMSIcon icon="close"></CMSIcon>
            </IconWrapper>
            <h3 className="boxHeader">{Header}</h3>
            <hr className="line" />
            <p>{Description}</p>
            {Boolean(CTA) && (
              <ButtonWrapper>
                <Button
                  className="boxButton"
                  component={`a`}
                  href={CTA?.Url}
                  variant={`CTA`}
                >
                  <span className="label">{CTA?.Label}</span>
                  <CMSIcon icon="arrowdown" />
                </Button>
              </ButtonWrapper>
            )}
          </StyledHelpBox>
          <BoxTriangle></BoxTriangle>
        </div>
      </HelpWrapper>
    </>
  );
};
export default HelpBtn;
