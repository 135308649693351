import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M94.117 56.098s14.606 21.98-10.086 39.066c-24.691 17.086-43.6-29.542-43.6-29.542S29.957 49.931 11.99 46.168C-5.98 42.406-6.558 12.13 29.809 1.22 66.174-9.688 94.117 56.098 94.117 56.098"
      fill="#87CDD7"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgClimate",
  {
    viewBox: "0 0 98.407 98.928",
    width: "98.407px",
    height: "98.928px",
  }
);
