import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <g fill="#1D1D1B" fillRule="evenodd">
      <path d="M31.741 1.833a6.422 6.422 0 0 0-6.415 6.415v61.776a6.422 6.422 0 0 0 6.415 6.416H46.52a6.422 6.422 0 0 0 6.415-6.416V8.248a6.422 6.422 0 0 0-6.415-6.415H31.74Zm14.778 76.44H31.74c-4.548 0-8.248-3.701-8.248-8.25V8.249C23.493 3.7 27.193 0 31.741 0H46.52c4.548 0 8.248 3.7 8.248 8.248v61.776c0 4.548-3.7 8.249-8.248 8.249Z" />
      <path d="M8.248 25.33a6.423 6.423 0 0 0-6.415 6.414v14.783a6.423 6.423 0 0 0 6.415 6.416H70.01a6.423 6.423 0 0 0 6.417-6.416V31.744a6.423 6.423 0 0 0-6.417-6.415H8.248ZM70.01 54.775H8.248C3.699 54.776 0 51.075 0 46.527V31.744c0-4.547 3.7-8.247 8.248-8.247H70.01c4.55 0 8.25 3.7 8.25 8.247v14.783c0 4.548-3.7 8.25-8.25 8.25Z" />
      <path d="M30.961 35.693h15.482c1.179 0 1.181-1.832 0-1.832H30.961c-1.18 0-1.18 1.832 0 1.832M30.961 40.338h15.482c1.179 0 1.181-1.833 0-1.833H30.961c-1.18 0-1.18 1.833 0 1.833M30.961 44.983h15.482c1.179 0 1.181-1.833 0-1.833H30.961c-1.18 0-1.18 1.833 0 1.833" />
    </g>
  </React.Fragment>,
  "SubjectHealth",
  {
    viewBox: "0 0 78.26 78.273",
    width: "78.26px",
    height: "78.273px",
  }
);
