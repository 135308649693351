import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M54.973 28.7C55.636 13.525 43.873.69 28.7.026 13.526-.636.69 11.127.027 26.3c-.663 15.174 11.1 28.01 26.273 28.673 15.174.663 28.01-11.1 28.673-26.274Zm-27.74-.816c.406.681.81 1.364 1.187 2.063 2.123 3.94 3.378 8.652 2.53 13.135-.846 4.484-4.158 8.549-8.292 9.02l4.575-24.218c-.82-1.374-1.646-2.743-2.267-4.236-1.552-3.729-1.703-8.137-.409-11.981 1.294-3.845 4.016-7.077 7.399-8.786l-4.723 25.003Z"
      fill="#8ECDB2"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "GreenSpine",
  {
    viewBox: "0 0 55 55",
    width: "55px",
    height: "55px",
  }
);
