import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { IHeader, IFooter } from "../../Types";
import { useAppContext } from "../../Contexts/StateProvider";

const StyledFullscreenMenu = styled.div`
  width: 100vw;
  background: ${(props) => props.theme.navigation.fullScreen.bg};
  position: fixed;
  top: 100px;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  height: ${(props) => (props.isOpen ? "100vh" : "0")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: height, opacity;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1000;
`;

const MenuScroller = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  -moz-overflow-scrolling: touch;
  height: calc(100% - 78px);
  width: 100%;
`;

const MenuInner = styled.div`
  padding: 0 40px;
`;

const PrimaryNav = styled.nav`
  margin: 36px 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: left;
  ul {
    list-style: none;
  }
`;

const NavListItem = styled.li`
  margin-bottom: 50px;
  text-align: left;
`;

const PrimaryNavLink = styled.a`
  font-size: 17px;
  line-height: 24px;
  color: ${(props) => props.theme.navigation.fullScreen.txt};
  text-decoration: none;
`;

export interface IFullscreenMenu extends IHeader, IFooter {
  /**
   * Is the menu open or closed?
   */
  isOpen?: boolean;
  Alias: string;
  Country: string;
  HomeUrl: string;
}

const FullscreenMenu: React.FC<IFullscreenMenu> = ({
  HomeUrl,
  Header: HeaderComponent,
  Alias,
  Country,
  ...rest
}) => {
  const { isMenuOpen: isOpen } = useAppContext();

  const [isClosing, setIsClosing] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>();
  const scrollerRef = useRef();

  const { NavigationLinks } = HeaderComponent;

  useEffect(() => {
    isOpen
      ? disableBodyScroll(scrollerRef.current)
      : enableBodyScroll(scrollerRef.current);

    setIsClosing(isOpen ? false : true);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  useEffect(() => {
    const transitionListener = () => {
      setIsClosing(false);
    };

    menuRef?.current?.addEventListener("transitionend", transitionListener);

    return () => {
      menuRef?.current?.removeEventListener(
        "transitionend",
        transitionListener
      );
    };
  }, []);

  return (
    <StyledFullscreenMenu ref={menuRef} isOpen={isOpen} isClosing={isClosing}>
      <MenuScroller ref={scrollerRef}>
        <MenuInner>
          <PrimaryNav role="navigation">
            <ul>
              {Boolean(NavigationLinks) &&
                NavigationLinks.map(({ Url, Label }, key) => (
                  <NavListItem key={key}>
                    <PrimaryNavLink href={Url}>{Label}</PrimaryNavLink>
                  </NavListItem>
                ))}
            </ul>
          </PrimaryNav>
        </MenuInner>
      </MenuScroller>
    </StyledFullscreenMenu>
  );
};

export default FullscreenMenu;
