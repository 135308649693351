import React from "react";
import {
  ActivityMap,
  CommunesList,
  ContentBanner,
  CookieBot,
  FullScreenImage,
  HelpBtn,
  ImageBanner,
  SearchSection,
  SubheaderSection,
  SubjectBanner,
  SubjectTabs,
  Spacer,
  UnderHeaderSection,
  Webinars,
} from "../.";
import { ICMSSections } from "../../Types";

const CMSContent: React.FC<ICMSSections> = ({ Components = [] }) => {
  return (
    <React.Fragment>
      {Components.map((component, key) => {
        switch (component.Alias) {
          case "ActivityMap":
            return <ActivityMap key={key} {...component} />;
          case "CommunesList":
            return <CommunesList key={key} {...component} />;
          case "ContentBanner":
            return <ContentBanner key={key} {...component} />;
          case "CookieBotSection":
            return <CookieBot key={key} {...component} />;
          case "FullScreenImage":
            return <FullScreenImage key={key} {...component} />;
          case "HelpButton":
            return <HelpBtn key={key} {...component} />;
          case "ImageBanner":
            return <ImageBanner key={key} {...component} />;
          case "SearchSection":
            return <SearchSection key={key} {...component} />;
          case "SubheaderSection":
            return <SubheaderSection key={key} {...component} />;
          case "SubjectBanner":
            return <SubjectBanner key={key} {...component} />;
          case "SubjectTabs":
            return <SubjectTabs key={key} {...component} />;
          case "SpacerSection":
            return <Spacer key={key} {...component} />;
          case "UnderHeaderSection":
            return <UnderHeaderSection key={key} {...component} />;
          case "Webinars":
            return <Webinars key={key} {...component} />;
        }
      })}
    </React.Fragment>
  );
};

export default CMSContent;
