import * as React from "react";
import createSvgIcon from "../SvgIcon/createSvgIcon";

export default createSvgIcon(
  // tslint:disable-next-line:max-line-length
  <React.Fragment>
    <path
      d="M8.121 2.726S59.83-8.914 74.006 15.91c14.177 24.824-7.29 41.634-7.29 41.634l-11.322 27.36-24.201 4.531L.754 18.38S-3.481 4.575 8.121 2.726"
      fill="#BBA9D1"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "BgEnergy",
  {
    viewBox: "0 0 78.848 89.435",
    width: "78.848px",
    height: "89.435px",
  }
);
