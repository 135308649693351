import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const SuggestContainer = styled.div`
	width: 100%;
	border-radius: 8px;
	background: ${props => props.theme.colors.neutralWhite};
	color: ${props => props.theme.colors.textDarkGreen};
	position: absolute;
	bottom: -20px;
	transform: translateY(100%);
	display: flex;
	flex-direction: column;
	text-align: left;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
`;

const Suggestion = styled.a`
	font-size: 16px;
	font-weight: normal;
	line-height: 43px;
	letter-spacing: normal;
	color: #193232;
	padding: 0 16px;
	height: 43px;
	max-height: 43px;
	border-bottom: 1px solid #dedede;
	cursor: pointer;
	text-decoration: none;
	background: ${props => props.isSelected ? props.theme.colors.lightGreen : 'transparent'};
	&:last-child {
		border-bottom: 0;
	}

	&:hover {
		background: ${props => props.theme.colors.lightGreen};
	}
`;

export type TSearchHook = {
	SearchEngineId: string;
	Site: string;
	Language: string;
	PageArea: string;
	SearchUrl: string;
}

export default function useSearch({
	SearchEngineId,
	Site,
	Language,
	PageArea,
	SearchUrl
}: TSearchHook): any {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [autoCompleteResults, setAutoCompleteResults] = useState([]);
	const [autoCompleteResultsIndex, setAutoCompleteResultsIndex] = useState(-1);
	const [searchTermSetBySuggestion, setSearchTermSetBySuggestion] = useState(false);
	const [results, setResults] = useState<any>();
	const [resultDocs, setResultDocs] = useState<any>([]);
	const [showResults, setShowResults] = useState(false);
	const [hasNextPage, setHasNextPage] = useState<boolean>(false);
	const [nextPageUrl, setNextPageUrl] = useState<string>('');
	const [resetResults, setResetResults] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const suggestRef = useRef<HTMLDivElement>(null);

	const setInitialFocus = () => {
		inputRef && inputRef.current && inputRef.current.focus();
	}

	const useSuggestion = term => {
		setSearchTermSetBySuggestion(true);
		setAutoCompleteResults([]);
		setSearchTerm(term);
		setInitialFocus();
	}

	const handleSuggestKeydown = (e: any): any => {
		if (!autoCompleteResults.length) {
			return true;
		}

		switch (e.key) {
			case 'Enter':
				if (autoCompleteResultsIndex === -1) {
					return true;
				}
				e.preventDefault();

				window.location.href = `${SearchUrl}?term=${autoCompleteResults[autoCompleteResultsIndex]}`;
				break;
			case 'Escape':
				setAutoCompleteResults([]);
				setAutoCompleteResultsIndex(-1);
				break;
			case 'ArrowDown':
				const maxIndex = autoCompleteResults.length - 1;
				setAutoCompleteResultsIndex(autoCompleteResultsIndex === maxIndex ? maxIndex : autoCompleteResultsIndex + 1);
				break;
			case 'ArrowUp':
				const minIndex = 0;
				setAutoCompleteResultsIndex(autoCompleteResultsIndex === minIndex ? minIndex : autoCompleteResultsIndex - 1);
				break;
		}
	}

	const executeSearch = (currentTerm) => {
		fetch(`/api/search/query?term=${currentTerm}&page=1&engineId=${SearchEngineId}&Site=${Site}&language=${Language}&pageArea=${PageArea}`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json())
			.then(response => {
				setResults(response);
				setResultDocs(response.TypedDocuments);
				setHasNextPage(response.HasNextPage);
				setNextPageUrl(response.NextPageUrl);
			})
			.catch(error => {
				// handle error
			});
	}

	const executeSuggest = (currentTerm) => {
		fetch(`/api/search/autocomplete?term=${currentTerm}&engineId=${SearchEngineId}`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json())
			.then(response => {
				setAutoCompleteResultsIndex(-1);
				setAutoCompleteResults(response);
			})
			.catch(error => {
				// handle error
			});
	}

	useEffect(() => {
		if (!Boolean(searchTerm)) {
			setAutoCompleteResults([]);
			setAutoCompleteResultsIndex(-1);
		}

		if (searchTerm && !searchTermSetBySuggestion) {
			executeSuggest(searchTerm);
		}
	}, [searchTerm]);

	useEffect(() => {
		setShowResults(true);
	}, [resultDocs]);


	const resetSearch = () => {
		setSearchTerm('');
		setResetResults(true);
		setAutoCompleteResults([]);
		setInitialFocus();
	};

	const handleSearchTermChange = event => {
		setSearchTerm(event.target.value);

		if (searchTermSetBySuggestion) {
			setSearchTermSetBySuggestion(false);
		}
	};

	const handleQuerySubmit = (e: any): void => {
		e.preventDefault();

		setAutoCompleteResults([]);
		executeSearch(searchTerm);
	};

	const clickSuggest = (e: any): void => {
		e.preventDefault();
		e.stopPropagation();

		useSuggestion(e.currentTarget.textContent);
	}

	const handleNextPage = (e: any): void => {
		fetch(nextPageUrl, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json())
			.then(response => {
				setResultDocs([...resultDocs, ...response.TypedDocuments]);
				setNextPageUrl(response.NextPageUrl);
				setHasNextPage(response.HasNextPage);
			})
			.catch(error => {
				// handle error
			});
	};

	useEffect(() => {
		if (resetResults) {
			setResetResults(false);
			setResultDocs([]);
			setNextPageUrl('');
			setHasNextPage(false);
		}
	}, [resetResults]);

	useEffect(() => {
		const isClient = typeof window === 'object';

		if (!isClient) {
			return;
		}

		document.addEventListener('mousedown', handleClick);

		return () => {
			const isClient = typeof window === 'object';

			if (!isClient) {
				return;
			}

			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleClick = e => {
		if (suggestRef && suggestRef.current && suggestRef.current.contains(e.target)) {
			return;
		}

		setAutoCompleteResults([]);
	};

	const Suggestions: React.FC<any> = ({
		Entries,
		Index,
		onSuggestionClick
	}) => {
		const realIndex = parseInt(Index, 10);
		return (
			<React.Fragment>
				{Boolean(Entries.length) && (
					<SuggestContainer ref={suggestRef}>
						{Entries.map((suggestion, key) => {
							return (
								<Suggestion href={`#`} key={key} onClick={onSuggestionClick} isSelected={realIndex === key}>
									{suggestion}
								</Suggestion>
							);
						})}
					</SuggestContainer>)}
			</React.Fragment>
		)
	};

	return {
		inputRef,
		executeSearch,
		executeSuggest,
		results,
		setResults,
		searchTerm,
		setSearchTerm,
		autoCompleteResults,
		setAutoCompleteResults,
		searchTermSetBySuggestion,
		setSearchTermSetBySuggestion,
		resultDocs,
		setResultDocs,
		hasNextPage,
		setHasNextPage,
		nextPageUrl,
		setNextPageUrl,
		showResults,
		autoCompleteResultsIndex,
		setAutoCompleteResultsIndex,
		handleSuggestKeydown,
		setInitialFocus,
		useSuggestion,
		resetResults,
		resetSearch,
		handleNextPage,
		handleQuerySubmit,
		handleSearchTermChange,
		clickSuggest,
		Suggestions
	};
}